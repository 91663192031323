import api from './api';
import { SeguroGrupos } from '../interfaces/seguroGrupos';

const getAll = () => api.get<SeguroGrupos[]>('/v1/grupos-seguradora')


//const createGrupoUsuarios = (grupoUsuarios: Pick<Grupod, 'nome' | 'listMenuGrupoUsuario'>) => api.post('/v1/grupos-usuario', grupoUsuarios)

//const alteraGrupoUsuarios = (grupoUsuarios: GrupoUsuarios) => api.put(`/v1/grupos-usuario/`, grupoUsuarios)

//const deleteGrupoUsuarios = (id: number) => api.delete(`/v1/grupos-usuario/${id}`)

export const GetSeguroGrupos = {
    getAll,
    //createGrupoUsuarios,
    //alteraGrupoUsuarios,
    //deleteGrupoUsuarios,
}