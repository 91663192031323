import React, { ChangeEvent, Fragment, useEffect, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { Container, CardContainer, Cards, Tabelas1 } from './styles';
import { Button, Form, Row, Col, Table } from 'react-bootstrap';
import api from '../../../services/api';
import { Tabela } from '../../../interfaces/tabela';
import { TabelaBens } from '../../../interfaces/tabelaBens';
import { TiposBens } from '../../../interfaces/tiposBem';
import { SequenciaCard } from '../../../interfaces/sequenciaCard';
import { Bens } from '../../../interfaces/bens';
import { PlanoAgrupamento } from '../../../interfaces/planoAgrupamentos';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import { Tabelas } from '../../../hooks/tabelas';
import { Planoh } from '../../../hooks/plano';
import { Planos } from '../../../interfaces/planos';
import { FiArrowLeft } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../hooks/toast';
import { TabelaBensParcela } from '../../../interfaces/tabelasBensParcela'

const Etapa3: React.FC = () => {

  const { addToast } = useToast();
  const history = useHistory();
  const { tabela } = useParams() as any
  const { tiposBem, getAllSequenciaCards, sequenciaCards, getAllPlanos, planosAgrupamento } = Tabelas();
  const [listaParcela, setListaParcela] = useState<TabelaBensParcela[]>([]);
  const [listaTabela, setListaTabela] = useState<Tabela[]>([]);
  const [listaTipoBem, setListaTipoBem] = useState<TiposBens[]>(tiposBem);
  const [listaCards, setListaCards] = useState<SequenciaCard[]>(sequenciaCards)
  const [listaPlanos, setListaPlanos] = useState<PlanoAgrupamento[]>(planosAgrupamento)
  const [grupo, setGrupo] = useState<number>();
  const { ativosLista, getAllAtivos } = Planoh();
  const [todosBens, setTodosBens] = useState<Bens[]>();
  const [idBem, setIdBem] = useState<string>();

  const ModelBasico = {
    tabelaVenda: { id: tabela },
    codigoBem: undefined
  }

  useEffect(() => {
    carregaTabelaDados()
    carregaTabela()
    carregaParametro()
    getAllSequenciaCards();
  }, [])

  useEffect(() => {
    setListaCards(sequenciaCards)
  },[sequenciaCards])

  useEffect(() => {
    carregaParametro()
  }, [listaTabela, listaCards])


  const [model, setModel] = useState<Omit<TabelaBens, "id">>(ModelBasico)

  async function carregaTabelaDados() {
    const responseTD = await api.get(`/v1/tabelas-venda/${tabela}`)
    setListaTabela(responseTD.data)
  }

  async function carregaTabela() {
    const response = await api.get(`/v1/tabelas-venda/${tabela}/calculado`)
    let obj:object = response.data[0]
    
    if(Object.values(obj).length > 0){
      console.log(response.data)
    setListaParcela(response.data)
  }
  }

  async function carregaParametro() {
    const response = await api.get('/v1/bens/paginado?page=0&size=99999&sort=descricao,asc')
    let bens:Bens[] = response.data.content;
    let bensAtivos:Bens[] = bens.filter(item => item ? item.foraDeLinha === 'N' && item.codigoGrupoBem === listaTabela[0]?.codigoGrupoBem : item )
    setTodosBens(bensAtivos)
  }





  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value !== '') {
      setModel({
        ...model,
        codigoBem: parseInt(e.target.value)
      });
      setIdBem(e.target.value)
    } else {
      setModel(ModelBasico)
      setIdBem('')

    }
  }


  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    //const ativo = ativosLista.find(item => item.id === model.sequenciaAgrupamento)
    e.preventDefault()
    if (model.codigoBem !== null && model.tabelaVenda?.id !== null && model.codigoBem !== undefined) {
      console.log(model)
      const response = await api.post(`/v1/bens-tabela-venda/`, model)
      console.log(response)

      carregaTabela()

      addToast({
        type: 'success',
        title: 'Sucesso ao salvar',
        description: 'Bem inserido com sucesso!'
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao salvar',
        description: 'Faltou preencher campos, bem não foi inserido!'
      });

      return
    }

    setModel(ModelBasico)
    setIdBem('')

  }


  async function deletaCard(id: number) {
    const response = await api.delete(`/v1/sequencias-agrupamento-tabela-venda/${id}`);
    getAllSequenciaCards();
  }


  function back() {
    history.goBack()
  }



  async function handleGrupo(e: ChangeEvent<HTMLSelectElement>) {
    carregaParametro()
    if (e.target.value !== '') {
      setModel({
        ...model,
        codigoBem: parseInt(e.target.value)
      });
      setIdBem(e.target.value)
    } else {
      setModel(ModelBasico)
      setIdBem('')
    }
  }

  function verificar() {
    if (!grupo) {
      return (
        <option></option>
      )
    }
  }

  function listaSequencia(sequencia: number) {
    const lista = listaPlanos.filter(item => sequencia.toString() ? item.sequenciaAgrupamento.toString() === (sequencia.toString()) : item);
    return lista
  }

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "00" + numero;
    else if (numero <= 99)
      return "0" + numero;
    else
      return numero
  }

  function voltar() {
    history.goBack()
  }

  async function deleteBem(id: number){
    const response = await api.delete(`/v1/bens-tabela-venda/${id}`);
    if(response.status === 204){
      carregaTabela()
      addToast({
        type: 'success',
        title: 'Sucesso ao excluir',
        description: 'Sucesso ao excluir bem!'
      });
    }else{
      addToast({
        type: 'error',
        title: 'Erro ao excluir',
        description: 'Erro encontrado, favor informar ao TI!'
      });

      return
    }
  }


  return (
    <>
      <Header />
      <Container>
        <CardContainer>
          <div className='title'><FiArrowLeft onClick={voltar} className='voltar' /><h2>Cadastro de Bens</h2><h5></h5></div>
          <Form className="form-border" onSubmit={onSubmit}>
            <Row>

              <Col>
                <FormControl className="codFunc" variant="standard" >
                  <TextField
                    InputLabelProps={{ shrink: model.codigoBem ? true : false }}
                    className='inputNovo'
                    id="standard-required"
                    label="Código Bem"
                    variant="standard"
                    name='codigo'
                    value={idBem?.toString()}
                    onChange={handleChangeInput}

                  />
                </FormControl>
              </Col>
              <Col>
                <Form.Group className="inputNovo2">
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Bem
                  </InputLabel>
                  <NativeSelect
                    className="inputNovo1"
                    value={idBem?.toString()}
                    onChange={handleGrupo}
                    inputProps={{
                      name: 'cargo',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option id="0" value="0"></option>
                    {todosBens?.map(item => {
                      //if (item. === 'A') {
                      return (
                        <option
                          id={item.codigoBem.toString()}
                          value={item.codigoBem}>

                          {item.codigoBem + ' - '+item.descricao}
                        </option>
                      )
                      //}
                    })

                    }



                  </NativeSelect>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="buttonsCargo">
              <Button type="submit">Adicionar</Button>
            </Form.Group>
          </Form>
          <h2 className='tituloTabela'>{listaTabela[0]?.nome.toUpperCase()} - {listaTabela[0]?.tipoBem?.descricao}</h2>
          <Tabelas1>
          <Table bordered hover responsive className='tabela'>
            
            <thead>
              <tr className='linha titulo'>
                <th></th>
                <th rowSpan={2} className='ac'>Código Bem</th>
                <th rowSpan={2} className='ac1'>Bem</th>
                <th rowSpan={2} className='esquerda ac'>Crédito</th>
                {listaParcela[0]?.meses.map(function (item) {
                  let tipo = 0;
                  listaCards.map(function (item2) {
                    if(item2.tabelaVenda.id === parseInt(tabela) && item2.adesao === 0 && item2.reducao === 0 && item2.sequencia.prazo === item){
                      tipo = item2.codigoTipoVenda
                    }
                  })
                  return(
                    <th className='ac esquerda tit' colSpan={2}>{item} Meses - Gr {listaTabela[0]?.codigoGrupo} - {tipo}
                    </th>
                
                    
                  )
                })}
              </tr>
              <tr className='meses'>
          
                <th></th>
                {listaParcela[0]?.meses.map(function (item) {
                  return(
                    <>
                    <th className='ac semSeguro esquerda'>Sem Seguro
                    </th>
                    <th className=' comSeguro esquerda1'>Com Seguro
                    </th>
                    </>
                    
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {listaParcela.map(function (item) {
              return(
                <Fragment>
                {item.bens.map(ib =>
                  <Fragment>
                  <tr>
                  <td onClick={() => deleteBem(ib.bemTabelaVendaId)} className="del"><MdDelete size={20}></MdDelete></td>
                  <td className="ac">{ib.codigoBem}</td>
                  <td className='ac nome'>{ib.nomeBem}</td> 
                  <td className='esquerda'>{ib.valorBem.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                  {ib.parcelas.map(ibp =>
                    <Fragment> 
                    <td className='esquerda'>{ibp.valorParcela.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                    <td className='esquerda1'>{ibp.valorParcelaComSeguro.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                    </Fragment>
                  )}
                   </tr>
                  </Fragment>
                )}
                
              </Fragment>
              )}
            )}
            </tbody>
          </Table>
          </Tabelas1>

        </CardContainer>
      </Container>
    </>
  )
}

export default Etapa3