import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    :root{
      --background: #f0f2f5;
      --red: #e52e40;
      --blue-base: rgb(19,52,131);//#0b1f35;
      --blue: rgb(19,52,131); //rgb(11, 31, 53)

      --name: #1565c0; 
      
      --blue-light: #2e2c33;

      --text-title: #363f5f;
      --text-body: #969cb3;

      --background: #f0f2f5;
      --shape: #ffffff;
    }
    
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        @media (max-width: 1080px){
            font-size: 93.75%; //15px
        }

        @media (max-width: 720px){
            font-size: 87.5%; //14px
        }
    }

    body{
        background: var(---background);
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button {
        @font-face {
        src: local('Code39'), url('https://fonts.cdnfonts.com/s/11015/Code39r.woff') format('woff');

        .teste{
            @import url('https://fonts.cdnfonts.com/css/code-39');
            font-size: 40px;
            font-family:  'Code39', sans-serif;
        }
    }
        //font-family: 'Roboto';
        font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",'Code39';
        //font-weight: 400;
        word-wrap: break-word;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }

    button{
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }


`