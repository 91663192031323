import api from './api';
import { Nivel } from '../interfaces/nivel';

const getAll = () => api.get<Nivel[]>('/v1/niveis')

const createNivel = (nivel: Pick<Nivel, 'nome' | 'ativo'>) => api.post('/v1/niveis', nivel)

const alteraNivel = (nivel: Nivel) => api.put(`/v1/niveis/`, nivel)

const deleteNivel = (id: number) => api.delete(`/v1/niveis/${id}`)

export const GetNivel = {
    getAll,
    createNivel,
    alteraNivel,
    deleteNivel,
}