import api from './api';
import { Tabela } from '../interfaces/tabela';
import { TiposBens } from '../interfaces/tiposBem';
import { SequenciaCard } from '../interfaces/sequenciaCard';
import { PlanoAgrupamento } from '../interfaces/planoAgrupamentos';
import { TabelasOrder } from '../interfaces/tabelasOrder';
import { SequenciaAgrupamento } from '../interfaces/sequenciaAgrupamento';

const getAllTabelas = () => api.get<Tabela[]>('/v1/tabelas-venda')

const getAllTiposBem = () => api.get<TiposBens[]>('/v1/tipos-bem')

const getAllCards = () => api.get<SequenciaCard[]>('/v1/sequencias-agrupamento-tabela-venda')

const getAllCards2 = () => api.get<TabelasOrder>('/v1/sequencias-agrupamento-tabela-venda/paginado?page=0&size=99999&sort=adesao,reducao,asc')

const getAllPlanoAgrupamento = () => api.get<PlanoAgrupamento[]>('/v1/planos-agrupamento')

const getAllPlanos = () => api.get<SequenciaAgrupamento[]>('/v1/sequencias-agrupamento')

//const deleteCargo = (id: number) => api.delete(`/v1/cargos/${id}`)

export const getTabelas = {
    getAllTabelas,
    getAllTiposBem,
    getAllCards,
    getAllCards2,
    getAllPlanoAgrupamento,
    getAllPlanos,
}