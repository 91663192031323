import styled from "styled-components";
import { shade } from 'polished';


export const Container = styled.button `
             place-content: center;
           margin-top: 12px;
           font-weight: bold;
           background: #363f5f;
           border-radius: 10px;
           border: 0 none;
           outline: 0;
           padding: 5px;
           width: 180px;
           height: 60px;
           color: #f4ede8;
           font-size: 17px;
           transition: background-color 0.2s;

           &:hover {
             background: ${shade(0.2, '#363f5f')};
           }

`;