import api from './api';
import { GrupoUsuarios } from '../interfaces/grupoUsuarios';

const getAll = () => api.get<GrupoUsuarios[]>('/v1/grupos-usuario')

const createGrupoUsuarios = (grupoUsuarios: Pick<GrupoUsuarios, 'nome' | 'listMenuGrupoUsuario'>) => api.post('/v1/grupos-usuario', grupoUsuarios)

const alteraGrupoUsuarios = (grupoUsuarios: GrupoUsuarios) => api.put(`/v1/grupos-usuario/`, grupoUsuarios)

const deleteGrupoUsuarios = (id: number) => api.delete(`/v1/grupos-usuario/${id}`)

export const GetGrupoUsuarios = {
    getAll,
    createGrupoUsuarios,
    alteraGrupoUsuarios,
    deleteGrupoUsuarios,
}