import React, { ChangeEvent, useEffect, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { Container, CardContainer } from './styles';
import { Button, Form, Row, Col } from 'react-bootstrap';
import api from '../../../services/api';
import { Usuario } from '../../../interfaces/usuario';
import { Contato } from '../../../interfaces/contato';
import NativeSelect from '@mui/material/NativeSelect';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import { UsuariosLogin } from '../../../hooks/usuarioSistema';
import cep from 'cep-promise';
import { Cargo } from '../../../interfaces/cargo';
import { Cargos } from '../../../hooks/cargo';
import { Niveis } from '../../../hooks/nivel';
import { Nivel } from '../../../interfaces/nivel';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import InputMask from 'react-input-mask';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { useToast } from '../../../hooks/toast';
import { ContatoConsulta } from '../../../interfaces/contatoConsulta';
import { ConsultaUsuario } from '../../../interfaces/usuarioConsulta';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import copy from 'copy-to-clipboard';
import {Boleto} from '../../../components/Boleto'; 
import { error } from 'console';

interface State {
  amount: string;
  password: string;
  weight: string;
  weightRange: string;
  showPassword: boolean;
}


const pessoaFisica = {
  nomePrincipal: 'Nome',
  nomeSecundario: 'Sobrenome',
  data: 'Data de Nascimento',
  documentoPrincipal: 'CPF',
  mascaraPessoa: '999.999.999-99',
  documentoSecundario: 'RG',
}

const pessoaJuridica = {
  nomePrincipal: 'Razão Social',
  nomeSecundario: 'Nome Fantasia',
  data: 'Data Constituição da Empresa',
  documentoPrincipal: 'CNPJ',
  mascaraPessoa: '99.999.999/9999-99',
  documentoSecundario: 'Inscrição Municipal ou Estadual',
}

const FIXO = {
  telefone: 'Telefone Comercial',
  mascara: '(99) 9999-9999',
}

const WHATSAPP = {
  telefone: 'WhatsApp',
  mascara: '(99) 99999-9999'
}

const CELULAR = {
  telefone: 'Celular',
  mascara: '(99) 99999-9999',
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const UsuarioBase = {
  id: 0,
  tipoPessoa: 'PESSOA_FISICA',
  usuario: { id: 0 },
  //codigo: 0,
  nomePrincipal: '',
  nomeSecundario: '',
  //metaMensal: 0,
  status: true,
  permiteVenda: true,
  vendaTelefone: false,
  vendaSemSQG: false,
  pagarCartao: false,
  verTodos: false,
  cep: '',
  logradouro: '',
  numero: '',
  bairro: '',
  complemento: '',
  cidade: '',
  estado: '',
  email: '',
  //data: '',
  documentoSecundario: '',
  documentoPrincipal: '',
  bloqueado: false,
  forcaTrocarSenha: false,
  descontaReducao: true,
  comDSR: true,
  parceiroGroscred: true,
  superior: { id: 0 },
  cargo: { id: 0 },
  nivel: { id: 0 },
  dataUltimoAcesso: ''
}

const FormUsers: React.FC = () => {

  const { addToast } = useToast();

  const history = useHistory();
  const { id } = useParams() as any;
  const { usuariosLista, getAll } = UsuariosLogin();
  const cargos = Cargos();
  const niveis = Niveis();

  const [listaCargo, setListaCargo] = useState<Cargo[]>(cargos.cargoLista);
  const [listaNivel, setListaNivel] = useState<Nivel[]>(niveis.nivelLista);

  const [listaUsuarios, setListaUsuarios] = React.useState(usuariosLista);

  const [age, setAge] = React.useState<Date | null>(null);

  const [copyText, setCopyText] = useState('');

  const [camposPessoa, setCamposPessoa] = React.useState(pessoaFisica);
  const [campoFone, setCamposFone] = React.useState(CELULAR)
  const [campoEmail, setCampoEmail] = React.useState({ tipoContato: "EMAIL", valor: '' })



  const [contatosBanco, setContatosBanco] = useState<ContatoConsulta[]>();
  const [bloqueado, setBloqueado] = useState<boolean>(false);

  const [ultimoAcesso, setUltimoAcesso] = useState<Usuario>();


  function handleChangeCheckPessoa(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value === 'PESSOA_JURIDICA') {
      setModel({ ...model, tipoPessoa: 'PESSOA_JURIDICA' })
      setCamposPessoa(pessoaJuridica)
    } else {
      setCamposPessoa(pessoaFisica)
      setModel({ ...model, tipoPessoa: 'PESSOA_FISICA' })
    }

  }

  const [values, setValues] = React.useState<State>({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [valueDate, setValueDate] = React.useState<Date | null>(
    null
  );

  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != undefined) {
      setAge(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
      setModel({
        ...model,
        data: DataFormatada
      });
    }
  };

  useEffect(() => {
    getAll();
    cargos.getAll();
    niveis.getAll();
  }, [getAll])

  useEffect(() => {
    setListaUsuarios(usuariosLista)
  }, [usuariosLista])

  useEffect(() => {
    setListaCargo(cargos.cargoLista)
  }, [cargos.cargoLista])

  useEffect(() => {
    setListaNivel(niveis.nivelLista)
  }, [niveis.nivelLista])

  const [model, setModel] = useState<Usuario>(UsuarioBase)

  const [modelContato, setModelContato] = useState<Omit<Contato, "id" | "dadoUsuario">>({
    tipoContato: 'CELULAR',
    valor: '',
  })

  const [contact, setContact] = useState<Omit<Contato, "id" | "dadoUsuario">[]>([])



  async function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;



    resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "");

    if (e.target.name === "documentoPrincipal" && resultado.length === 11 && e.target.id === "CPF") {
      const result = TestaCPF(resultado)
      if (!result) {
        addToast({
          type: 'error',
          title: 'CPF Inválido',
          description: 'Por favor insira um CPF Válido!'
        });
        return;
      }
    }

    setModel({
      ...model,
      [e.target.name]: resultado
    });

  
    if (e.target.name === "cep") {
      buscaCep(resultado)
    }
  }

  async function handleChangeInputEmailRecuperacao(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;

    setModel({
      ...model,
      [e.target.name]: resultado
    });

  }

  async function handleChangeInputContato(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;

    resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "");
    setModelContato({
      ...modelContato,
      [e.target.name]: resultado
    });
  }

  async function handleChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;
    setCampoEmail({
      tipoContato: "EMAIL", valor: resultado
    });
  }

  function handleChangeSelectContato(e: ChangeEvent<HTMLSelectElement>) {

    setModelContato({
      ...modelContato,
      [e.target.name]: e.target.value
    })

    if (e.target.value === 'FIXO') {
      setCamposFone(FIXO)
    } else if (e.target.value === 'WHATSAPP') {
      setCamposFone(WHATSAPP)
    } else {
      setCamposFone(CELULAR)
    }
  }

  function handleChangeSelected(e: ChangeEvent<HTMLSelectElement>) {
    if(listaUsuarios){
    let idUsuarioDado = Array.isArray(listaUsuarios) ? listaUsuarios.find(item => item.id === parseInt(e.target.value)):undefined
    if (idUsuarioDado?.dadoUsuario.id !== undefined) {
      setModel({
        ...model,
        id: idUsuarioDado.dadoUsuario.id,
        usuario: { id: parseInt(e.target.value) },
      })
    }
  }

  }

  function handleChangeSelectedCargo(e: ChangeEvent<HTMLSelectElement>) {
    setModel({
      ...model,
      [e.target.name]: { id: parseInt(e.target.value) },
    })

  }


  function handleChangeCheck(e: ChangeEvent<HTMLInputElement>) {
  console.log(e.target.name)
  console.log(bloqueado)
      setModel({
        ...model,
        [e.target.name]: e.target.value
      });
      if(bloqueado){
        setBloqueado(false)
      }else{
        setBloqueado(true)
      }

  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault()

    if (model.status != undefined) {
      delete model.status
    }
    if (model.superior?.id === 0) {
      delete model.superior
    }
    if (model.cargo?.id === 0) {
      delete model.cargo
    }
    if (model.nivel?.id === 0) {
      delete model.nivel
    }

    if (model.cargo?.nome !== undefined) {
      delete model.cargo.nome
    }

    if (model.cargo?.nome) {
      delete model.cargo.nome
    }

    if (model.nivel?.nome !== undefined) {
      delete model.nivel.nome
    }

    if (model.nivel?.nome) {
      delete model.nivel.nome
    }

    if (model.superiorId !== undefined) {
      delete model.superiorId
    }

    if(model.nomeVendedor)
    delete model.nomeVendedor


    if (model.id === 0) {
      alert('Obrigatório selecionar usuário')
      return
    }

    



    try {
      const response = await api.put(`/v1/dados-usuario/${model.id}`, model)
      

      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'Dados Salvos',
          description: 'Dados foram salvos com sucesso!'
        })
        setModel(UsuarioBase)
        history.push('/usuarios')
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao Salvar',
          description: 'Ocorreu um erro ao tentar salvar as informações, favor informar ao TI do consórcio!'
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao Salvar',
        description: 'Ocorreu um erro ao tentar salvar as informações, favor informar ao TI do consórcio!'
      });
    }
  }

  function back() {
    history.goBack()
  }



  async function buscaCep(x: string) {
    if (x.length === 8) {
      try {
        let resultado = await cep(x)
        setModel({
          ...model,
          logradouro: resultado.street,
          bairro: resultado.neighborhood,
          cidade: resultado.city,
          estado: resultado.state,
          cep: x,
        })
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Cep não encontrato!',
          description: 'Não encontramos este cep na base de dados!'
        });
        setModel({
          ...model,
          logradouro: '',
          bairro: '',
          cidade: '',
          estado: '',
          cep: x,
        })
      }
    }
  }

  async function inserirContatoTelefone() {
    if (modelContato.valor.length < 13) {
      addToast({
        type: 'info',
        title: 'Digite Telefone Válido',
        description: 'Digite um telefone Válido!'
      })
      return
    }
    if (contatosBanco != undefined) {
      const a = contatosBanco.filter(item => item.valor === modelContato.valor)

      if (a.length > 0) {
        addToast({
          type: 'info',
          title: 'Contato Cadastrado',
          description: 'Contato já existe neste Cadastro!'
        })
        return
      }
    }
    try {
      const response = await api.post(`/v1/contatos`,
        {
          tipoContato: modelContato.tipoContato,
          valor: modelContato.valor,
          dadoUsuario: { id: model.id }
        })
      findContato(model.id)

      setContact([...contact, { tipoContato: modelContato.tipoContato, valor: modelContato.valor }])
    } catch (err) {
      console.log(err)
    }

    setModelContato({
      ...modelContato,
      valor: ''
    })
  }

  async function inserirContatoEmail() {
    if (campoEmail.valor.length < 5) {
      addToast({
        type: 'info',
        title: 'Digite E-mail Válido',
        description: 'Digite um e-mail Válido!'
      })
      return
    }

    const response = await api.post(`/v1/contatos`,
      {
        tipoContato: campoEmail.tipoContato,
        valor: campoEmail.valor,
        dadoUsuario: { id: model.id }
      })
    findContato(model.id)
    setContact([...contact, { tipoContato: campoEmail.tipoContato, valor: campoEmail.valor }])
    setCampoEmail({
      ...campoEmail,
      valor: ''
    })


  }

  function randomDarkRGB() {
    var r = Math.floor(Math.random() * 156);
    var g = Math.floor(Math.random() * 156);
    var b = Math.floor(Math.random() * 156);
    var bgColor = "rgb(" + r + "," + g + "," + b + ")";
    return bgColor;
  }

  async function excluirFone(x: number) {
    const response = await api.delete(`/v1/contatos/${x}`)
    findContato(model.id)
  }

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }


  useEffect(() => {
    if (id !== undefined) {
      findUsuario(id)
    }
  }, [])

  async function findUsuario(id: string) {

    const response = await api.get(`/v1/usuarios/${id}`);

    let idContato = response.data[0].dadoUsuario.id;
    const response2 = await api.get(`/v1/dados-usuario/${idContato}`)
    setUltimoAcesso(response2.data[0])
    let teste:Usuario = { ...response.data[0].dadoUsuario, usuario: { id: id } }
    
    if (response.data[0].dadoUsuario.superiorId) {
      teste = { ...teste, superior: { id: response.data[0].dadoUsuario.superiorId } }
    }
    setModel(teste)
    if(teste.tipoPessoa==="PESSOA_JURIDICA")
    setCamposPessoa(pessoaJuridica)
    if (response.data[0].dadoUsuario.data != undefined) {
      const Data = new Date(`${response.data[0].dadoUsuario.data}:08:00:00`);
      setAge(Data)
    }
    findContato(idContato)
  }


  async function findContato(id: number) {
    const response = await api.get(`/v1/dados-usuario/${id}`);
    console.log('dados contato')
    console.log(response.data[0]?.listContato)
    setBloqueado(response.data[0].bloqueado)
    if (response.data[0]?.listContato != undefined) {
      setContatosBanco(response.data[0].listContato)
    }else{
      setContatosBanco([])
    }

  }

  function TestaCPF(strCPF: string) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  async function Copiar(x: string) {
    copy(x)
    addToast({
      type: 'success',
      title: 'Dado Copiado',
      description: 'Para utilizar basta apenas colar onde quiser!'
    })
  }

  function mostraModel(){
    console.log(model)
  }

  function formatarData(data:string){
    let dataF = data.slice(8,10)+'/'+data.slice(5,7)+'/'+data.slice(0,4)
    return dataF
  }



  return (
    <>
      <Header />
      <Container>
        <CardContainer>
          <div className='title'><h2 onClick={mostraModel}>Cadastro Dados Usuário</h2></div>
          <Form className="form-border" onSubmit={onSubmit}>
            <div>
              <Row className="tipoPessoa">
                <Col>
                <FormLabel id="demo-row-radio-buttons-group-label">Tipo de Pessoa</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="tipoPessoaRadio"
                  onChange={handleChangeCheckPessoa}
                  value={model.tipoPessoa}
                >
                  <FormControlLabel value="PESSOA_FISICA" control={<Radio />} label="Fisíca" />
                  <FormControlLabel value="PESSOA_JURIDICA" control={<Radio />} label="Jurídica" />
                </RadioGroup>
                </Col>
                <Col>
                <FormLabel id="demo-row-radio-buttons-group-label">Data último acesso</FormLabel><br/>
                <FormLabel id="demo-row-radio-buttons-group-label">{
                formatarData(ultimoAcesso?.dataUltimoAcesso?.slice(0,10)||'')}</FormLabel>
                
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col className="select">
                  <Form.Group className="inputNovo">
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Usuário
                    </InputLabel>
                    <NativeSelect
                      className="inputNovo"
                      defaultValue={30}
                      onChange={handleChangeSelected}
                      value={model.usuario.id}
                      disabled
                      inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <option id="0" value="0"></option>
                      {listaUsuarios.map((item) =>
                        <option id={item.nome} value={item.id}>{item.nome}</option>
                      )}
                    </NativeSelect>
                  </Form.Group>
                </Col>
                <Col>
                  <FormControl className="inputNovo codFunc" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      InputLabelProps={{ shrink: model.codigo ? true : false }}
                      className='inputNovo'
                      id="standard-required"
                      label="Código Funcionário"
                      variant="standard"
                      name='codigo'
                      value={model.codigo}
                      onChange={handleChangeInput}

                    />
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      required
                      id="standard-required"
                      label={camposPessoa.nomePrincipal}
                      variant="standard"
                      name='nomePrincipal'
                      value={model.nomePrincipal}
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </Col>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      id="standard-required"
                      label={camposPessoa.nomeSecundario}
                      variant="standard"
                      name='nomeSecundario'
                      value={model.nomeSecundario}
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <div className='datePick'>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DesktopDatePicker
                        label={camposPessoa.data}
                        inputFormat="dd/MM/yyyy"
                        value={age}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params}
                        />
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 3, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      id="standard-required"
                      label={camposPessoa.documentoSecundario}
                      variant="standard"
                      name='documentoSecundario'
                      value={model.documentoSecundario}
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <InputMask
                      mask={camposPessoa.mascaraPessoa}
                      onChange={handleChangeInput}
                      value={model.documentoPrincipal}
                    >
                      {() =>
                        <TextField
                          className='inputNovo'
                          required
                          id={camposPessoa.documentoPrincipal}
                          label={camposPessoa.documentoPrincipal}
                          variant="standard"
                          name='documentoPrincipal'
                        />
                      }
                    </InputMask>
                  </FormControl>
                </Col>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <InputMask
                      mask="99.999-999"
                      onChange={handleChangeInput}
                      value={model.cep}
                    >
                      {() =>
                        <TextField
                          className='inputNovo'
                          required
                          id="standard-required"
                          label="CEP"
                          variant="standard"
                          name='cep'
                        />
                      }
                    </InputMask>
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      required
                      id="endereco"
                      label="Endereço"
                      variant="standard"
                      value={model.logradouro}
                      name="logradouro"
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </Col>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      required
                      id="standard-required"
                      label="Número"
                      variant="standard"
                      value={model.numero}
                      name="numero"
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      required
                      id="standard-required"
                      label="Bairro"
                      variant="standard"
                      value={model.bairro}
                      name="bairro"
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </Col>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      id="standard-required"
                      label="Complemento"
                      variant="standard"
                      value={model.complemento}
                      name="complemento"
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      required
                      id="standard-required"
                      label="Estado"
                      variant="standard"
                      value={model.estado}
                      name="estado"
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </Col>
                <Col>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      required
                      id="standard-required"
                      label="Cidade"
                      variant="standard"
                      value={model.cidade}
                      name="cidade"
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col>
                  <Form.Group className="select">
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Cargo
                    </InputLabel>
                    <NativeSelect
                      className="inputNovo"
                      defaultValue={30}
                      onChange={handleChangeSelectedCargo}
                      value={model.cargo?.id}
                      inputProps={{
                        name: 'cargo',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <option id="0" value="0"></option>
                      {listaCargo.map((item) =>
                        <option id={item.nome} value={item.id}>{item.nome}</option>
                      )}
                    </NativeSelect>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="select">
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Nível
                    </InputLabel>
                    <NativeSelect
                      className="inputNovo"
                      defaultValue={30}
                      onChange={handleChangeSelectedCargo}
                      value={model.nivel?.id}
                      inputProps={{
                        name: 'nivel',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <option id="0" value="0"></option>
                      {listaNivel.map((item) =>
                        <option id={item.nome} value={item.id}>{item.nome}</option>
                      )}
                    </NativeSelect>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col>
                  <Form.Group className="select"                                                                                               >
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Superior
                    </InputLabel>
                    <NativeSelect
                      className="inputNovo"
                      defaultValue={30}
                      onChange={handleChangeSelectedCargo}
                      value={model.superior?.id}
                      inputProps={{
                        name: 'superior',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <option id="0" value="0"></option>
                      {listaUsuarios.map((item) =>
                        <option id={item.nome} value={item.id}>{item.nome}</option>
                      )}
                    </NativeSelect>
                  </Form.Group>
                </Col>
                <Col>
                <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      required
                      id="standard-required"
                      label="E-mail Recuperação"
                      variant="standard"
                      value={model.email}
                      name="email"
                      onChange={handleChangeInputEmailRecuperacao}
                    />
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col>
                  <InputLabel className='labelTelefone' variant="standard" htmlFor="uncontrolled-native">
                    Telefone
                  </InputLabel>
                </Col>
                <Col>
                  <InputLabel className='labelTelefone' variant="standard" htmlFor="uncontrolled-native">
                    E-mail
                  </InputLabel>
                </Col>
              </Row>
            </div>
            <div>
              <Row style={{ marginLeft: '-4px' }}>
                <Col xs={2}>
                  <Form.Group className="linhaTelefone">
                    <InputLabel variant="standard" htmlFor="uncontrolled-native" className="linhaTelefone">
                      Tipo
                    </InputLabel>
                    <NativeSelect
                      className="inputNovo linhaTelefone"
                      defaultValue={30}
                      inputProps={{
                        name: 'tipoContato',
                        id: 'uncontrolled-native',
                      }}
                      onChange={handleChangeSelectContato}
                    >
                      <option value={'CELULAR'}>Celular</option>
                      <option value={'FIXO'}>Fixo</option>
                      <option value={'WHATSAPP'}>WhatsApp</option>
                    </NativeSelect>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <InputMask
                      mask={campoFone.mascara}
                      onChange={handleChangeInputContato}
                      value={modelContato.valor}

                    >
                      {() =>
                        <TextField
                          className='inputNovo'
                          id="standard-required"
                          label={campoFone.telefone}
                          variant="standard"
                          name="valor"
                        />
                      }
                    </InputMask>
                  </FormControl>
                </Col>
                <Col xs={1}>
                  <Button className="botao" onClick={inserirContatoTelefone}><FiPlus size={20} /></Button>
                </Col>

                <Col>

                  <FormControl className="inputNovo" variant="standard" sx={{ m: 1, mt: 1, width: '25ch' }}>
                    <TextField
                      className='inputNovo'
                      id="standard-required"
                      label="E-mail"
                      variant="standard"
                      name="EMAIL"
                      type="email"
                      value={campoEmail.valor}
                      onChange={handleChangeEmail}
                    />
                  </FormControl>
                </Col>
                <Col xs={1}>
                  <Button className="botao" onClick={inserirContatoEmail}><FiPlus size={20} /></Button>
                </Col>
              </Row>
            </div>
            <Row >
              <Col>

                {contatosBanco?.map(function (item) {
                  if (item.tipoContato !== 'EMAIL') {
                    return (
                      <Card className='tituloCard'>
                        <CardHeader

                          avatar={
                            <Avatar sx={{ bgcolor: randomDarkRGB() }} aria-label="recipe">
                              {
                                item.tipoContato.substring(0, 2).toUpperCase()
                              }
                            </Avatar>
                          }
                          action={
                            <IconButton aria-label="settings" onClick={() => excluirFone(item.id)}>
                              <FiXCircle />
                            </IconButton>
                          }
                          title={item.valor}
                          onClick={()=>Copiar(item.valor)}
                        />
                      </Card>)

                  }
                })}
              </Col>
              <Col>
                {contatosBanco?.map(function (item) {
                  if (item.tipoContato === 'EMAIL') {
                    let valor = item.valor;
                    if(valor.length > 30){
                      valor = valor.substring(0,29)+'...'
                    }
                    return (
                      <Card className='tituloCard'>
                        <LightTooltip title={item.valor}>
                        <CardHeader

                          avatar={
                            <Avatar sx={{ bgcolor: randomDarkRGB() }} aria-label="recipe" onClick={()=>Copiar(item.valor)}>
                              {
                                item.tipoContato.substring(0, 2).toUpperCase()
                              }
        
                            </Avatar>
                          }
                          action={
                            <IconButton aria-label="settings" onClick={() => excluirFone(item.id)}>
                              <FiXCircle />
                            </IconButton>
                          }
                          title={valor}
                          onClick={()=>Copiar(item.valor)}
                        />
                        </LightTooltip>
                      </Card>)

                  }
                })}
              </Col>
            </Row>
            <Row style={{ marginLeft: '-4px' }}>
              <Col>
                <Form.Label className="statusLabel">Bloqueado</Form.Label>
                <RadioGroup
                className="statusOpcao"
                row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="bloqueado"
        value={bloqueado}
        onChange={handleChangeCheck}
      >
        <FormControlLabel value={true} control={<Radio />} label="Bloqueado" />
        <FormControlLabel value={false} control={<Radio />} label="Liberado" />
      </RadioGroup>
               {/*} <Form.Group className="statusOpcao">
                  <Form.Check
                    type="radio"
                    label="Ativo"
                    name="bloqueado"
                    inline
                    value="ativo"
                    checked
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeCheck(e)}
                  />
                  <Form.Check
                    type="radio"
                    label="Inativo"
                    name="bloqueado"
                    inline
                    value="inativo"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeCheck(e)}
                  />
              </Form.Group>*/}
              </Col>
              <Col>
                <Form.Label className="statusLabel">Comissão</Form.Label>
                <Form.Group className="statusOpcao">
                  <Form.Check
                    type="radio"
                    label="Quizenal"
                    name="comissao"
                    inline

                  />
                  <Form.Check
                    type="radio"
                    label="Mensal"
                    name="comissao"
                    inline
                    checked
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="buttonsCargo">
              <Button onClick={back}>Cancelar</Button>
              <Button type="submit">Salvar</Button>
            </Form.Group>
          </Form>
        </CardContainer>
      </Container>
    </>
  )
}

export default FormUsers