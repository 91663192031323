import styled from 'styled-components';

const background = ["#c62828", "#ad1457", "#512da8", "#7b1fa2", "#0d47a1", "#0097a7", "#00897b", "#388e3c", "#455a64"]


export const Container = styled.div`

   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;


   Form{
      label{
      color:red;
      font-size: 40px;
      }
   }

   .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 640px;
      margin-bottom: 30px;
   
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
   }

   input{
       width: 100%;
       height: 100%;
       border: none;
   }
`;


export const Teste = styled.div`
   display: flex;
   flex: 1;
   align-items: center;
   width: 50px;
`;


export const Titulo = styled.div`
      display: flex;
      flex: 1;
      align-items: center;
`

export const Parametros = styled.div`
   margin-top: 20px;
   
   display: flex;
   flex-direction: column;
   justify-content: space-between;
  
    ::after, ::before {
    border: 0; 
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

   .aba {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 630px;
      min-width: 332px;
      //max-width: 100%;
      //flex: 0;
   }

   .aba1 {
      border-color: #dee2e6;
    border-style: solid;
    //border-width: 0.2;
    //border-spacing: 2px;
    border: 1px solid #dee2e6;
   }

   .nav-tabs .nav-link {
    width: 314px;
    color: #495057;
   }

   .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0b1f35;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;

    .tab-content {
      border-color: #dee2e6 #dee2e6 #fff;
    }
}
`;

export const Linha = styled.div`
    display: flex;
    flex: 1;
    margin-top: 10px;
    border-color: #dee2e6 #dee2e6 #fff;

    .sqg{
       color:red;
    }

    .sliderValor{
       width: 100%;
       margin-right: 30px;
       margin-left: 30px;
       
    }

    .inputValor{
       margin-top: 20px;
       margin-left: 10px;
       

       input{
         border: 1px solid #dee2e6;
         height: 30px;
         width: 60px;
         margin-right: 20px;
    }
    }

    .inputValor1{
       margin-top: 20px;
       margin-left: 10px;

  
       

       input{
         border: 1px solid #dee2e6;
         height: 30px;
         width: 110px;
         margin-right: 20px;
    }

    .btn .btn-success{
      background: var(--blue);
   }
    }
   
`;

export const Botao = styled.div`
   margin-left: 10px;
   margin-top: 100px;
   
   .btn, .btn-primary{
      background: var(--blue);;
   }
   
`;

export const Alinhamento = styled.div`
    display: flex;
`;