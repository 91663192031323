import { useCallback, useState } from "react"
import { GetCargos } from '../services/Cargos';
import { Cargo } from '../interfaces/cargo';


const Cargos = () => {
    const [cargoLista, setCargo] = useState<Cargo[]>([]);
    
    const getAll = useCallback(async () => {
        const { status, data } = await GetCargos.getAll();
        const ordenado = data.sort(function(a,b){return a.nome.toUpperCase() < b.nome.toUpperCase() ? -1: a.nome.toUpperCase() > b.nome.toUpperCase()? 1:0})


        if(status !== 200) throw new Error();

        setCargo(ordenado) 
    
    },[]);

    const createCargo = useCallback(async (cargo: Pick<Cargo, 'nome' | 'ativo'>) => {
        const { status, data } = await GetCargos.createCargo(cargo);
        
        if(status !== 201) throw new Error();

    }, [])

    const alteraCargo = useCallback(async (cargo: Cargo) => {
        const { status, data } = await GetCargos.alteraCargo(cargo);
        
        if(status !== 201) throw new Error();

    }, [])


    return {
        cargoLista,
        getAll,
        createCargo,
        alteraCargo,
    }
}

export { Cargos }