import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas, Botao, PrimeiraLetra } from './styles';
import { Header } from '../../components/Header';
import { FormHandles } from '@unform/core';
import { FiPlus } from 'react-icons/fi';
import { Table, Button, Modal } from 'react-bootstrap';
import { Cargos } from '../../hooks/cargo';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationCargo';

interface Cargo {
  id: number;
  nome: string;
  ativo: boolean;
}

const Cargo: React.FC = () => {
  const history = useHistory();
  const idCargo = useRef(null);
  const { cargoLista, getAll } = Cargos();
  const [checked, setChecked] = React.useState(false);
  const [listaSearchCargo, setListaSearchCargo] = useState<Cargo[]>(cargoLista);
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchCargo.length / PER_PAGE);
  const _DATA = usePagination(listaSearchCargo, PER_PAGE);
  console.log(_DATA)
  

  const handleChangePagination = (event: object, e:number) => {
    console.log(e)
    setPage(e);
    _DATA.jump(e);
  };


  const handleChange = () => {
    setChecked(!checked);
  }

  function editCargo(id: number) {
    history.push(`/cargoCadastro/${id}`)
  }

  function novoCargo() {
    history.push(`/cargoCadastro/`)
  }

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    const lista: Cargo[] = cargoLista.filter(item => event.target.value ? item.nome.toLowerCase().includes(event.target.value.toLowerCase()) : item);

    setListaSearchCargo(lista);

    setPage(1)
    _DATA.jump(1);
  }

  useEffect(() => {
    getAll();
  }, [getAll])

  useEffect(() => {
    setListaSearchCargo(cargoLista)
  }, [cargoLista])

  function randomDarkRGB() {
    var r = Math.floor(Math.random() * 156);
    var g = Math.floor(Math.random() * 156);
    var b = Math.floor(Math.random() * 156);
    var bgColor = "rgb(" + r + "," + g + "," + b + ")";
    return bgColor;
  }

  return (
    <>
      <Header />
      <Container>
        <div className='title'><h2>Cargo</h2></div>
        <Tabelas>
          <Table bordered hover className='tabela'>
            <thead>
              <tr>
                <th><TextField className='pesquisa' variant='standard' type="text" placeholder="Pesquisar" onChange={handleSearch} /></th>
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map((item) =>
                <tr className="linha">
                  <td
                    className="celula"
                    key={item.id}
                    ref={idCargo}
                    id={item.id.toString()}
                    onClick={() => editCargo(item.id)}>
                    <PrimeiraLetra style={{ backgroundColor: `${randomDarkRGB()}` }}>
                      {item.nome.substring(0, 1).toUpperCase()}
                    </PrimeiraLetra>
                    {item.nome.toUpperCase()}
                  </td>
                </tr>
              )}
               <tr>
                <td>
                  <Pagination className='pagination' count={count} page={page} onChange={handleChangePagination} size="large" />
                </td>
                </tr>
            </tbody>
          </Table>

          <Botao>
            <Button variant="primary" onClick={novoCargo} style={{ border: 'none', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems:"center"}}><FiPlus size={30}/></Button>
          </Botao>
        </Tabelas>


      </Container>
    </>
  )
}

export default Cargo