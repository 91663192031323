export const profissoes = [
	"A€Ugueiro",
	"Acabador De Couro",
	"Acabador",
	"Acessor Parlamentar",
	"Acogueiro",
	"Acompanhante",
	"Açougueira",
	"Acougueiro",
	"Açougueiro",
	"Ad. Escritorio",
	"Adestrador De Animais",
	"Adm. De Empresa",
	"Adm. De Empresas",
	"Adm. De Redes",
	"Adm. Hidraulico",
	"Adm.Pecuar",
	"Adm.Vendas",
	"Administrador De Fazenda",
	"Administrador(A)",
	"Administradora",
	"Advogado(A)",
	"Aeronauta",
	"Aeroportuario",
	"Aeroviario",
	"Ag. Ad. Func. Publico",
	"Ag. Seguranca Judic.",
	"Ag.Org Escolar",
	"Ag.Portaria",
	"Ag.Tecnico",
	"Agen.Funcionario Pub",
	"Agente Administrativo",
	"Agente Ambiental",
	"Agente Comunitario",
	"Agente De Atendimento",
	"Agente De Bordo",
	"Agente De Exportação",
	"Agente De Saude",
	"Agente De Segurança",
	"Agente De Serviços Diversons",
	"Agente De Transportes",
	"Agente De Turismo",
	"Agente Educacional",
	"Agente Fiscal De Rendas",
	"Agente Funerario",
	"Agente Org Escolar",
	"Agente Penitenciario",
	"Agente Policial",
	"Agente Vendas",
	"Agente",
	"Agricultor",
	"Agronomo",
	"Agropecuarista",
	"Aj.Geral",
	"Aj.Granjai",
	"Aj.Motoris",
	"Ajud. Motorista",
	"Ajud.Geral",
	"Ajudante De Cozinha",
	"Ajudante De Eletricista",
	"Ajudante De Produção",
	"Ajudante Geral",
	"Ajudante Marceneiro",
	"Ajudante",
	"Ajustador",
	"Alfaiate",
	"Alimentador De Producao",
	"Almoxarife",
	"Almoxarife",
	"Almoxaris",
	"Analista Contabil",
	"Analista De Importação",
	"Analista De Marketing",
	"Analista De Processo",
	"Analista De Redes",
	"Analista De Rh",
	"Analista De Sistemas",
	"Analista Financeiro",
	"Analista Fiscal",
	"Analista Quimico",
	"Analista",
	"Apicultor",
	"Aplicador",
	"Apontador",
	"Aposentado(A)",
	"Armador i",
	"Armador",
	"Arquiteto(A)",
	"Arquivista",
	"Arrematadeira",
	"Arte Finalista",
	"Artesao",
	"Asc.Finace",
	"Ass De Producao",
	"Ass. Administrativo",
	"Ass. Financeiro(A)",
	"Ass. Operações",
	"Ass. Social",
	"Ass.Parlam",
	"Assesor De Exportação",
	"Assessor De Imprensa",
	"Assessor Executivo",
	"Assessor Juridico",
	"Assessora De Marketing",
	"Assist. Cobranca",
	"Assist. Judicial",
	"Assist.Comercial",
	"Assistente Administrativo",
	"Assistente Comercial",
	"Assistente Especial",
	"Assistente Financeiro",
	"Assistente Fiscal",
	"Assistente Juridico",
	"Assistente Marketing",
	"Assistente Social",
	"Assistente Tecnico",
	"Assistente",
	"Assit.Soc3",
	"At. Comerc",
	"Atendente Amostra",
	"Atendente Caixa",
	"Atendente Comercial",
	"Atendente De Aeronaves",
	"Atendente De Farmacia",
	"Atendente De Frios",
	"Atendente De Pedagio",
	"Atendente De Saude",
	"Atendente De Telemarketing",
	"Atendente",
	"Ator",
	"Auditor",
	"Aumoxarifado",
	"Autonomo(A)",
	"Aux De Compras",
	"Aux De Cozinha",
	"Aux De Dentista",
	"Aux De Estoque",
	"Aux De Expedição",
	"Aux De Producao",
	"Aux Enfermagem",
	"Aux Fiaçá",
	"Aux. Cabelereiro",
	"Aux. Comercio",
	"Aux. Conservacao Urb",
	"Aux. Contabil",
	"Aux. Contabilidade",
	"Aux. De Credito",
	"Aux. De Educação Básica",
	"Aux. De Enfermagem",
	"Aux. De Escritorio",
	"Aux. De Farmacia",
	"Aux. De Laboratorio",
	"Aux. De Lavanderia",
	"Aux. De Produ€Ao",
	"Aux. De Serviço De Saneamento",
	"Aux. Enfermagem",
	"Aux. Enfremagem",
	"Aux. Escr.",
	"Aux. Escritorio",
	"Aux. Farmaceutico",
	"Aux. Faturamento",
	"Aux. Limpeza",
	"Aux. Mecanico Industrial",
	"Aux. Pintura Em Aeronaves",
	"Aux. Producao",
	"Aux. Quimico",
	"Aux. Saude",
	"Aux. Suinocultura",
	"Aux. Tecnico",
	"Aux.Acougue.",
	"Aux.Apoio.Serv.Comer",
	"Aux.Cilindro",
	"Aux.Com.Frios",
	"Aux.Comercio",
	"Aux.Contab",
	"Aux.Contabil",
	"Aux.De Campo",
	"Aux.De Cozinha",
	"Aux.De Farmacia",
	"Aux.De Montagem",
	"Aux.Enferm",
	"Aux.Escrit",
	"Aux.Escritorio",
	"Aux.Geral",
	"Aux.Labor.",
	"Aux.Mecan.",
	"Aux.Prod.",
	"Aux.Produc",
	"Aux.Secret",
	"Aux.Serr.",
	"Aux.Serv.Gerais",
	"Aux.Termico",
	"Auxil.Escritorio",
	"Auxiliar Administrador",
	"Auxiliar Administrativo",
	"Auxiliar Biblioteca",
	"Auxiliar Contabil",
	"Auxiliar De Cartorio",
	"Auxiliar De Cilindro",
	"Auxiliar De Classe",
	"Auxiliar De Cozinha",
	"Auxiliar De Dentista",
	"Auxiliar De Dep. Pessoal",
	"Auxiliar De Depósito",
	"Auxiliar De Enfermag",
	"Auxiliar De Escritorio",
	"Auxiliar De Estoque",
	"Auxiliar De Estrusão",
	"Auxiliar De Farmacia",
	"Auxiliar De Fotógrafo",
	"Auxiliar De Imspeção",
	"Auxiliar De Jardinagem",
	"Auxiliar De Laticinio",
	"Auxiliar De Limpeza",
	"Auxiliar De Logistica",
	"Auxiliar De Manutenção",
	"Auxiliar De Perecíveis",
	"Auxiliar De Pesponto",
	"Auxiliar De Planejam",
	"Auxiliar De Prancheamento",
	"Auxiliar De Produ€Ao",
	"Auxiliar De Recepção",
	"Auxiliar De Serviços",
	"Auxiliar De T.I.",
	"Auxiliar De Vendas",
	"Auxiliar Enfermagem",
	"Auxiliar Escritorio",
	"Auxiliar Financeiro",
	"Auxiliar Financeiro",
	"Auxiliar Fiscal",
	"Auxiliar Fiscal",
	"Auxiliar Geral",
	"Auxiliar Geral",
	"Auxiliar Industrial",
	"Auxiliar Laboratorio",
	"Auxiliar Manut. Agricola",
	"Auxiliar Odontologia",
	"Auxiliar Producao",
	"Auxiliar Tecnico",
	"Auxiliar",
	"Av. Joao Xxiii, 1509",
	"Avalista Finaceiro(A)",
	"Ax. Comercio",
	"Ax. Contas A Pagar",
	"Ax. Deposi",
	"Ax. Limpez",
	"Ax.Enferm.",
	"Ax.Marcineiro",
	"Ax.Optico",
	"Ax.Trafego",
	"Azulegista",
	"Baba",
	"Bacharel Direito",
	"Bailarina",
	"Balanceiro",
	"Balconista",
	"Bancario(A)",
	"Banqueiro",
	"Barbeiro",
	"Bauconista",
	"Berçarista",
	"Bibliotecario(A)",
	"Bio-Medico",
	"Biologo",
	"Biomedica",
	"Bioquimico",
	"Blaqueador",
	"Bobinador",
	"Bombeiro Hidraulico",
	"Bombeiro",
	"Bordador",
	"Borracheiro",
	"Braquiador",
	"Cabeleireiro(A)",
	"Caixa",
	"Calcadista",
	"Calculista Judicial",
	"Caldeireiro Auxiliar",
	"Caldeireiro",
	"Camareira",
	"Caminhoneiro",
	"Cantor",
	"Carcereiro",
	"Cargueiro",
	"Caribador",
	"Carneador",
	"Carpinteiro",
	"Carregador",
	"Carreteiro",
	"Carteiro(A)",
	"Caseiro",
	"Cenografo",
	"Ceramista",
	"Ch. Pecas",
	"Chanfradeira",
	"Chanfrador",
	"Chapeiro",
	"Chaveiro",
	"Chefe De Cozinha",
	"Chefe Encarregado",
	"Chefe Produção",
	"Ciclista",
	"Cinegrafista",
	"Cirurgiao Dentista",
	"Classificador",
	"Co- Piloto",
	"Cobrador(A)",
	"Coladeira",
	"Colador De Cartaz",
	"Coletor",
	"Colhedor",
	"Colorista",
	"Comboista",
	"Comercial",
	"Comerciante Autonomo",
	"Comerciante",
	"Comerciario(A)",
	"Comercio",
	"Comissaria De Voo",
	"Compositor",
	"Comprador",
	"Conciliador",
	"Confeccion",
	"Confeiteiro(A)",
	"Conferente",
	"Cons. Beleza",
	"Cons. Tecnico",
	"Cons. Viagem",
	"Conselheiro Tutelar",
	"Construção Civil",
	"Construtor",
	"Construtor",
	"Consultor Artistico",
	"Consultor De Empresa",
	"Consultor De Vendas",
	"Consultor(A) Financeiro(A)",
	"Consultora De Vendas",
	"Consultora",
	"Contabilista",
	"Contador (A)",
	"Contas A Pagar",
	"Contra Mestre",
	"Controlador Aluno",
	"Controlador De Acesso",
	"Controlador De Frota",
	"Controlador De Trafego Aereo",
	"Controlador",
	"Controle De Qualidade",
	"Conultor",
	"Coord. Pedagogica",
	"Coord. Setor Sucroalcooleiro",
	"Coord. Tecnico",
	"Coordenad.",
	"Coordenador De Vendas",
	"Coordenador",
	"Coordenadora De Atendimento",
	"Coordenadora Secretaria",
	"Coordenadora",
	"Copeira",
	"Copeira",
	"Copeiro",
	"Copista",
	"Cor.Segur.",
	"Cordenador Administrativo",
	"Cordenador De Produção",
	"Cordenador De Projetos",
	"Cordenador Patio",
	"Coringa",
	"Coronel Policia Militar",
	"Corretor (A)",
	"Corretor De Seguros",
	"Cortador Manual",
	"Cortador",
	"Cortumeira",
	"Costureira",
	"Costureiro",
	"Coxoeiro",
	"Cozedor",
	"Cozinhador De Açucar",
	"Cozinheira",
	"Cozinheiro",
	"Cozinheiro(A)",
	"Crediarist",
	"Cuidador(A)",
	"Cumim",
	"Curtumeiro",
	"Decorador",
	"Defentor Publico",
	"Delegado",
	"Dentista",
	"Dep. Compras",
	"Dep.Pessoal",
	"Depiladora",
	"Depto.Cobr",
	"Desencelador De Tripa",
	"Desenhista",
	"Desenvolvedor",
	"Desenvolvedor",
	"Designer De Interiores",
	"Designer Gráfico",
	"Designer",
	"Desossador",
	"Despachant",
	"Despachante",
	"Destilador",
	"Diarista",
	"Digitador",
	"Digitadora",
	"Dir. Administrativa",
	"Dir.Serv.",
	"Diretor Adm.",
	"Diretor De Rodeio",
	"Diretor Teatral",
	"Diretor Vendas",
	"Diretor",
	"Diretora Comercial",
	"Diretora Financeira",
	"Diretora Secretaria",
	"Diretora",
	"Distribuidor",
	"Do Lar",
	"Domador",
	"Domestica",
	"Economiario",
	"Economista",
	"Editor - Cinegrafista",
	"Editor De Imagem",
	"Editora",
	"Eduacadora Fisica",
	"Educador Fisico",
	"Educadora",
	"Eleitorista Entrega",
	"Eletric.",
	"Eletricist",
	"Eletricista De Auto",
	"Eletricista Industrial",
	"Eletricista Naval",
	"Eletricista",
	"Eletricita",
	"Eletrotecnico",
	"Embalador",
	"Embarcador",
	"Empilhadeirista",
	"Emplacador",
	"Empregado De Obras",
	"Empreiteiro",
	"Empresaria",
	"Empresario",
	"Empressario",
	"Empressor",
	"Enc Dep. Financeiro",
	"Enc. Agropecuaria",
	"Enc. Dep. Vendas",
	"Enc.Coml.",
	"Enc.Mecan.",
	"Enc.Operacional",
	"Encanador",
	"Encarredado Manutenção",
	"Encarregada",
	"Encarregado Cobrança",
	"Encarregado De Adm.",
	"Encarregado De Caixa",
	"Encarregado De Caldeira",
	"Encarregado De Estoque",
	"Encarregado De Obras",
	"Encarregado De Patio",
	"Encarregado De Producao",
	"Encarregado De Setor",
	"Encarregado Maq. Agricola",
	"Encarregado Maq. Automotiva",
	"Encarregado Mecanica",
	"Encarregado Operacional",
	"Encarregado Transportes",
	"Encarregado",
	"Endustriaria",
	"Enfermeira",
	"Enfermeiro",
	"Enfestador",
	"Eng Agronomo",
	"Eng. Civil",
	"Eng. Mecanico",
	"Eng. Quimico (A)",
	"Eng.Agron.",
	"Eng.Civil",
	"Engenheir Ceramico",
	"Engenheiro Agronomo",
	"Engenheiro Civil",
	"Engenheiro Eletrico",
	"Engenheiro Mecanico",
	"Engenheiro",
	"Engenheiro(A) Alimentos",
	"Enrolador De Bobina",
	"Enseminador",
	"Ent.Jornal",
	"Entregador - Linha De Leite",
	"Entregador",
	"Esc.Fiduciario",
	"Escalador De Couro",
	"Escrevente",
	"Escritora",
	"Escrituario",
	"Escriturar",
	"Escrituraria",
	"Escriturario",
	"Escrivão De Policia",
	"Espc. Materiais",
	"Espe€Ao De Laranjas",
	"Especilalista Serviços",
	"Estagiario",
	"Esteticista",
	"Estilista",
	"Estoquista",
	"Estrutor",
	"Estudante",
	"Eux.Escrit",
	"Excrituraria",
	"Exec. De Vendas",
	"Executiva",
	"Expedidor",
	"F. Publico",
	"F.Pub.Est.",
	"Fabricante",
	"Farmaceutica",
	"Farmaceutico",
	"Faturista",
	"Faxineira(O)",
	"Fazendeiro(A)",
	"Feirante",
	"Ferroviario(A)",
	"Financeira",
	"Financeiro",
	"Financiario",
	"Fiscal De Caixa",
	"Fiscal De Campo",
	"Fiscal",
	"Fisioterapeuta",
	"Fisiterap.",
	"Florista",
	"Fonoaudiologa",
	"Formeiro",
	"Fornecedor De Formas",
	"Fotografo(A)",
	"Frangueiro",
	"Frentista",
	"Fresador Mecanico",
	"Frezador Ferramentei",
	"Frizador",
	"Fuc. Publica",
	"Func Publica",
	"Func Publico",
	"Func. Publica",
	"Func. Publico Estado",
	"Func. Publico",
	"Func.Publ.",
	"Func.Publico",
	"Funcionaria Publica",
	"Funcionario Publico",
	"Fundicao",
	"Fundidor",
	"Funileiro",
	"Galvanoplasta",
	"Galvanoplastia",
	"Garageiro",
	"Garagista",
	"Garcom",
	"Garcon",
	"Garçon",
	"Garconete",
	"Gari",
	"Ger.Adm.",
	"Ger.Geral",
	"Ger.Oper.",
	"Ger.Pecas",
	"Gerente Adm.",
	"Gerente Administrativo",
	"Gerente Comercial",
	"Gerente De Cobranças",
	"Gerente De Compras",
	"Gerente De Estoque",
	"Gerente De Marketing",
	"Gerente De Pos Vendas",
	"Gerente De Produ€Ao",
	"Gerente De Producao",
	"Gerente De Rh",
	"Gerente De Vendas",
	"Gerente Delegado",
	"Gerente Fazenda",
	"Gerente Financeiro",
	"Gerente Geral",
	"Gerente Industrial",
	"Gerente Operacional",
	"Gerente Producao",
	"Gerente Restaurante",
	"Gerente Rh",
	"Gerente Socio",
	"Gerente",
	"Gesseiro",
	"Gestora Financeira",
	"Governanta",
	"Grafico",
	"Grfico",
	"Guarda Municipal",
	"Higienizadora",
	"Horticultura",
	"Hoteleiro",
	"Impressor",
	"Industr.",
	"Industria.",
	"Industrial",
	"Industriar",
	"Industriario",
	"Indutrial",
	"Insp De Qualidade",
	"Insp. De Qualidade",
	"Inspetor De Aluno",
	"Inspetor Trafico",
	"Inspetora",
	"Inst De Informatica",
	"Instalador",
	"Instrumentador",
	"Instrumentista",
	"Instrutor",
	"Investigador",
	"Jardineiro",
	"Jogador De Basquete",
	"Jogador Futebol ",
	"Jornalista",
	"Juiz",
	"Juiza",
	"Laboratorista",
	"Laminador",
	"Lapidario",
	"Lavador De Veiculos",
	"Lavrador",
	"Leiteiro",
	"Leiturista",
	"Lider Agricola",
	"Lider De Logistica",
	"Lider De Manutenção ",
	"Lider De Negocios",
	"Lider De Produção",
	"Limpador De Vidros",
	"Lixador",
	"Locutor",
	"Lojistica",
	"Lombador",
	"Lubrificador",
	"Lustradeira",
	"Lustrador De Madeira",
	"Mandrilhador",
	"Manicure",
	"Manobrista",
	"Manuten€Ao",
	"Maquiador(A)",
	"Maquinista",
	"Marceneiro",
	"Marcineiro",
	"Marinheiro",
	"Marmorista",
	"Masseiro",
	"Matizador",
	"Matrizaria",
	"Mec De Manutencao",
	"Mec Inustriaria",
	"Mecanico De Manut",
	"Mecanico Manutencao",
	"Mecanico Maquinas",
	"Mecanico",
	"Mecatronico",
	"Medica",
	"Medico Bioquimico",
	"Medico",
	"Mensageiro (A)",
	"Mercadologico",
	"Mestre De Obras",
	"Metaluigico",
	"Metalurgico,",
	"Metalurgico",
	"Metalurgico(A)",
	"Metri",
	"Micro Empresario",
	"Micro-Empr",
	"Micro-Empresario",
	"Microempr.",
	"Microempresario",
	"Militar Aposentado",
	"Militar Reformado",
	"Militar",
	"Miltar Aposentado",
	"Min.Evang.",
	"Mineirador",
	"Ministro Religioso",
	"Modelador",
	"Modelista",
	"Modelo\/Ator",
	"Moldador",
	"Monitor",
	"Montador Manual",
	"Montador(A)",
	"Mot.Auton.",
	"Motaxista",
	"Moto Boy",
	"Moto Taxista",
	"Moto-Taxista",
	"Motorista Autonomo",
	"Motorista Escolar",
	"Motorista Operador",
	"Motorista Rodotrem",
	"Motorista Vendedor",
	"Motorista,",
	"Motorista",
	"Movimentador De Mercadorias",
	"Musico",
	"Não Informado",
	"Ncelos",
	"Nihill",
	"Niquelador",
	"Nutricionista",
	"O Mesmo Residencial",
	"Odotologista",
	"Office Boy",
	"Officeboy",
	"Oficial Administrativa",
	"Oficial Diligencia",
	"Oficial Manutencao",
	"Oficial Pm",
	"Oficial Promotoria",
	"Oficial",
	"Op De Audio",
	"Op De Empilhadeira",
	"Op De Maquina",
	"Op De Maquinas",
	"Op Logistica",
	"Op. Caixa",
	"Op. Cinematografico",
	"Op. De Colhetadeira",
	"Op. De Fonte Rolante",
	"Op. De Manufatura",
	"Op. De Maquina",
	"Op. De Producao",
	"Op. De Veic. Ind.",
	"Op. Manufatura",
	"Op. Maquinas",
	"Op. Supermercado",
	"Op.De Maquinas",
	"Op.Industrial",
	"Op.Manufatura",
	"Oper. Motoniveladora",
	"Oper. Telemarketing",
	"Oper.De Colhedora De Cana",
	"Oper.Producao",
	"Operador Computador",
	"Operador De Atendimento",
	"Operador De Caldeira",
	"Operador De Empilhadeira",
	"Operador De Eta",
	"Operador De Industria",
	"Operador De Maquina",
	"Operador De Maquinas",
	"Operador De Modelado",
	"Operador De Moinho",
	"Operador De Painel",
	"Operador De Patio",
	"Operador De Prensa",
	"Operador De Produção",
	"Operador De Retifica",
	"Operador De Sistema",
	"Operador De Teleatendimento",
	"Operador Equipamento",
	"Operador Manufatura",
	"Operador Maquina",
	"Operador Maquinas",
	"Operador Polivalente",
	"Operador Produ€Ao",
	"Operador Producao",
	"Operador Retiro",
	"Operador Rodoviario",
	"Operador",
	"Operadora De Caixa",
	"Operadora De Micro",
	"Operadora De Negocios",
	"Operadora Tele Vendas",
	"Operaria",
	"Operario",
	"Orcamentista",
	"Ordenhador",
	"Orientador",
	"Ortador",
	"Ourives",
	"P. Militar",
	"Padeiro",
	"Paisagista",
	"Pastor Evangelico",
	"Pastor",
	"Patrulheiro",
	"Pecuarista",
	"Pedagoga",
	"Pediatra",
	"Pedreiro",
	"Pensionista",
	"Personal Trainer",
	"Pesadora",
	"Pespontadeira",
	"Pespontador",
	"Piloto",
	"Pinguimentador",
	"Pintor De Autos",
	"Pintor Industrial",
	"Pintor",
	"Piscineiro (Limpador)",
	"Pitor",
	"Pizzaiolo",
	"Pm",
	"Podologa",
	"Pol.Milit.",
	"Polic.Mil.",
	"Policia",
	"Policial Militar",
	"Policial",
	"Pontiador",
	"Porteiro Tel",
	"Porteiro",
	"Pos Venda",
	"Prefeito",
	"Prencista",
	"Prenseira",
	"Prep. Vulcanizacao",
	"Preparador De Caixa",
	"Preparador De Esmalte",
	"Preparador De Pintura",
	"Prestadora Serviços",
	"Prod. Som",
	"Produção",
	"Produtor Grafico",
	"Produtor Musical",
	"Produtor Rural",
	"Professor Informat.",
	"Professor Particular",
	"Professor",
	"Professora Auxiliar",
	"Professora i",
	"Professora Universitária",
	"Professora",
	"Professsor(A) Ed. Fisica",
	"Profissional Liberal",
	"Progetista",
	"Programador Torno Cnc",
	"Programador",
	"Programadora",
	"Projetista",
	"Promotor De Eventos",
	"Promotor De Vendas",
	"Promotor Tecnico",
	"Promotor",
	"Promotora De Vendas",
	"Propietario",
	"Propriet.",
	"Proprietario",
	"Protetico",
	"Provador Classificante",
	"Psicologa",
	"Psicologo(A)",
	"Psiquiatra",
	"Publicidade",
	"Publicitaria",
	"Publicitario",
	"Quimico",
	"R.Comercial",
	"R.Publicas",
	"Radialista",
	"Radiologista",
	"Recepcion.",
	"Recepcionista",
	"Reciclador",
	"Recpcionia",
	"Recursos Humanos",
	"Rel.Public",
	"Relojoeiro",
	"Rep. Comercial",
	"Rep.Comerc",
	"Repes. Comercial",
	"Repositor",
	"Repres. Comercial",
	"Repres.Comercia",
	"Repres.Comercial",
	"Representante Comerc",
	"Representante",
	"Retificador",
	"Retireiro",
	"Revendedora De Roupas",
	"Revisor",
	"Revisora",
	"S. Gerais",
	"S.Justica",
	"s",
	"Salgadeiro",
	"Sapateira",
	"Sapateiro.",
	"Sapateiro",
	"Secretaria Adm.",
	"Secretaria De Educação",
	"Secretaria Do Lar",
	"Secretaria Estudanti",
	"Secretaria",
	"Secretário",
	"Securitaria",
	"Seguranca",
	"Segurança",
	"Ser.Gerais",
	"Seringueiro",
	"Serralheir",
	"Serralheiro",
	"Serv. Publ",
	"Serv. Publico",
	"Serv.Expedi€Ao",
	"Serv.Ger.",
	"Serv.Gerais",
	"Servente Escolar",
	"Servente Pedreiro",
	"Servgerais",
	"Servi€Os Gerais",
	"Servicos Diversos",
	"Servicos Gerais",
	"Serviços Gerais",
	"Servicos",
	"Servidor Publico",
	"Servidora Publica",
	"Sindicalista",
	"Sitiante",
	"Socia Proprietaria",
	"Socia-Prop",
	"Socio Ger.",
	"Socio Proprietario",
	"Solador",
	"Soldado Pm",
	"Soldado",
	"Soldador",
	"Sommelier",
	"Sup.Comun.",
	"Sup.Manut.",
	"Superv. Lavanderia",
	"Superv. Producao",
	"Superv. Vendas",
	"Superv.Adm",
	"Supervisor Campo",
	"Supervisor De Mecanica",
	"Supervisor De Obra Civil",
	"Supervisor Producao",
	"Supervisor",
	"Supervisora",
	"Supridor",
	"Sushiman",
	"T.I.",
	"Tabelião",
	"Tapeceiro",
	"Taxista",
	"Tec Em Protese",
	"Tec Em Raio X",
	"Tec Enfermagem",
	"Tec Judiciario",
	"Tec Metalurgico",
	"Tec. Administrativo",
	"Tec. Agricola",
	"Tec. Agropecuaria",
	"Tec. Contabilidade",
	"Tec. De Oftalmologia",
	"Tec. Eletricidade",
	"Tec. Eletromecanica",
	"Tec. Em Ar Condicionado",
	"Tec. Em Eletronica",
	"Tec. Em Eletronica",
	"Tec. Em Iluminação",
	"Tec. Em Radioerapia",
	"Tec. Fazendaria",
	"Tec. Informatica",
	"Tec. Montagem",
	"Tec. Radiologia",
	"Tec. Refrigeracao",
	"Tec. Saneamento ",
	"Tec. Segurança Eletronica",
	"Tec. Segurança Trabalho",
	"Tec. Seguranca",
	"Tec. Serigrafia",
	"Tec. Som",
	"Tec. Telefonia",
	"Tec.Eletr.",
	"Tec.Elev.",
	"Tec.Manuten€Ao",
	"Tec.Opitico",
	"Tec.Otica",
	"Tec.Pat.Cl",
	"Tec.Polissonografia",
	"Tec.Proces",
	"Tec.Seg. Trabalho",
	"Tec.Seg.Trabalho",
	"Tec.Telecomunicacoes",
	"Tecnica Enfermagem",
	"Tecnico Aeronautica",
	"Tecnico Agricola",
	"Tecnico Agricola",
	"Técnico Agricola",
	"Tecnico Contabil",
	"Tecnico Eletronica",
	"Tecnico Eletronico",
	"Tecnico Em Ceramica",
	"Técnico Em Enfermagem",
	"Técnico Em Informática",
	"Tecnico Em Manufatura",
	"Tecnico Em Meio Ambiente",
	"Tecnico Em Otica",
	"Tecnico Em Radiologia",
	"Tecnico Em T.I.",
	"Tecnico Hospitalar",
	"Tecnico Manutencao",
	"Tecnico Mecanico",
	"Tecnico Planejamento",
	"Tecnico Quimico",
	"Tecnico Responsavel.",
	"Tecnico Suporte",
	"Tecnico",
	"Tele Atendimento",
	"Telefonista",
	"Tenente Militar",
	"Tenico Informatica",
	"Terapeuta",
	"Tesoureira",
	"Topografo",
	"Torn. Mecanico",
	"Torn.Mecan",
	"Torneio Mecanico",
	"Torneiro Mec.",
	"Torneiro Mecanico",
	"Torneiro",
	"Tornriro Mecanico",
	"Tosador Canino",
	"Trabalhador Rural",
	"Transport.",
	"Transportador",
	"Transportes",
	"Tratador",
	"Tratorista",
	"Universitaria",
	"Usineiro(A)",
	"Vendedor Autonomo",
	"Vendedor",
	"Vendedor3",
	"Vendedora Autonoma",
	"Vendedora",
	"Vereador",
	"Vereadora",
	"Veterinario (A)",
	"Vice Diret",
	"Vice Presidente",
	"Vidraceiro",
	"Vigia",
	"Vigilante",
	"Visitador Sanitario",
	"Vistoriador Veiculos",
	"Vulcanizador",
	"Zelador(A)",
	"Zootecnist",
]