import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas, Tabelas1, Botao, PrimeiraLetra } from './styles';
import { Header } from '../../components/Header';
import { FaBarcode } from 'react-icons/fa';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';
import { Planoh } from '../../hooks/plano';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Boletoi } from '../../interfaces/boleto'
import { Remessa } from '../../interfaces/remessa'
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationBoleto';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import NativeSelect from '@mui/material/NativeSelect';
import Link from '@mui/material/Link';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FiDownload } from 'react-icons/fi';
import Iframe from 'react-iframe';



const Bivendas: React.FC = () => {
  const { addToast } = useToast();
  moment.locale('pt-br');



  return (
    <>
      <Header />
      <Container>
        <Iframe 
          url="https://app.powerbi.com/view?r=eyJrIjoiNGEyYjY3OGEtMThkMC00YzU0LThjOGQtMTg4MzcxZjVlZjVlIiwidCI6IjEzNTEyZjMwLTVhYTYtNGNjYy1hNDJjLWU5NTM0ZjE2MWE5YSJ9" 
          width='90%'
          height='800px'
        />

      </Container>

    </>
  )
}

export default Bivendas