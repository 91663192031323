import { Container } from './styles';


export function Footer() {


  return (
    <>
    <Container>

    </Container>
    </>
  )
}