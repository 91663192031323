import { useCallback, useState } from "react"
import { GetRelatorio } from '../services/Relatorios';
import { TabelaBensParcela } from '../interfaces/tabelasBensParcela';


const RelatoriosParcela = () => {
    
    const [parcelas, setParcelas] = useState<TabelaBensParcela[]>([]);
    
    const getAllTabelaParcelas = useCallback(async (id: number) => {
        const { status, data } = await GetRelatorio.getAllTabelaParcelas(id);
        if(status !== 200) throw new Error();
        setParcelas(data)
    },[]);


    return {
        parcelas,
        getAllTabelaParcelas
    }
}

export { RelatoriosParcela }