import { useCallback, useState } from "react"
import { GetMenu } from '../services/Menu';
import { Menu as MenuInterface } from '../interfaces/menu';


const Menu = () => {
    const [menuLista, setMenu] = useState<MenuInterface[]>([]);
    
    const getAll = useCallback(async () => {
        const { status, data } = await GetMenu.getAll();

        if(status !== 200) throw new Error();

        setMenu(data) 
    
    },[]);

    const createMenu = useCallback(async (menu: Pick<MenuInterface, 'nome' | 'menusInferiores'>) => {
        const { status, data } = await GetMenu.createMenu(menu);
        
        if(status !== 201) throw new Error();

    }, [])

    const alteraMenu = useCallback(async (menu: MenuInterface) => {
        const { status, data } = await GetMenu.alteraMenu(menu);
        
        if(status !== 201) throw new Error();

    }, [])


    return {
        menuLista,
        getAll,
        createMenu,
        alteraMenu,
    }
}

export { Menu }