import styled from 'styled-components';

const background = ["#c62828", "#ad1457", "#512da8", "#7b1fa2", "#0d47a1", "#0097a7", "#00897b", "#388e3c", "#455a64"]


export const Container = styled.div`

   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;


   Form{
      label{
      color:red;
      font-size: 40px;
      }
   }

   .labelNative{
      margin-top:30px;
      margin-left: 8px;
   }

   .inputNovo{
      max-width: 620px;
      width: 617px;
      margin-left: 10px;
   }

   
   select:focus {
      background-color: white;
   }

   .inputNovo1{
      max-width: 180px;
      width: 280px;
      margin-left: 6px;

   }
   .inputNovo2{
      max-width: 400px;
      width: 617px;

   }

   .inputNovo3{
      max-width: 400px;
      width: 617px;
      margin-left: 10px;
      margin-top: 30px;

   }

   .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 640px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
   }

   .ListaGeral{
      margin-left: 30px;
      margin-top: 15px;
   }

   


`;


export const Teste = styled.div`
   display: flex;
   flex: 1;
   align-items: center;
   width: 50px;
`;

export const Section = styled.section`
   display: flex;
   
   margin-top: 48px;
   width: 400px;
   

   strong {
       display: flex;
       flex-wrap: wrap;
       flex: 1;
       justify-content: right;
       color: #999591;
       font-size: 40px;
       line-height: 26px;
       border-bottom: 1px solid #797571;
       display: block;
       padding-bottom: 16px;
   }

`
export const Titulo = styled.div`
      display: flex;
      flex: 1;
      align-items: center;
`

export const Cargos = styled.div`
     display: flex;
     flex-direction: column;
     

     a{
        display: flex;
        justify-content: left;
        flex: 1;
        width: 400px;
        align-items: center;
        padding: 16px 14px;
        border-radius: 10px;
        margin-top: 10px;
        position: relative;
        background: #969cb3;
        color: #f4ede8;
     }

     span {
         display: flex;
         align-items: center;
         justify-content: center;
         color: #363f5f;
         font-size: 30px;
         width: 45px;
         height: 45px;

         border-radius: 50%;
         background: #f4ede8;
     }

     strong{
         margin-left: 20px;
     }

     input {
         margin-top: 15px;
     }
`
export const Tabelas = styled.div`
   max-width: 700px;
   width: 80%;
   display: flex;

   .tabela{
     vertical-align: center;
   }

   .celula{
      display: flex;
      align-items: center;
   }

   .linha{
      /* height: 60px; */
      vertical-align: center;
   }
   
   .pesquisa{
      width: 100%;
   }

   .botao1{
      margin-left: 30px;
      width: 100%;
   }

   .deletar{
      font-size: 30px;
      color: red;
   }

`;



export const PrimeiraLetra = styled.div`
   vertical-align: center;   
   width: 40px;
   height: 40px;
   border-radius: 20px;
   font-size: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 20px;
   color: #ffffff;
`;

export const CardContainer = styled.div`
    display:flex;
    flex-direction: column;
    flex:1;
    align-items:center;
    justify-content:space-between;
    border: 1px solid currentColor;
    border-color: #cfd4d8;

    width: 640px;
    
    //flex-direction: column;
   
    //top:-10px;
    //position: absolute;
    //background-color:#ffffff;
    //z-index: 50;
    //margin-top: 50px;

    .title{
      margin-top: 50px;
      background-color: var(--blue);
      color: white;
      width: 100%;
      //max-width: 840px;
      display: flex;
      align-items: center;
      justify-content: center;
      //border-top-right-radius: 10px;
      //border-top-left-radius: 10px;
      height: 60px;
   }

   .select{
      max-width: 840px;

      .row{
         max-width: 660px;
      }

      .col{
         width: 100%;
      }
   }

   .buttonsCargo{
        display: flex;
        margin-top: 35px;
        justify-content:flex-end;
        margin-bottom: 20px;
        margin-right: 5px;
        
        Button{
            margin-left: 10px;
            background-color: var(--blue);;
            min-width: 100px;
            border: none;
        }
        
       
    }
`;

export const Botao = styled.div`
   font-size: 20px;
   border: none;
   width: 300px;

   .btn, .btn-primary{
      background: var(--blue);;
   }
 
`;