import styled from 'styled-components';

export const Container = styled.div`
  .btn, .btn-check:focus+ .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
    //height: 100%;
    //margin-left: 10px;
}

  .list-group-item .accordion-header{
    background-color: red;
  }

`;