import React, { ChangeEvent, useState, useEffect } from "react";
import { Form, Button, Row, Col, Table } from 'react-bootstrap';
import { Container } from './styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Tabela } from './styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import api from '../../../services/api';
import FormControl from '@mui/material/FormControl';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../hooks/toast';
import { Contato } from '../../../interfaces/contato';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import IconButton from '@mui/material/IconButton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ContatoConsulta } from '../../../interfaces/contatoConsulta';
import copy from 'copy-to-clipboard';
import NumberFormat from "react-number-format";

const FIXO = {
  telefone: 'Telefone Comercial',
  mascara: '(99) 9999-9999',
}

const WHATSAPP = {
  telefone: 'WhatsApp',
  mascara: '(99) 99999-9999'
}

const CELULAR = {
  telefone: 'Celular',
  mascara: '(99) 99999-9999',
}

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      //decimalSeparator={","}
    
      prefix={"R$ "}
      // isNumericString

      format={currencyFormatter}
    />
  );
}

function currencyFormatter(value: any){
  if(!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value/100);

  return amount

}

export function Etapa6CapacidadeFinanceiraPJ(props: any) {

  const { addToast } = useToast();

  const { DadosVenda, setAlteraDados, setAlteraPagina } = props






  function handleChangeCheck(e: ChangeEvent<HTMLInputElement>) {
    setAlteraDados({...DadosVenda, [e.target.name]: parseInt(e.target.value)})
  }

  function handleChangeCheck1(e: ChangeEvent<HTMLInputElement>) {
    if(e.target.checked){
      console.log(e.target.name)
      setAlteraDados({...DadosVenda, [e.target.name]: "X"})
    }else{
      setAlteraDados({...DadosVenda, [e.target.name]: ""})
    }
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let nomeForm = e.target.name+"F";
    console.log(nomeForm)
    setAlteraDados({ 
      ...DadosVenda, 
      [e.target.name]: e.target.value.toUpperCase(),  
      [nomeForm]: (parseFloat(e.target.value)/100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    })
};

async function passaFase6() {
  if(DadosVenda.codB === 0){
    addToast({
      type: 'error',
      title: 'Erro bem selecionado',
      description: 'Ao retornar a primeira pagina, por favor selecione o bem novamente!'
    });
    setAlteraPagina(0)
  }
  const tudo = JSON.stringify(DadosVenda)
  const teste = JSON.parse(tudo)
  if (DadosVenda?.id > 0) {
    const response = await api.put(`v1/pre-vendas/${DadosVenda.id}`, {
      idVendedor: DadosVenda.idVendedor,
      idTabela: DadosVenda.idTabela,
      documento: DadosVenda.documento,
      dataCriacao: DadosVenda.dataAdesao,
      etapa: 6,
      conteudo: tudo,
      dataExportacao: DadosVenda.dataAdesao,
      status: "PENDENTE",
      posVenda: "PENDENTE",
        
    })

    console.log(response.status)

      setAlteraDados({
        ...DadosVenda,
        id: response.data[0].id,
        etapa7: false,

      })
    

    setAlteraPagina(6)
  } else {
    const response = await api.post(`v1/pre-vendas`, {
      idVendedor: DadosVenda.idVendedor,
      idTabela: DadosVenda.idTabela,
      documento: DadosVenda.documento,
      dataCriacao: DadosVenda.dataAdesao,
      etapa: 6,
      conteudo: tudo,
      dataExportacao: DadosVenda.dataAdesao,
      status: "PENDENTE",
        posVenda: "PENDENTE",
        
    })

    if (response.status === 201) {
      setAlteraDados({
        ...DadosVenda,
        id: response.data[0].id,
        etapa7: false,

      })

      setAlteraPagina(6)
    }
  }


}

  return (
    <>
      <Container>
        <Row style={{ marginTop: 10 }}>
          <Col style={{backgroundColor: "rgb(240,240,240)"}}>
            <div style={{color: "rgb(110,110,110", marginTop: 10}}>QUAL O TIPO DE EMPRESA?</div>
            <RadioGroup style={{ marginBottom: 10}}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p10"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p10}
            >
                  <FormControlLabel value="1" style={{width:"300px", height:"30px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="LTDA" />
                  <FormControlLabel value="2" style={{width:"300px", height:"30px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="MEI" />
                <FormControlLabel value="3" style={{width:"300px", height:"30px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Sociedade Anônima" />
                <FormControlLabel value="4" style={{width:"300px", height:"30px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Individual" />
              </RadioGroup>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col style={{backgroundColor: "rgb(240,240,240)"}}>
            <div style={{color: "rgb(110,110,110", marginTop: 10}}>QUAL O RAMO DE ATIVIDADE?</div>
            <RadioGroup style={{ marginBottom: 10}}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p11"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p11}
            >
                  <FormControlLabel value="1" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Agricultura, pecuária, pesca, produção florestal" />
                  <FormControlLabel value="2" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Comércio" />
                  <FormControlLabel value="3" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Prestação de Serviço" />
                  <FormControlLabel value="4" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Atividades financeiras, seguros e serviços relacionados" />
                  <FormControlLabel value="5" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Indústria" />

              </RadioGroup>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col style={{backgroundColor: "rgb(240,240,240)"}}>
            <div style={{color: "rgb(110,110,110", marginTop: 10}}>A SEDE DA EMPRESA É:</div>
            <RadioGroup style={{ marginBottom: 10}}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p12"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p12}
            >
                  <FormControlLabel value="1" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Própria" />
                  <FormControlLabel value="2" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Financiada" />
                <FormControlLabel value="3" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="A empresa não possui sede" />
                <FormControlLabel value="4" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Alugada" />
                <FormControlLabel value="5" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Arrendada" />
              </RadioGroup>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
        <Col style={{backgroundColor: "rgb(240,240,240)"}}>
            <FormControl variant="standard" style={{width:"100%"}} >
        
              <TextField
              style={{width:"100%", marginTop: 10, marginBottom: 10}}
                InputLabelProps={{ shrink: DadosVenda.capitalSocialFormulario >0 ? true : false }}
                label="QUAL O CAPITAL SOCIAL DA EMPRESA"
                required
                value={DadosVenda.capitalSocialFormulario > 0? DadosVenda.capitalSocialFormulario:""}
                onChange={handleChangeInput}
                variant="standard"
                name="capitalSocialFormulario"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />

            </FormControl>

          </Col>
          </Row>


          <Row style={{ marginTop: 10 }}>
        <Col style={{backgroundColor: "rgb(240,240,240)"}}>
            <FormControl variant="standard" style={{width:"100%"}} >
        
              <TextField
              style={{width:"100%", marginTop: 10, marginBottom: 10}}
                InputLabelProps={{ shrink: DadosVenda.faturamento >0 ? true : false }}
                label="QUAL O FATURAMENTO BRUTO MENSAL?"
                required
                value={DadosVenda.faturamento > 0? DadosVenda.faturamento:""}
                onChange={handleChangeInput}
                variant="standard"
                name="faturamento"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />

            </FormControl>

          </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
        <Col style={{backgroundColor: "rgb(240,240,240)"}}>
            <FormControl variant="standard" style={{width:"100%"}} >
        
              <TextField
              style={{width:"100%", marginTop: 10, marginBottom: 10}}
                InputLabelProps={{ shrink: DadosVenda.lucroMensal >0 ? true : false }}
                label="QUAL O LUCRO LIQUIDO MENSAL APROXIMADO?"
                required
                value={DadosVenda.lucroMensal > 0? DadosVenda.lucroMensal:""}
                onChange={handleChangeInput}
                variant="standard"
                name="lucroMensal"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />

            </FormControl>

          </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
          <Col style={{backgroundColor: "rgb(240,240,240)"}}>
            <div style={{color: "rgb(110,110,110", marginTop: 10 , marginBottom: 10}}>QUANTOS FUNCIONÁRIOS A EMPRESA TEM?</div>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p7"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p7}
            >
              <Row>
                <Col>
                <FormControlLabel value="1" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="1 a 3" />
                <FormControlLabel value="4" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="4 a 6" />
                
                </Col>
                <Col>
                <FormControlLabel value="2" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="7 a 10" />
                <FormControlLabel value="5" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="11 a 15" />
                </Col>
                <Col>
                <FormControlLabel value="3" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="15 a 20" />
                <FormControlLabel value="6" style={{width:"300px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Acima de 20 funcionários" />
                </Col>


              </Row>
                
                
                
                
                
                
              </RadioGroup>
          </Col>
        </Row>


        <Row style={{ marginTop: 10 }}>
          <Col style={{backgroundColor: "rgb(240,240,240)"}}>
            <div style={{color: "rgb(110,110,110", marginTop: 10}}>A EMPRESA POSSUI BENS?</div>
            <RadioGroup style={{marginBottom: 10}}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p8"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p8}
            >
                  <FormControlLabel value="1" style={{width:"300px", height:"30px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="SIM" />
                  <FormControlLabel value="2" style={{width:"300px", height:"30px"}} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="NÃO" />
              </RadioGroup>
          </Col>
        </Row>
        {DadosVenda.t_p8 === 1?<>
        <Row style={{ marginTop: 10 }}>
          <Col style={{backgroundColor: "rgb(240,240,240)"}}>
            <div style={{color: "rgb(110,110,110", marginTop: 10 , marginBottom: 10}}>QUAIS BENS?</div>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"

            >
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b1" checked={DadosVenda.b1 === "X" ? true : false} onChange={handleChangeCheck1} sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Casa" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b4" checked={DadosVenda.b4 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Carro" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b7" checked={DadosVenda.b7 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Fazenda" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b2" checked={DadosVenda.b2 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Apartamento" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b5" checked={DadosVenda.b5 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Moto" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b8" checked={DadosVenda.b8 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Outros" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b3" checked={DadosVenda.b3 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Terreno" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b6" checked={DadosVenda.b6 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Chacára" />
              </RadioGroup>
          </Col>
          </Row>
        </>:<></>}
          {DadosVenda.b8 ==="X" ?<>
          <Row>
          <Col style={{backgroundColor: "rgb(240,240,240)"}}>
          <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard"  >
                <TextField style={{color: "rgb(110,110,110", marginTop: 10 , marginBottom: 10}}
                  required
                  className='inputNovo'
                  id="standard-required"
                  label="Quais?"
                  variant="standard"
                  name='b9'
                  value={DadosVenda.b9.toUpperCase()}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          </Row>
          </>:<></>}
       


        <Row style={{ marginTop: 39 }}>
          <Col xs={3}>


          </Col>
          <Col xs={6}></Col>
          <Col xs={3}>
            <Form.Group className="mb-3 teste right" as={Col} controlId="formGridEmail">
              <div className="botaoPesquisar">
                <Button variant="primary" type="submit" className="btnPesquisar" onClick={passaFase6} 
                disabled={(DadosVenda.t_p10 === 0 ||
                  DadosVenda.t_p11 === 0 ||
                  DadosVenda.t_p12 === 0 ||
                  DadosVenda.t_p7 === 0 ||
                  DadosVenda.t_p8 === 0 ||
                  DadosVenda.capitalSocialFormulario === 0 ||
                  DadosVenda.faturamento === 0 ||
                  DadosVenda.lucroMensal === 0
                  )
                  ? true : false
                  }
                
                >
                  <Typography variant="button" gutterBottom> PRÓXIMO</Typography>
                </Button>
              </div>
            </Form.Group>

          </Col>

        </Row>




      </Container>
    </>
  )
}