import React, { ChangeEvent, useEffect, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { Container, CardContainer, Cards } from './styles';
import { Button, Form, Row, Col } from 'react-bootstrap';
import api from '../../../services/api';
import { Tabela } from '../../../interfaces/tabela';
import { TiposBens } from '../../../interfaces/tiposBem';
import { SequenciaCard } from '../../../interfaces/sequenciaCard';
import { SequenciaAgrupamentoTabelaVendas } from '../../../interfaces/sequenciaAgrupamentoTabela';
import { PlanoAgrupamento } from '../../../interfaces/planoAgrupamentos';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import { Tabelas } from '../../../hooks/tabelas';
import { Planoh } from '../../../hooks/plano';
import { Planos } from '../../../interfaces/planos';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { FiArrowLeft } from 'react-icons/fi';


const Etapa2: React.FC = () => {

	const history = useHistory();
	const { tabela } = useParams() as any
	const [tipoBem, setTipoBem] = useState<TiposBens[]>([]);
	const { getAllTiposBem, tiposBem, getAllSequenciaCards, sequenciaCards, getAllPlanos, planosAgrupamento } = Tabelas();
	const [listaTipoBem, setListaTipoBem] = useState<TiposBens[]>(tiposBem);
	const [listaCards, setListaCards] = useState<SequenciaCard[]>(sequenciaCards)
	const [listaPlanos, setListaPlanos] = useState<PlanoAgrupamento[]>(planosAgrupamento)
	const [grupo, setGrupo] = useState<number>();
	const { ativosLista, getAllAtivos } = Planoh();
	const [ativos, setAtivos] = useState<Planos[]>(ativosLista)

	useEffect(() => {
		getAllTiposBem();
		getAllAtivos();
		getAllSequenciaCards();
		getAllPlanos();
	}, [getAllTiposBem, getAllAtivos, getAllSequenciaCards])


	useEffect(() => {
		setListaTipoBem(tiposBem)
		setAtivos(ativosLista)
		setListaCards(sequenciaCards)
		setListaPlanos(planosAgrupamento)
	}, [tiposBem, ativosLista, sequenciaCards, planosAgrupamento])

	const [model, setModel] = useState<Omit<SequenciaAgrupamentoTabelaVendas, "id">>({
	   adesao: 0,
	   reducao: 0,
	   codigoGrupoBem: 0,
	   codigoTipoVenda: 0,
	   sequenciaAgrupamento: 0,
	})


	function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
		setModel({
			...model,
			[e.target.name]: e.target.value
		});
	}


	async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
		const ativo = ativosLista.find(item => item.id === model.sequenciaAgrupamento)
		e.preventDefault()
		if (ativo !== undefined) {
			const response = await api.post(`/v1/sequencias-agrupamento-tabela-venda/`, 
			{ 
				sequenciaAgrupamento: ativo.sequenciaAgrupamento,
		        codigoGrupoBem: ativo.codigoGrupoBem,
		        codigoTipoVenda: ativo.codigoTipoVenda,
				adesao: model.adesao,
				reducao: model.reducao,
				tabelaVenda: {id: tabela}
			})
		} 

		setModel({
			adesao: 0,
	        reducao: 0,
	   codigoGrupoBem: 0,
	   codigoTipoVenda: 0,
	   sequenciaAgrupamento: 0,
	})
			
	getAllSequenciaCards();
		//back();
	}

	async function findTabela(id: string) {
		const response = await api.get(`/v1/tabelas-venda/${id}`);
		setModel({
          ...model
		})

		getAllSequenciaCards();

	}

	async function deletaCard(id: number) {
		const response = await api.delete(`/v1/sequencias-agrupamento-tabela-venda/${id}`);
        getAllSequenciaCards();
	}


	function back() {
		history.goBack()
	}

	function handleChangeAdesao(e: ChangeEvent<HTMLSelectElement>) {
		setModel({
			...model,
			adesao: parseInt(e.target.value)
		})
	}

	function handleChangeReducao(e: ChangeEvent<HTMLSelectElement>) {
		setModel({
			...model,
			reducao: parseInt(e.target.value)
		})
	}

	async function handleGrupo(e: ChangeEvent<HTMLSelectElement>) {

		setModel({
			...model,
	        sequenciaAgrupamento: parseInt(e.target.value)
		});
	}

	function verificar() {
		if (!grupo) {
			return (
				<option></option>
			)
		}
	}

	function listaSequencia(sequencia: number){
		const lista = listaPlanos.filter(item => sequencia.toString() ? item.sequenciaAgrupamento.toString() === (sequencia.toString()) : item);
		return lista
	}

	function adicionaZero(numero: number) {
		if (numero <= 9)
		  return "00" + numero;
		else if (numero <= 99)
		  return "0" + numero;
		else
		  return numero
	  }

	  function voltar(){
		history.goBack()
	  }


	return (
		<>
			<Header />
			<Container>
				<CardContainer>
					<div className='title'><FiArrowLeft onClick={voltar} className='voltar'/><h2>Regra de Cobrança</h2><h5></h5></div>
					<Form className="form-border" onSubmit={onSubmit}>
						<Row>
							<Col>
								<Form.Group className="inputNovo">
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Adesão
									</InputLabel>
									<NativeSelect
										className="inputNovo1"
										onChange={handleChangeAdesao}
										value={model.adesao}
										inputProps={{
											name: 'adesao',
											id: 'uncontrolled-native',
										}}
									>
										<option id="0" value="0">0%</option>
										<option id="1" value="1">1%</option>
										<option id="2" value="2">2%</option>
										<option id="3" value="3">3%</option>

									</NativeSelect>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="inputNovo">
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Redução
									</InputLabel>
									<NativeSelect
										className="inputNovo1"
										onChange={handleChangeReducao}
										value={model.reducao}
										inputProps={{
											name: 'reducao',
											id: 'uncontrolled-native',
										}}
									>
										<option id="0" value="0">0%</option>
										<option id="10" value="1">10%</option>
										<option id="20" value="2">20%</option>
										<option id="30" value="3">30%</option>
									</NativeSelect>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="inputNovo">
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Plano de Venda
									</InputLabel>
									<NativeSelect
										className="inputNovo1"
										value={model.sequenciaAgrupamento}
										onChange={handleGrupo}
										defaultValue=""
										inputProps={{
											name: 'cargo',
											id: 'uncontrolled-native',
										}}
									>
										{verificar()
										}
										{ativosLista.map(item => {
											console.log('Ativos Listassss')
											console.log(ativosLista)
											//if (item. === 'A' teste) {
											return (
												<option
													id={item.codigoTipoVenda.toString()}
													value={item.id}>

													{item.codigoTipoVenda}
												</option>
											)
											//}
										})

										}



									</NativeSelect>
								</Form.Group>
							</Col>
						</Row>



						<Form.Group className="buttonsCargo">
							<Button type="submit">Adicionar</Button>
						</Form.Group>
					</Form>
                    <Cards>

					{listaCards.map(function (item) {
                    const x:PlanoAgrupamento[] = listaSequencia(item.sequenciaAgrupamento)
					console.log(x)
					if(item.id !== undefined){
                  return (
                    <div style={{marginBottom: 10}}>	
					<Card sx={{ maxWidth: 345, width: 248, marginRight: 2 }}>
						<CardContent>
							<Typography gutterBottom variant="h5" component="div" className='cabecalhoCard'>
							<div className='spam'>{item.sequencia.prazo} Meses</div>
							</Typography>
							<Typography variant="body2" color="text.secondary">
								<div className='informacoes'>
								Adesão
								<div className='valor'>{item.adesao}%</div>
								Redução
								<div className='valor'>{item.reducao}%</div>
								Plano de Venda
								<div className='valor'>{item.codigoTipoVenda}</div>
								<div className='barra'></div>
								{x.map((item) => 
								   <div className='percentual'>{adicionaZero(item.parcelaInicial)} à {adicionaZero(item.parcelaFinal)} = TX {item.percentualTaxaAdministracao}% e FC {item.percentualNormal}%</div>
								)}
                                </div>
							</Typography>
						</CardContent>
						<CardActions>
						<Form.Group className="buttonsCargo1">
							<Button type="submit" onClick={() => deletaCard(item.id)}>Remover</Button>
						</Form.Group>
						</CardActions>
					</Card>
					</div>
                  )}
                
              })}
					</Cards>
					
				</CardContainer>
			</Container>
		</>
	)
}

export default Etapa2