import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route'

import SignIn from '../pages/SignIn';
import ChangeRecoveryPassword from '../pages/ChangePasswordRecovery';
import RecoveryPassword from '../pages/ChangePasswordRecovery';
import Dashboard from '../pages/Dashboard';
import Cargo from '../pages/Cargo';
import FormCargo from '../pages/Cargo/Form';
import Nivel from '../pages/Nivel';
import FormNivel from '../pages/Nivel/Form';
import GrupoUsuarios from '../pages/GrupoUsuarios';
import FormGrupoUsuarios from '../pages/GrupoUsuarios/Form';
import Users from '../pages/Usuarios';
import Vendas from '../pages/Vendas';
import FormUsers from '../pages/Usuarios/Form';
import Contrato from '../pages/Contrato/index';
import AlteraSenha from '../pages/AlteraSenha/index';
import { Principal } from '../components/Principal';

import Grupos from '../pages/Grupos';
import FormGrupos from '../pages/Grupos/Form';

import UsuariosSistema from '../pages/UsuariosSistema';
import UsuariosSistemaForm from '../pages/UsuariosSistema/Form';

import Plano from '../pages/PlanoVendas';
import AutorizaCpfCnpj from '../pages/AutorizaCpfCnpj';
import Boleto from '../pages/Boleto';

import TabelasVendas from '../pages/Tabelas';
import Etapa4 from '../pages/Tabelas/Etapa4';
import Etapa1 from '../pages/Tabelas/Etapa1';
import Etapa2 from '../pages/Tabelas/Etapa2';
import Etapa3 from '../pages/Tabelas/Etapa3';

import Bivendas from '../pages/BI_Vendas';
import BiContemplacao from '../pages/BI_Contemplacao';
import BiInadimplencia from '../pages/BI_Inadimplencia';

import BiInadimplencia2366 from '../pages/BI_Inadimplencia2366';
import BiVendas2366 from '../pages/BI_Vendas2366';

import changePassword from '../pages/ChangePassword';

import { Teste12 } from '../components/Teste12';

import Simulacao from '../pages/Simulacao';

import ConsultaContrato from '../pages/ConsultaContrato';
import ChangePassword from '../pages/ChangePassword';

const Routes: React.FC = () => (
    <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/ChangeRecoveryPassword" component={ChangeRecoveryPassword} />
        <Route path="/RecoveryPassword" component={RecoveryPassword} />
        <Route path="/principal" component={Principal} isPrivate/>

        <Route path="/cargo" component={Cargo} isPrivate/>
        <Route path="/cargoCadastro/:id" component={FormCargo} isPrivate/>
        <Route path="/cargoCadastro" component={FormCargo} isPrivate/>
        
        <Route path="/nivel" component={Nivel} isPrivate/>
        <Route path="/nivelCadastro/:id" component={FormNivel} isPrivate/>
        <Route path="/nivelCadastro" component={FormNivel} isPrivate/>
        
        <Route path="/grupos" component={Grupos} isPrivate/>
        <Route path="/gruposCadastro/:grupo" component={FormGrupos} isPrivate/>
        <Route path="/gruposCadastro" component={FormGrupos} isPrivate/>
        
        
        <Route path="/grupoUsuarios" component={GrupoUsuarios} isPrivate/>
        <Route path="/grupoUsuariosCadastro/:id" component={FormGrupoUsuarios} isPrivate/>
        <Route path="/grupoUsuariosCadastro" component={FormGrupoUsuarios} isPrivate/>

        <Route path="/usuariosSistema" component={UsuariosSistema} isPrivate/>
        <Route path="/usuariosSistemaForm/:id" component={UsuariosSistemaForm} isPrivate/>
        <Route path="/usuariosSistemaForm" component={UsuariosSistemaForm} isPrivate/>

        <Route path="/alteraSenha" component={ChangePassword} />

        <Route path="/usuarios" component={Users} isPrivate/>
        <Route path="/usuariosForm/:id" component={FormUsers} isPrivate/>
        <Route path="/usuariosForm" component={FormUsers} isPrivate/>

        <Route path="/plano" component={Plano} isPrivate/>

        <Route path="/autoriza" component={AutorizaCpfCnpj} isPrivate/>

        <Route path="/boleto" component={Boleto} isPrivate/>

        <Route path="/parametrosContrato" component={Contrato} isPrivate/>
        
        <Route path="/tabelas" component={TabelasVendas} isPrivate/>
        <Route path="/tabelasEtapa1/:id" component={Etapa1} isPrivate/>
        <Route path="/tabelasEtapa1" component={Etapa1} isPrivate/>
        <Route path="/tabelasEtapa2/:tabela" component={Etapa2} isPrivate/>
        <Route path="/tabelasEtapa3/:tabela" component={Etapa3} isPrivate/>
        <Route path="/tabelasEtapa4/:tabela" component={Etapa4} isPrivate/>

        <Route path="/simulacao" component={Simulacao} isPrivate/>
        <Route path="/detalhesSimulacao/:id" component={FormCargo} isPrivate/>

        <Route path="/consultaContrato" component={ConsultaContrato} isPrivate/>
        
        
        <Route path="/dashboard" component={Principal} isPrivate/>
        <Route path="/Vendas/:idConsulta" component={Vendas} isPrivate/>
        <Route path="/Vendas" component={Vendas} isPrivate/>

        <Route path="/BiVendas" component={Bivendas} isPrivate/>
        <Route path="/BiContemplacao" component={BiContemplacao} isPrivate/>
        <Route path="/BiInadimplencia" component={BiInadimplencia} isPrivate/>

        <Route path="/BiVendas2366" component={BiVendas2366} isPrivate/>
        <Route path="/BiInadimplencia2366" component={BiInadimplencia2366} isPrivate/>
        

        <Route path='/Teste12' component={Teste12} isPrivate />   
        
    </Switch>
);

export default Routes;