import styled from 'styled-components';

export const Container = styled.div`
    //display: flex;
    //margin: 20px;
    //max-width: 1190px;
    //flex-direction: column;
    //position:relative;
    //align-items:center;

    .formulario{
        display: flex;
    }
    
    .tipoPessoa{
        margin-left: 4px;
        margin-bottom: 12px;
    }

    .tipoPessoaRadio{
        margin-top: -10px;
        margin-left: -12px;
    }

    .meta{
      margin-top: 16px;
    }

    .codFunc{
        margin-top: 17px;
    }

    .labelTelefone{
        margin-left: 7px;
        margin-top: 10px;
    }

    .botao{
        margin-top: 17px;
    }


    .datePick{
        width: 100%;
        margin-top: 15px;
        margin-left: 7px;

        .MuiTextField-root{
            width: 100%;
            
        }

        .MuiFormLabel-filled{
            font-size: 16px;
            position: absolute;
            top: -5px;
            left: -12px;
        }

        .MuiInputLabel-outlined{
            font-size: 17px;
            position: absolute;
            top: 8px;
            left: -14px;
            
        }
        .MuiOutlinedInput-input{
            height: 24px;
            margin-top: 30px;
            padding: 0;
            padding-bottom: 3px;
        } 
        

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
          //height: 24px;
          //margin-top: 30px;
          //padding: 0;
          //padding-bottom: 3px;
        }

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
          border-width: 0;
          border-color: #1976d2;
          border-bottom-width: 2px;

        }

        fieldset{
            border-radius: 0;
            border-width: 0;
            border-bottom-width: 1px;
            border-color: rgba(0, 0, 0, 0.43);

            legend{
                
                span{
                    font-size: 16px;
                }
            }
        }
    }

    .inputNovo{
        width: 100%;
    }

    .inputCargo{
        min-width: 120px;
    }

    .buttonsCargo{
        display: flex;
        margin-top: 35px;
        justify-content:flex-end;
        
        Button{
            margin-left: 10px;
            background-color: var(--blue);
            min-width: 100px;
            border: none;
        }
        
       
    }

    .linhaTamanho .checkCargo{
        //width: 10px;
        height: 25px;
    }

    .statusLabel{
        margin-top: 20px;
        
    }

    .statusOpcao{
        margin-top: -10px;
        margin-bottom: 20px;;
    }

    .tituloCard {
        margin-top: 5px;
        .MuiTypography-root{
          font-size: 15px;
        }   
    }

    .checkCargo{
       // margin-top: 20px;
    }

    .form-border{
        width: 840px;
        border-width: 1px;
        border-color: #cccccc;
        border-style: solid;
        padding: 20px;
        border-radius: 2px;
    }

    .btn, .btn-primary{
        background: var(--blue);
        border-color: var(--blue);
    }

    .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: var(--blue);

    .linhaTelefone{
        margin-left: 30px;
        width: 0px;
    }
}

.form-check-input:checked {
    background-color: var(--blue);
    border-color: var(--blue);
}
`;

export const CardContainer = styled.div`
    display:flex;
    flex-direction: column;
    flex:1;
    align-items:center;
    justify-content:space-between;
    //flex-direction: column;
   
    //top:-10px;
    //position: absolute;
    //background-color:#ffffff;
    //z-index: 50;
    //margin-top: 50px;

    .title{
      margin-top: 50px;
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 840px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
   }
`;

