import React, { ChangeEvent, useState, useEffect } from "react";
import { Form, Button, Row, Table } from 'react-bootstrap';
import { Container } from './styles';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import api from '../../../services/api';
import FormControl from '@mui/material/FormControl';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../hooks/toast';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Estados } from '../../../interfaces/estados';
import { Cidades } from '../../../interfaces/cidades';
import { nacionalidades } from '../../../mock/nacionalidades';
import { profissoes } from '../../../mock/profissoes';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Col } from "../styles";


export function Etapa2DadosCadastraisPF(props: any) {

  const { DadosVenda, setAlteraDados, setAlteraPagina } = props
  const { addToast } = useToast();
  const [estados, setEstados] = useState<Estados[]>([]);
  const [cidades, setCidades] = useState<Cidades[]>([]);

  useEffect(() => {
    buscaEstados();

  }, [])

  useEffect(() => {
    if (DadosVenda.naturalUF) {
      console.log(DadosVenda.naturalUF)
      const idEstado = estados?.find(item => item.nome.toUpperCase() === DadosVenda.naturalUF)
      console.log(idEstado)
      if (idEstado !== undefined)
        buscaCidades(idEstado.id)
    }
  }, [estados])

  const buscaEstados = async () => {
    const { data } = await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
    setEstados(data)

  }







  const buscaCidades = async (idEstado: number) => {
    const { data } = await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${idEstado}/municipios?orderBy=nome`)
    setCidades(data)
  }

  const [age, setAge] = React.useState<Date | null>(null);
  const [dataExped, setDataExped] = React.useState<Date | null>(null);


  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != undefined) {
      setAge(newValue)
      console.log(age)
      const agora = Date.now()
      const hoje = new Date(agora)
      const idade = Math.floor(Math.ceil(Math.abs(newValue.getTime() - hoje.getTime()) / (1000 * 3600 * 24)) / 365.25);
      const idadeF = idade + " ANOS";
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
      let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      setAlteraDados({ ...DadosVenda, age1: newValue, dataNascimento: DataFormatada, dataNascimentoF: DataFormatada1, idade: idadeF })
    }
  };

  const handleChangeDateExpedicao = (newValue: Date | null) => {
    if (newValue != undefined) {
      setDataExped(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
      let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      setAlteraDados({ ...DadosVenda, dataExpedicao: DataFormatada, dtEF: DataFormatada1, age5: newValue })
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value.toUpperCase() })
  };

  function handleChangeSelect(e: ChangeEvent<HTMLSelectElement>) {
    console.log(e.target.name, e.target.value)

    if (e.target.name === "profissao") {
      const result = e.target.value.substring(0, 33)
      setAlteraDados({ ...DadosVenda, [e.target.name]: result })


    } else {
      setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value })
    }

    if (e.target.name === "naturalUF") {
      const idEstado = estados?.find(item => item.nome.toUpperCase() === e.target.value)
      if (idEstado !== undefined)
        buscaCidades(idEstado.id)

    }
  }

  function handleChangeSelectNovo(e: SelectChangeEvent) {
    console.log(e.target.name, e.target.value)
    setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value })

    if (e.target.name === "naturalUF") {
      const idEstado = estados?.find(item => item.nome.toUpperCase() === e.target.value)
      if (idEstado !== undefined)
        buscaCidades(idEstado.id)

    }
  }

  /*function formatData(x: string) {
    let dataAss = new Date(x)
    console.log(dataAss)
    console.log('chegou aqui Data')
    let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }*/

  async function passaFase2() {
    if (DadosVenda.codB === 0) {
      addToast({
        type: 'error',
        title: 'Erro bem selecionado',
        description: 'Ao retornar a primeira pagina, por favor selecione o bem novamente!'
      });
      setAlteraPagina(0)
    }
    const tudo = JSON.stringify(DadosVenda)
    const teste = JSON.parse(tudo)
    if (DadosVenda?.id > 0) {
      const response = await api.put(`v1/pre-vendas/${DadosVenda.id}`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 2,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        nomeCliente: DadosVenda.nomeCliente,
        status: "PENDENTE",
        posVenda: "PENDENTE",

      })

      console.log(response.status)

      if (response.status === 200) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa3: false,

        })
      }

      setAlteraPagina(2)
    } else {
      const response = await api.post(`v1/pre-vendas`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 2,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        nomeCliente: DadosVenda.nomeCliente,
        status: "PENDENTE",
        posVenda: "PENDENTE",

      })

      if (response.status === 201) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa3: false,

        })

        setAlteraPagina(2)
      }
    }


  }



  const tiposSexo = [
    { label: 'MASCULINO' },
    { label: 'FEMININO' }
  ]

  return (
    <>
      <Container>
        <Row className="row-inputs">
          <Col min={'140px'} max={'100%'}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 9 }} >
                <TextField
                  InputLabelProps={{ shrink: DadosVenda.sequenciaAgrupamento > 0 ? true : false }}
                  disabled
                  className='inputNovo'
                  id="standard-required"
                  label="CPF *"
                  variant="standard"
                  name='nomePrincipal'
                  value={DadosVenda.documentoFormatado}
                />
              </FormControl>
            </div>
          </Col>
          <Col min='320px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 9 }} >
                <TextField
                  required
                  inputProps={{ maxlength: 50 }}
                  className='inputNovo'
                  id="standard-required"
                  label="Nome Completo"
                  variant="standard"
                  name='nomeCliente'
                  value={DadosVenda.nomeCliente.toUpperCase()}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col min='140px' max='220px'>
            <div className='datePick'>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DesktopDatePicker
                  label="Data de Nascimento *"
                  inputFormat="dd/MM/yyyy"
                  value={DadosVenda.age1}
                  onChange={handleChangeDate}
                  className="teste"

                  renderInput={(params) => <TextField {...params}
                    sx={{ maxWidth: 220, alignItems: "right" }}
                    InputLabelProps={{ shrink: DadosVenda.dataNascimento !== "" ? true : false }}
                  />
                  }
                />
              </LocalizationProvider>
            </div>
          </Col>
          <Col min='140px' max='220px'>
            <FormControl fullWidth style={{ marginTop: 8 }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.sexo === "" ? false : true} >
                Sexo *
              </InputLabel>
              <NativeSelect
                value={DadosVenda.sexo.toUpperCase()}
                onChange={handleChangeSelect}
                inputProps={{
                  name: 'sexo',
                  id: 'uncontrolled-native',

                }}
              >
                <option value=""></option>
                <option value={"FEMININO"}>FEMININO</option>
                <option value={"MASCULINO"}>MASCULINO</option>
              </NativeSelect>
            </FormControl>
          </Col>
        </Row>

        <Row className="row-inputs">
          <Col min='140px' max='220px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  className='inputNovo'
                  inputProps={{ maxlength: 15 }}
                  id="standard-required"
                  label="RG *"
                  variant="standard"
                  name='documento2'
                  value={DadosVenda.documento2.toUpperCase()}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col min='100px' max='220px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  required
                  className='inputNovo'
                  inputProps={{ maxlength: 6 }}
                  id="standard-required"
                  label="Orgão Emissor"
                  variant="standard"
                  name='orgEm'
                  value={DadosVenda.orgEm.toUpperCase()}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col min='180px' max='345px'>
            <FormControl fullWidth style={{ marginTop: 8 }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.UFEmissao === "" ? false : true} >
                UF Emissão *
              </InputLabel>
              <NativeSelect
                value={DadosVenda.ufe.toUpperCase()}
                onChange={handleChangeSelect}
                inputProps={{
                  name: 'ufe',
                  id: 'uncontrolled-native',

                }}
              >
                <option value=""></option>
                {estados?.map(item =>
                  <option value={item.sigla.toUpperCase()}>{item.nome.toUpperCase()}</option>
                )}
              </NativeSelect>
            </FormControl>
          </Col>
          <Col min='140px' max='280px'>
            <div className='datePick'>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DesktopDatePicker
                  label="Data de Expedição *"
                  inputFormat="dd/MM/yyyy"
                  value={DadosVenda.age5}
                  onChange={handleChangeDateExpedicao}
                  renderInput={(params) => <TextField {...params}
                  />
                  }
                />
              </LocalizationProvider>
            </div>
          </Col>
        </Row>

        <Row className="row-inputs">
          <Col min='280px' max='440px'>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.nacionalidade === "" ? false : true} >
                Nacionalidade *
              </InputLabel>
              <NativeSelect
                value={DadosVenda.nacionalidade.toUpperCase()}
                onChange={handleChangeSelect}
                inputProps={{
                  name: 'nacionalidade',
                  id: 'uncontrolled-native',

                }}
              >
                <option value=""></option>
                <option value="BRASILEIRA(O)">BRASILEIRA(O)</option>
                {nacionalidades?.map(item =>
                  <option value={item.gentilico.toUpperCase() + "(O)"}>{item.nome_pais.toUpperCase()} - {item.gentilico.toUpperCase()}(O)</option>
                )}
              </NativeSelect>
            </FormControl>
          </Col>
          <Col min='280px' max='340px'>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.naturalUF === "" ? false : true} >
                Natural (UF) *
              </InputLabel>
              <NativeSelect
                value={DadosVenda.naturalUF.toUpperCase()}
                onChange={handleChangeSelect}
                inputProps={{
                  name: 'naturalUF',
                  id: 'uncontrolled-native',

                }}
              >
                <option value=""></option>
                {estados?.map(item =>
                  <option value={item.nome.toUpperCase()}>{item.nome.toUpperCase()}</option>
                )}
              </NativeSelect>
            </FormControl>
          </Col>
          <Col min='280px' max='288px'>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.naturalCidade === "" ? false : true} >
                Natural (Cidade) *
              </InputLabel>
              <NativeSelect
                value={DadosVenda.naturalCidade.toUpperCase()}
                onChange={handleChangeSelect}
                inputProps={{
                  name: 'naturalCidade',
                  id: 'uncontrolled-native',

                }}
              >
                <option value=""></option>
                {cidades?.map(item =>
                  <option value={item.nome.toUpperCase()}>{item.nome.toUpperCase()}</option>
                )}
              </NativeSelect>
            </FormControl>
          </Col>
        </Row>

        <Row between>
          <Col min='180px'>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.estadoCivil === "" ? false : true} >
                Estado Civil *
              </InputLabel>
              <NativeSelect
                value={DadosVenda.estadoCivil.toUpperCase()}
                onChange={handleChangeSelect}
                inputProps={{
                  name: 'estadoCivil',
                  id: 'uncontrolled-native',

                }}
              >
                <option value=""></option>
                <option value={"CASADO"}>CASADO</option>
                <option value={"DIVORCIADO"}>DIVORCIADO</option>
                <option value={"OUTRO"}>OUTRO</option>
                <option value={"SEPARADO"}>SEPARADO</option>
                <option value={"SOLTEIRO"}>SOLTEIRO</option>
                <option value={"UNIAO ESTAVEL"}>UNIÃO ESTÁVEL</option>
                <option value={"VÍUVO"}>VÍUVO</option>
              </NativeSelect>
            </FormControl>
          </Col>
          <Col min='220px'>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.profissao === "" ? false : true} >
                Profissão *
              </InputLabel>
              <NativeSelect
                value={DadosVenda.profissao.toUpperCase()}
                onChange={handleChangeSelect}
                inputProps={{
                  name: 'profissao',
                  id: 'uncontrolled-native',
                }}
              >
                <option value={""}></option>
                {profissoes?.map(item =>
                  <option value={item.substring(0, 33).toUpperCase()}>{item.toUpperCase()}</option>
                )}
              </NativeSelect>
            </FormControl>
          </Col>
        </Row>

        <Row className="row-inputs">
          <Col min='280px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  required
                  inputProps={{ maxlength: 32 }}
                  className='inputNovo'
                  id="standard-required"
                  label="Nome mãe"
                  variant="standard"
                  name='nomeMae'
                  value={DadosVenda.nomeMae.toUpperCase()}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col min='280px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  inputProps={{ maxlength: 32 }}
                  className='inputNovo'
                  id="standard-required"
                  label="Nome Pai"
                  variant="standard"
                  name='nomePai'
                  value={DadosVenda.nomePai.toUpperCase()}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
        </Row>

        <Row className="row-inputs" style={{ marginTop: 35, justifyContent: 'flex-end' }}>
          <Col>
            <Form.Group className="mb-3 teste right" as={Col} controlId="formGridEmail">
              <div className="botaoPesquisar">
                <Button variant="primary" type="submit" className="btnPesquisar" onClick={passaFase2}
                  disabled={DadosVenda.nomeCliente === "" ||
                    DadosVenda.dataNascimento === "" ||
                    DadosVenda.documento2 === "" ||
                    DadosVenda.orgaoEmissor === "" ||
                    DadosVenda.dataExpedicao === "" ||
                    DadosVenda.nacionalidade === "" ||
                    DadosVenda.naturalUF === "" ||
                    DadosVenda.naturalCidade === "" ||
                    DadosVenda.estadoCivil === "" ||
                    DadosVenda.nomeMae === "" ? true : false
                  }
                >
                  <Typography variant="button" gutterBottom> PRÓXIMO</Typography>
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </>
  )
}