export const menusRota = [
	{
		id: 1,
		nome: 'Usuário',
		ordem: 1,
		menusInferiores: [
			{
				id: 5,
				nome: 'Cargo',
				ordem: 1,
        rota: '/cargo'
			},
			{
				id: 6,
				nome: 'Nível',
				ordem: 2,
        rota: '/nivel'
			},
			{
				id: 7,
				nome: 'Grupo de Usuários',
				ordem: 3,
        rota: '/grupoUsuarios'
			},
			{
				id: 8,
				nome: 'Cadastro de Usuários',
				ordem: 4,
        rota: '/usuarios'
			},
			{
				id: 9,
				nome: 'Usuários do Sistema',
				ordem: 5,
        rota: '/usuariosSistema'
			}
		]
	},
	{
		id: 2,
		nome: 'Configurações',
		ordem: 2,
		menusInferiores: [
			{
				id: 10,
				nome: 'Boletos',
				ordem: 1,
        rota: '/boleto'
			},
			{
				id: 11,
				nome: 'Autoriza CPF/CNPJ',
				ordem: 2,
        rota: '/autoriza'
			},
			{
				id: 12,
				nome: 'Parâmetros Contrato',
				ordem: 3,
        rota: '/parametrosContrato'
			},
			{
				id: 13,
				nome: 'Grupos',
				ordem: 4,
        rota: '/grupos'
			},
			{
				id: 14,
				nome: 'Plano de Vendas',
				ordem: 5,
        rota: '/plano'
			},
			{
				id: 15,
				nome: 'Tabela de Vendas',
				ordem: 6,
        rota: '/tabelas'
			}
		]
	},
	{
		id: 3,
		nome: 'Simulação',
		ordem: 3,
		menusInferiores: [
			{
				id: 16,
				nome: 'Nova Simulação',
				ordem: 1,
        rota: '/Simulacao'
			},
			{
				id: 17,
				nome: 'Arquivos',
				ordem: 2,
        rota: '/arquivos'
			},
			{
				id: 18,
				nome: 'Parâmetros Simulação',
				ordem: 3,
        rota: '/'
			}
		]
	},
	{
		id: 4,
		nome: 'Vendas',
		ordem: 4,
		menusInferiores: [
			{
				id: 19,
				nome: 'Nova Venda',
				ordem: 1,
        rota: '/vendas'
			},
			{
				id: 20,
				nome: 'Consulta Contrato',
				ordem: 2,
        rota: '/consultaContrato'
			},
			{
				id: 21,
				nome: 'Consulta Contrato Pós-Venda',
				ordem: 3,
        rota: '/'
			}
		]
	}
];