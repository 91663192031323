import styled from 'styled-components';
import { Button as BButton } from 'react-bootstrap';

export const Container = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;

   Form{
      label{
      color:red;
      font-size: 40px;
      }
   }

   .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
   }

   input{
       width: 100%;
       height: 100%;
       border: none;
   }
`;

export const Title = styled.div`
   display: flex; 
   width: 80%; 
   padding-right: 80px; 
   align-items: center; 
   max-width: 700px;
` 

export const Tabelas = styled.div`
   max-width: 700px;
   width: 80%;
   display: flex;

   .Mui-selected {
    background-color: #ffffff;
    border-bottom: solid 3px;
    border-color: var(--blue);
    font-weight: bold;
   }

   .tabela{
     vertical-align: center;
   }

   .celula{
      display: flex;
      align-items: center;
   }

   .linha{
      /* height: 60px; */
      vertical-align: center;
   }
   
   .pesquisa{
      width: 100%;
   }

   .pagination{
      display: flex;
      justify-content: center;
      border-top: red;
   }

   thead{
      //margin-bottom: 2px;
      tr, th{
         div{
            margin-bottom: 7px;
         }
         //height: 60px;
      }
   }

   .table-bordered>:not(caption)>*>* {
    //border-width: 0 0;
   }

   .table>:not(:first-child) {
      border-top: 0px solid currentColor;
   }
   

`;

export const PrimeiraLetra = styled.div`
   vertical-align: center;   
   width: 40px;
   height: 40px;
   border-radius: 20px;
   font-size: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 20px;
   color: #ffffff;
`;

export const ButtonNew = styled(BButton)`
   border: none;
   background: var(--blue);
   border-radius: 50%;
   width: 50px;
   height: 50px;
   display: flex;
   align-items: center;
`;