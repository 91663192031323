import { useCallback, useState } from "react"
import { GetUsuario } from '../services/Usuarios';
import { Usuario } from '../interfaces/usuario';
import { ConsultaUsuario } from '../interfaces/usuarioConsulta';
import { TabelaUsuariosAtivos } from "../interfaces/tabelaUsuariosAtivos";


const Usuarios = () => {
    const [usuariosLista, setUsuariosLista] = useState<ConsultaUsuario[]>([]);
    const [usuariosAtivosTabela, setUsuariosAtivosTabela] = useState<TabelaUsuariosAtivos[]>([]);


    
    const getAll = useCallback(async () => {
        const { status, data } = await GetUsuario.getAll();
        const ordenado = data.sort(function(a,b){return a.nome.toUpperCase() < b.nome.toUpperCase() ? -1: a.nome.toUpperCase() > b.nome.toUpperCase() ? 1: 0})
        if(status !== 200) throw new Error();
        setUsuariosLista(ordenado) 
    
    },[]);

    const getAllUsuariosAtivos = useCallback(async () => {
        const {status, data} = await GetUsuario.getAllUsuariosAtivos();
        if(status !== 200) throw new Error();
        setUsuariosAtivosTabela(data)
    },[])

    const createUsuario = useCallback(async (usuarios: Omit<Usuario, 'id'>) => {
        const { status, data } = await GetUsuario.createUsuario(usuarios);
        
        if(status !== 201) throw new Error();

    }, [])

    const alteraUsuario = useCallback(async (usuario: Usuario) => {
        const { status, data } = await GetUsuario.alteraUsuario(usuario);
        
        if(status !== 201) throw new Error();

    }, [])


    return {
        usuariosLista,
        usuariosAtivosTabela,
        getAll,
        getAllUsuariosAtivos,
        createUsuario,
        alteraUsuario,
    }
}

export { Usuarios }