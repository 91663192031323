import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Parametros, Linha, Botao, Alinhamento } from './styles';
import { Header } from '../../components/Header';
import { FormHandles } from '@unform/core';
import { FiPlus, FiSave } from 'react-icons/fi';
import { Tab, Tabs, Form,  Button } from 'react-bootstrap';
import { Cargos } from '../../hooks/cargo';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import Slider from '@mui/material/Slider';
import api from '../../services/api';

interface Contrato {
  id: number;
  nome: string;
  ativo: boolean;
}

interface Parametro {
  id: number;
  tipo: string;
  chave: string;
  valor: string;
}

const Contrato: React.FC = () => {
  const history = useHistory();
  const idCargo = useRef(null);
  const { cargoLista, getAll } = Cargos();
  const [checked, setChecked] = React.useState(false);
  const [listaSearchCargo, setListaSearchCargo] = useState<Contrato[]>(cargoLista);
  const [listaParametros, setListaParametros] = React.useState([]);

  async function ParametrosBanco(){
    const result = await api.get('/v1/parametros')
    result.data.map(function(item: any){
      if (item.id==1){
        setModelAutomovel({nome: item.valor})
        setValueAutomovel(item.valor)
      }
      if (item.id==2){
        setModelCaminhao({nome: item.valor})
        setValueCaminhao(item.valor)
      }
      if (item.id==3){
        setModelImovel({nome: item.valor})
        setValueImovel(item.valor)
      }
      if (item.id==4){
        setModelMotocicleta({nome: item.valor})
    setValueMotocicleta(item.valor)
      }
      if (item.id==5){
        setModelServico({nome: item.valor})
    setValueServico(item.valor)
      }
      if (item.id==6){
        setModelOutros({nome: item.valor})
    setValueOutros(item.valor)
      }
      if (item.id==7){
        setModelVida({nome: item.valor})
    setValueVida(item.valor)
      }
      if (item.id==8){
        setModelSQGAutomovel({nome: item.valor})
    setValueSQGAutomovel(item.valor)
      }
      if (item.id==9){
        setModelSQGImovel({nome: item.valor})
    setValueSQGImovel(item.valor)
      }
      if (item.id==10){
        setModelSQGCaminhao({nome: item.valor})
    setValueSQGCaminhao(item.valor)
      }

    });
    
    
    
    
    
    
  }

  useEffect(() => {
    ParametrosBanco()
  }, []) 

  async function salvarParametros(){
    await api.put(`/v1/parametros/1`, {id: 1,"tipo": "parametroContrato", "chave": "automovelIdade", valor: valueAutomovel})
    await api.put(`/v1/parametros/2`, {id: 2, "tipo": "parametroContrato", "chave": "caminhaoIdade", valor: valueCaminhao})
    await api.put(`/v1/parametros/3`, {id: 3, "tipo": "parametroContrato", "chave": "imovelIdade", valor: valueImovel})
    await api.put(`/v1/parametros/4`, {id: 4, "tipo": "parametroContrato", "chave": "motocicletaIdade", valor: valueMotocicleta})
    await api.put(`/v1/parametros/5`, {id: 5, "tipo": "parametroContrato", "chave": "servicoIdade", valor: valueServico})
    await api.put(`/v1/parametros/6`, {id: 6, "tipo": "parametroContrato", "chave": "outrosIdade", valor: valueOutros})
    await api.put(`/v1/parametros/7`, {id: 7, "tipo": "parametroContrato", "chave": "vidaValor", valor: valueVida})
    await api.put(`/v1/parametros/8`, {id: 8, "tipo": "parametroContrato", "chave": "automovelValor", valor: valueSQGAutomovel})
    await api.put(`/v1/parametros/9`, {id: 9, "tipo": "parametroContrato", "chave": "imovelValor", valor: valueSQGImovel})
    await api.put(`/v1/parametros/10`, {id: 10, "tipo": "parametroContrato", "chave": "caminhaoValor", valor: valueSQGCaminhao})

    alert('Dados Salvos com sucesso');
  
  }

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  const marks2 = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1000000,
      label: '1000000',
    },
  ];

  const [valueAutomovel, setValueAutomovel] = React.useState<number>(0);
  const [valueCaminhao, setValueCaminhao] = React.useState<number>(0);
  const [valueImovel, setValueImovel] = React.useState<number>(0);
  const [valueMotocicleta, setValueMotocicleta] = React.useState<number>(0);
  const [valueServico, setValueServico] = React.useState<number>(0);
  const [valueOutros, setValueOutros] = React.useState<number>(0);
  const [valueVida, setValueVida] = React.useState<number>(0);
  const [valueSQGAutomovel, setValueSQGAutomovel] = React.useState<number>(0);
  const [valueSQGImovel, setValueSQGImovel] = React.useState<number>(0);
  const [valueSQGCaminhao, setValueSQGCaminhao] = React.useState<number>(0);

  const [modelAutomovel, setModelAutomovel] = useState({nome: ''})
  const [modelCaminhao, setModelCaminhao] = useState({nome: ''})
  const [modelImovel, setModelImovel] = useState({nome: ''})
  const [modelMotocicleta, setModelMotocicleta] = useState({nome: ''})
  const [modelServico, setModelServico] = useState({nome: ''})
  const [modelOutros, setModelOutros] = useState({nome: ''})
  const [modelVida, setModelVida] = useState({nome: ''})
  const [modelSQGAutomovel, setModelSQGAutomovel] = useState({nome: ''})
  const [modelSQGImovel, setModelSQGImovel] = useState({nome: ''})
  const [modelSQGCaminhao, setModelSQGCaminhao] = useState({nome: ''})
  

  const handleChangeSliderAutomovel = (event: Event, newValue: number | number[]) => {
    setModelAutomovel({nome: newValue.toString()});
    setValueAutomovel(newValue as number);
  };

  function handleChangeInputAutomovel(e: ChangeEvent<HTMLInputElement>) {
		setModelAutomovel({
			...modelAutomovel,
			[e.target.name]: e.target.value
		});
    setValueAutomovel(parseInt(e.target.value))
	}

  function valuetextAutomovel(value: number) {
    return `${value}`;
  }

  const handleChangeSliderCaminhao = (event: Event, newValue: number | number[]) => {
    setModelCaminhao({nome: newValue.toString()});
    setValueCaminhao(newValue as number);
  };

  function handleChangeInputCaminhao(e: ChangeEvent<HTMLInputElement>) {
		setModelCaminhao({
			...modelCaminhao,
			[e.target.name]: e.target.value
		});
    setValueCaminhao(parseInt(e.target.value))
	}

  function valuetextCaminhao(value: number) {
    return `${value}`;
  }

  const handleChangeSliderImovel = (event: Event, newValue: number | number[]) => {
    setModelImovel({nome: newValue.toString()});
    setValueImovel(newValue as number);
  };

  function handleChangeInputImovel(e: ChangeEvent<HTMLInputElement>) {
		setModelImovel({
			...modelCaminhao,
			[e.target.name]: e.target.value
		});
    setValueImovel(parseInt(e.target.value))
	}

  function valuetextImovel(value: number) {
    return `${value}`;
  }

  const handleChangeSliderMotocicleta = (event: Event, newValue: number | number[]) => {
    setModelMotocicleta({nome: newValue.toString()});
    setValueMotocicleta(newValue as number);
  };

  function handleChangeInputMotocicleta(e: ChangeEvent<HTMLInputElement>) {
		setModelMotocicleta({
			...modelCaminhao,
			[e.target.name]: e.target.value
		});
    setValueMotocicleta(parseInt(e.target.value))
	}

  function valuetextMotocicleta(value: number) {
    return `${value}`;
  }

  const handleChangeSliderServico = (event: Event, newValue: number | number[]) => {
    setModelServico({nome: newValue.toString()});
    setValueServico(newValue as number);
  };

  function handleChangeInputServico(e: ChangeEvent<HTMLInputElement>) {
		setModelServico({
			...modelCaminhao,
			[e.target.name]: e.target.value
		});
    setValueServico(parseInt(e.target.value))
	}

  function valuetextServico(value: number) {
    return `${value}`;
  }

  const handleChangeSliderOutros = (event: Event, newValue: number | number[]) => {
    setModelOutros({nome: newValue.toString()});
    setValueOutros(newValue as number);
  };

  function handleChangeInputOutros(e: ChangeEvent<HTMLInputElement>) {
		setModelOutros({
			...modelOutros,
			[e.target.name]: e.target.value
		});
    setValueOutros(parseInt(e.target.value))
	}

  function valuetextOutros(value: number) {
    return `${value}`;
  }

  const handleChangeSliderVida = (event: Event, newValue: number | number[]) => {
    setModelVida({nome: newValue.toString()});
    setValueVida(newValue as number);
  };

  function handleChangeInputVida(e: ChangeEvent<HTMLInputElement>) {
		setModelVida({
			...modelOutros,
			[e.target.name]: e.target.value
		});
    setValueVida(parseInt(e.target.value))
	}

  function valuetextVida(value: number) {
    return `${value}`;
  }

  const handleChangeSliderSQGAutomovel = (event: Event, newValue: number | number[]) => {
    setModelSQGAutomovel({nome: newValue.toString()});
    setValueSQGAutomovel(newValue as number);
  };

  function handleChangeInputSQGAutomovel(e: ChangeEvent<HTMLInputElement>) {
		setModelSQGAutomovel({
			...modelOutros,
			[e.target.name]: e.target.value
		});
    setValueSQGAutomovel(parseInt(e.target.value))
	}

  function valuetextSQGAutomovel(value: number) {
    return `${value}`;
  }

  const handleChangeSliderSQGImovel = (event: Event, newValue: number | number[]) => {
    setModelSQGImovel({nome: newValue.toString()});
    setValueSQGImovel(newValue as number);
  };

  function handleChangeInputSQGImovel(e: ChangeEvent<HTMLInputElement>) {
		setModelSQGImovel({
			...modelOutros,
			[e.target.name]: e.target.value
		});
    setValueSQGImovel(parseInt(e.target.value))
	}

  function valuetextSQGImovel(value: number) {
    return `${value}`;
  }

  const handleChangeSliderSQGCaminhao= (event: Event, newValue: number | number[]) => {
    setModelSQGCaminhao({nome: newValue.toString()});
    setValueSQGCaminhao(newValue as number);
  };

  function handleChangeInputSQGCaminhao(e: ChangeEvent<HTMLInputElement>) {
		setModelSQGCaminhao({
			...modelOutros,
			[e.target.name]: e.target.value
		});
    setValueSQGCaminhao(parseInt(e.target.value))
	}

  function valuetextSQGCaminhao(value: number) {
    return `${value}`;
  }

  

  return (
    <>
      <Header />

      <Container>
        
      <Alinhamento>
      <Parametros>
      <div className='title'><h2>Parâmetros Contrato</h2></div>
      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-12 aba">
      <Tab className='aba1' eventKey="home" title="Idade Máxima">
      <Linha>
      <div className='sliderValor'>
      <label>Automóvel</label>
      <Slider
            value={valueAutomovel}
            min={0}
            max={100}
            onChange={handleChangeSliderAutomovel}
            valueLabelDisplay="on"
            getAriaValueText={valuetextAutomovel}
            marks={marks}
            getAriaLabel={() => 'Automovel'}
          />
      </div>
      <div className='inputValor'>
       <Form.Group >
							<Form.Control
								type="text"
								name="nome"
								value={modelAutomovel.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputAutomovel(e)}
							/>
						</Form.Group>
            </div>
        </Linha>
        <Linha>
      <div className='sliderValor'>
      <label>Caminhão</label>
      <Slider
            value={valueCaminhao}
            min={0}
            max={100}
            onChange={handleChangeSliderCaminhao}
            valueLabelDisplay="on"
            getAriaValueText={valuetextCaminhao}
            marks={marks}
            getAriaLabel={() => 'Caminhao'}
          />
      </div>
      <div className='inputValor'>
       <Form.Group >
							<Form.Control
								type="text"
								name="nome"
								value={modelCaminhao.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputCaminhao(e)}
							/>
						</Form.Group>
            </div>
        </Linha>

      <Linha>
      <div className='sliderValor'>
      <label>Imóvel</label>
      <Slider
            value={valueImovel}
            min={0}
            max={100}
            onChange={handleChangeSliderImovel}
            valueLabelDisplay="on"
            getAriaValueText={valuetextImovel}
            marks={marks}
            getAriaLabel={() => 'Imovel'}
          />
      </div>
      <div className='inputValor'>
       <Form.Group >
							<Form.Control
								type="text"
								name="nome"
								value={modelImovel.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputImovel(e)}
							/>
						</Form.Group>
            </div>
        </Linha>

        <Linha>
      <div className='sliderValor'>
      <label>Motocicleta</label>
      <Slider
            value={valueMotocicleta}
            min={0}
            max={100}
            onChange={handleChangeSliderMotocicleta}
            valueLabelDisplay="on"
            getAriaValueText={valuetextMotocicleta}
            marks={marks}
            getAriaLabel={() => 'Motocicleta'}
          />
      </div>
      <div className='inputValor'>
       <Form.Group >
							<Form.Control
								type="number"
								name="nome"
								value={modelMotocicleta.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputMotocicleta(e)}
							/>
						</Form.Group>
            </div>
        </Linha>

        <Linha>
      <div className='sliderValor'>
      <label>Serviço</label>
      <Slider
            value={valueServico}
            min={0}
            max={100}
            onChange={handleChangeSliderServico}
            valueLabelDisplay="on"
            getAriaValueText={valuetextServico}
            marks={marks}
            getAriaLabel={() => 'Servico'}
          />
      </div>
      <div className='inputValor'>
       <Form.Group >
							<Form.Control
								type="number"
								name="nome"
								value={modelServico.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputServico(e)}
							/>
						</Form.Group>
            </div>
        </Linha>
      
        <Linha>
      <div className='sliderValor'>
      <label>Outros</label>
      <Slider
            value={valueOutros}
            min={0}
            max={100}
            onChange={handleChangeSliderOutros}
            valueLabelDisplay="on"
            getAriaValueText={valuetextOutros}
            marks={marks}
            getAriaLabel={() => 'Outros'}
          />
      </div>
      <div className='inputValor'>
       <Form.Group >
							<Form.Control
								type="text"
								name="nome"
								value={modelOutros.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputOutros(e)}
							/>
						</Form.Group>
            </div>
        </Linha>
        
      </Tab>
      <Tab className='aba1' eventKey="profile" title="Valor Máximo Seguro">
      <Linha>
      <div className='sliderValor'>
      <label>Vida</label>
      <Slider
            value={valueVida}
            min={0}
            max={1000000}
            onChange={handleChangeSliderVida}
            valueLabelDisplay="on"
            getAriaValueText={valuetextVida}
            marks={marks2}
            getAriaLabel={() => 'Vida'}
          />
      </div>
      <div className='inputValor1'>
       <Form.Group >
							<Form.Control
								type="text"
								name="nome"
								value={modelVida.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputVida(e)}
							/>
						</Form.Group>
            </div>
        </Linha>
        

        <Linha>
      <div className='sliderValor'>
      <h5>SQG</h5> 
      <label>Automóvel</label>
      <Slider
            value={valueSQGAutomovel}
            min={0}
            max={1000000}
            onChange={handleChangeSliderSQGAutomovel}
            valueLabelDisplay="on"
            getAriaValueText={valuetextSQGAutomovel}
            marks={marks2}
            getAriaLabel={() => 'SQG Automovel'}
          />
      </div>
      <div className='inputValor1'>
       <Form.Group >
							<Form.Control
								type="text"
								name="nome"
								value={modelSQGAutomovel.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputSQGAutomovel(e)}
							/>
						</Form.Group>
            </div>
        </Linha>
        <Linha>
      <div className='sliderValor'>
      <label>Imóvel</label>
      <Slider
            value={valueSQGImovel}
            min={0}
            max={1000000}
            onChange={handleChangeSliderSQGImovel}
            valueLabelDisplay="on"
            getAriaValueText={valuetextSQGImovel}
            marks={marks2}
            getAriaLabel={() => 'SQG Imovel'}
          />
      </div>
      <div className='inputValor1'>
       <Form.Group >
							<Form.Control
								type="text"
								name="nome"
								value={modelSQGImovel.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputSQGImovel(e)}
							/>
						</Form.Group>
            </div>
        </Linha>
        <Linha>
      <div className='sliderValor'>
      <label>Caminhão</label>
      <Slider
            value={valueSQGCaminhao}
            min={0}
            max={1000000}
            onChange={handleChangeSliderSQGCaminhao}
            valueLabelDisplay="on"
            getAriaValueText={valuetextSQGCaminhao}
            marks={marks2}
            getAriaLabel={() => 'SQG Imovel'}
          />
      </div>
      <div className='inputValor1'>
       <Form.Group >
							<Form.Control
								type="text"
								name="nome"
								value={modelSQGCaminhao.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputSQGCaminhao(e)}
							/>
						</Form.Group>
            </div>
        </Linha>
      </Tab>
</Tabs>

</Parametros>
<Botao>
            <Button variant="primary" onClick={salvarParametros} style={{ border: 'none', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems:"center"}}><FiSave size={30}/></Button>
          </Botao>
</Alinhamento>

      </Container>
    </>
  )
}

export default Contrato