import api from './api';
import { Cargo } from '../interfaces/cargo';

const getAll = () => api.get<Cargo[]>('/v1/cargos')

const createCargo = (cargo: Pick<Cargo, 'nome' | 'ativo'>) => api.post('/v1/cargos', cargo)

const alteraCargo = (cargo: Cargo) => api.put(`/v1/cargos/`, cargo)

const deleteCargo = (id: number) => api.delete(`/v1/cargos/${id}`)

export const GetCargos = {
    getAll,
    createCargo,
    alteraCargo,
    deleteCargo,
}