import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas, Tabelas1, Botao, PrimeiraLetra } from './styles';
import { Header } from '../../components/Header';
import { FaBarcode } from 'react-icons/fa';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';
import { Planoh } from '../../hooks/plano';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Boletoi } from '../../interfaces/boleto'
import { Remessa } from '../../interfaces/remessa'
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationBoleto';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import NativeSelect from '@mui/material/NativeSelect';
import Link from '@mui/material/Link';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FiDownload } from 'react-icons/fi';

const styleBox = {
  position: 'absolute' as 'absolute',
  overflow: 'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1050,
  maxHeight: 500,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  boxShadow: 2,
  p: 4,

  header: {
    padding: '12px 0',
    borderBottom: '1px solid darkgrey'
  },
  content: {
    padding: 12,
    overflow: 'scroll'
  }
};



const Boleto: React.FC = () => {
  const { addToast } = useToast();
  moment.locale('pt-br');
  const history = useHistory();
  const idCargo = useRef(null);
  const { planosLista, ativosLista, getAll, getAllAtivos } = Planoh();
  const [checked, setChecked] = React.useState(false);
  const [dadosPreVendaUsuarioF, setDadosPreVendaUsuarioF] = useState<Boletoi[]>([])
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(dadosPreVendaUsuarioF.length / PER_PAGE);
  const _DATA = usePagination(dadosPreVendaUsuarioF, PER_PAGE);
  const [filtroDescricao, setFiltroDescricao] = useState<string>();
  const [filtroAtivo, setFiltroAtivo] = useState<string>('Todos');
  const [filtroTipoBem, setFiltroTipoBem] = useState<number>(0);
  const [filtroTipoVenda, setFiltroTipoVenda] = useState<number>();
  const [dataInicial, setDataInicial] = React.useState<Date | null>(null);
  const [dataFinal, setDataFinal] = React.useState<Date | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [remessas, setRemessas] = useState<Remessa[]>([])
  




  const handleChangePagination = (event: object, e: number) => {
    setPage(e);
    _DATA.jump(e);
  };

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    setFiltroDescricao(event.target.value)
  }

  function handleSearchCodigoVenda(event: ChangeEvent<HTMLInputElement>) {
    setFiltroTipoVenda(parseInt(event.target.value))
  }

  function handleSearchAtivos(event: ChangeEvent<HTMLInputElement>) {
    setFiltroAtivo(event.target.value)
  }

  function handleChangeSelected(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroTipoBem(parseInt(e.target.value))
  }

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  async function preVenda(){
    const response = await api.get(`/v1/boletos`)
    const dados: Boletoi[] = response.data
    const dadosOrdenados = dados.sort(function (a,b){
      if(a.numeroDocumento > b.numeroDocumento){
        return -1
      }
      if(a.numeroDocumento < b.numeroDocumento){
        return 1
      }

      return 0
    })
    setDadosPreVendaUsuarioF(dadosOrdenados)

    const responseRemessa = await api.get(`/v1/remessas`)
    const dadosRemessa: Remessa[] = responseRemessa.data
    const dadosRemessaOrdenados = dadosRemessa.sort(function (a,b){
      if(a.id > b.id){
        return -1
      }
      if(a.id < b.id){
        return 1
      }
      return 0
    })
    console.log(dadosRemessaOrdenados)

    setRemessas(dadosRemessaOrdenados)
  }

  useEffect(()=> {
    preVenda()
  },[])



  async function handleSwitch(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked === true) {

      const plano = planosLista.find(item => item.id.toString() === event.target.id)

      if (plano != undefined) {
        try {

          const response = await api.post(`/v1/sequencias-agrupamento-interno`,
            {
              id: plano.id,
              sequenciaAgrupamento: plano.sequenciaAgrupamento,
              codigoGrupoBem: plano.codigoGrupoBem,
              codigoTipoVenda: plano.codigoTipoVenda,

            })

          getAllAtivos();

          if (response.status === 201) {
            addToast({
              type: 'success',
              title: 'Dados Salvos',
              description: 'Dados foram salvos com sucesso!'
            })
          } else {
            addToast({
              type: 'error',
              title: 'Erro ao tentar salvar!',
              description: 'Informe este erro ao TI do consórcio2!'
            })
          }
        } catch (error) {
          console.log(error)
          addToast({
            type: 'error',
            title: 'Erro ao tentar salvar!',
            description: 'Informe este erro ao TI do consórcio!'
          })
        }

      }
    } else {
      try {
        const idSA = planosLista.find(e => e.id.toString() === event.target.id)
        const idSAAtivo = ativosLista.find(e => e.codigoGrupoBem === idSA?.codigoGrupoBem && e.codigoTipoVenda === idSA.codigoTipoVenda && e.sequenciaAgrupamento === idSA.sequenciaAgrupamento);
        if (idSAAtivo != null) {
          const responseDel = await api.delete(`/v1/sequencias-agrupamento-interno/${idSAAtivo.id}`)
          console.log(responseDel)
          getAllAtivos();

          addToast({
            type: 'info',
            title: 'Deletado com sucesso!',
            description: 'Plano foi deletado com sucesso!'
          })
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao tentar salvar!',
            description: 'Informe este erro ao TI do consórcio!'
          })
        }

      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao tentar salvar!',
          description: 'Informe este erro ao TI do consórcio!'
        })
      }
    }
  }

  useEffect(() => {
    addToast({
      type: 'info',
      title: 'Carregando a Listagem',
      description: 'Por favor aguarde!'
    })
    getAll();
    getAllAtivos();
  }, [getAll, getAllAtivos])





  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != undefined) {
      setDataInicial(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
    }
  };

  const handleChangeDateFinal = (newValue: Date | null) => {
    if (newValue != undefined) {
      setDataFinal(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
    }
  };

  async function gerarRemessa(){
    console.log(`chegou aquiiii`)
    const response = await api.post(`v1/remessas/gerar`)

    console.log(response.data[0])
    console.log(`status`)

    if(response.status === 201){
      if(response.data[0].link){
      window.open(response.data[0].link, "blank")
      preVenda()
      
    }
      addToast({
        type: 'success',
        title: 'Arquivo',
        description: 'Seu download começa em breve!'
      });
  }else{
    addToast({
      type: 'success',
      title: 'Sem arquivos',
      description: 'Não tem boletos para serem registrados!'
    });
  }
  }

  function formatData(x: string) {
    let dataAss = new Date(x)
    let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }

    


  return (
    <>
      <Header />
      <Container>
        <div className='title'><h2>Boletos</h2></div>
        <Tabelas>
          <Table bordered hover className='tabela'>
            <thead>
              <tr className='PrimeiroTH'>
                <th colSpan={7} className='PrimeiroTH'>
                  <Row className='PrimeiraLinha'>
                    <Col xs={4}>
                      <TextField className='pesquisa' variant='standard' type="number" placeholder="Digite Numero Contrato" onChange={handleSearchCodigoVenda} name="procurar" />
                    </Col>
                    <Col xs={4}>
                      <TextField className='pesquisa' variant='standard' type="number" placeholder="Digite Vendedor" onChange={handleSearchCodigoVenda} name="procurar" />
                    </Col>
                    <Col xs={4}>
                      <TextField className='pesquisa' variant='standard' type="number" placeholder="Digite Data Vencimento" onChange={handleSearchCodigoVenda} name="procurar" />
                    </Col>
                  </Row>
                </th>

              </tr>

              <tr>
                <th colSpan={7}>
                  <Row className='PrimeiraLinha1'>
                  <Col xs={4}>
                      <TextField className='pesquisa' variant='standard' type="number" placeholder="Digite Valor" onChange={handleSearchCodigoVenda} name="procurar" />
                    </Col>
                    <Col xs={4}>
                    <NativeSelect
                        className="status1"
                        defaultValue={30}
                        onChange={handleChangeSelected}
                        //value={model.usuario.id}
                        inputProps={{
                          name: 'age',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option id="0" value="0">TODOS</option>
                        <option id="0" value="0">SIM</option>
                        <option id="0" value="0">NÃO</option>
                      </NativeSelect>
                    </Col>
                    <Col xs={4}>
                      <NativeSelect
                        className="status1"
                        defaultValue={30}
                        onChange={handleChangeSelected}
                        //value={model.usuario.id}
                        inputProps={{
                          name: 'age',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option id="0" value="0">TODOS</option>
                        <option id="0" value="0">REGISTRADO</option>
                        <option id="0" value="0">NÃO REGISTRADO</option>                                                                  
                      </NativeSelect>
                    </Col>
                  </Row>
                </th>
              </tr>
             
            </thead>
          </Table>

          <Table bordered hover className='tabela'>
            <thead>
              <tr className='tituloTabela'>
                <th className="ac">Contrato</th>
                <th className="ac">Consorciado</th>
                <th className="ac">Documento</th>
                <th className="ac">Vencimento</th>
                
                <th className="ac">Valor</th>
                <th className="ac">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody className='teste'>
            {_DATA.currentData().map(item => {

              return(
                <>
               <tr className='linha1'>
               <td className="ac">
                 {item.numeroDocumento}
               </td>
               <td className="ac">
                {item.nome}
               </td>
               <td className="ac">
                {item.documento}
               </td>
               <td className="ac">
                {formatData(item.dataVencimento)}
               </td>
               <td className="ac">
               {item.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
               </td>
               <td className="ac">
               {item.situacao==="GERADO"?"AGUARDANDO":item.situacao}
               </td>
               
               <td className="icone">
               <Link href={item.link} target='blank'> <FaBarcode/></Link>
               </td>

             </tr>
             </>
              )
            })}

            

              <tr className="linha2">
                <td colSpan={8} className="pagination1" >
                  <Pagination style={{ alignItems: "center" }} count={count} page={page} onChange={handleChangePagination} size="large" />
                </td>
              </tr>
              <tr>
                <th colSpan={8}>
                  <Row className='PrimeiraLinha1'>
                    <Col className="botoesPesquisar">
                      <Form.Group className="buttonsCargo">
                        <Button onClick={handleOpen}>Remessas Anteriores</Button>
                        <Button onClick={gerarRemessa} >Gerar Remessa</Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </th>
              </tr>
            </tbody>
          </Table>
        </Tabelas>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleBox}>
        
         

            
          <Row>
         
          <Typography>
             <Tabelas1>
             <div className="titulo title1"><Typography style={{border: "none", margin:"0"}} gutterBottom><h5>Lista Remessas</h5></Typography></div>

            <Table bordered hover className='tabela'>
            <thead>
              <tr className='tituloTabela'>
                <th className="ac">Nome Arquivo</th>
                <th className="ac">Quantida de Boletos</th>
                <th className="ac">Contratos</th>
                <th className="ac">Baixar Arquivo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {remessas.map(function (item){
                console.log(item.listBoleto)
                return (<>
                <tr>
                <td className="ac">{item.nomeArquivo}</td>
                <td className="ac">{item.listBoleto.length}</td>
                <td className="ac">{
                  item.listBoleto.map(item=>item.numeroDocumento+ '  ')
                }</td>
                <td className="ac"><Link href={item.link} target='blank'><FiDownload size={20} /></Link></td>
                </tr>
                
                </>)
              })}
              </tbody>
              </Table>

              <Row className="tipoPessoa">

              </Row>
            
            <Typography>
              <Tabelas1>

                <div className="testea">
                  <Button onClick={handleClose} style={{ backgroundColor: "rgb(19,52,131)", marginRight: "20px" }}><Typography variant="button" gutterBottom>FECHAR</Typography></Button>
                </div>

              </Tabelas1>




            </Typography>
            </Tabelas1>
            </Typography>
            </Row>
          </Box>

        </Modal>
      </Container>

    </>
  )
}

export default Boleto