import api from './api';
import { UsuarioSistemaInterface } from '../interfaces/usuarioSistema';


const getAll = () => api.get<UsuarioSistemaInterface[]>('/v1/usuarios')

const createUsuario = (usuario: Omit<UsuarioSistemaInterface, `id`>) => api.post('/v1/usuarios', usuario)

const alteraUsuario = (usuario: UsuarioSistemaInterface) => api.put(`/v1/usuarios/`, usuario)

const deleteUsuario = (id: number) => api.delete(`/v1/cargos/${id}`)

export const GetUsuario = {
    getAll,
    createUsuario,
    alteraUsuario,
    deleteUsuario,
}