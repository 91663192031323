import { useCallback, useState } from "react"
import { GetUsuario } from '../services/UsuariosSistema';
import { UsuarioSistemaInterface } from '../interfaces/usuarioSistema';


const UsuariosLogin = () => {
    const [usuariosLista, setUsuariosLista] = useState<UsuarioSistemaInterface[]>([]);
    
    const getAll = useCallback(async () => {
        const { status, data } = await GetUsuario.getAll();
        const ordenado = data.sort(function(a,b){return a.nome.toUpperCase() < b.nome.toUpperCase() ? -1: a.nome.toUpperCase() > b.nome.toUpperCase()? 1:0})

        if(status !== 200) throw new Error();

        setUsuariosLista(ordenado) 
    
    },[]);

    const createUsuario = useCallback(async (usuarios: Omit<UsuarioSistemaInterface, 'id'>) => {
        const { status, data } = await GetUsuario.createUsuario(usuarios);
        
        if(status !== 201) throw new Error();

    }, [])

    const alteraUsuario = useCallback(async (usuario: UsuarioSistemaInterface) => {
        const { status, data } = await GetUsuario.alteraUsuario(usuario);
        
        if(status !== 201) throw new Error();

    }, [])


    return {
        usuariosLista,
        getAll,
        createUsuario,
        alteraUsuario,
    }
}

export { UsuariosLogin }