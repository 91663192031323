import React, { ChangeEvent, useEffect, useRef, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { Container, CardContainer, ViewSubMenu } from './styles';
import { Button, Form } from 'react-bootstrap';
import api from '../../../services/api';
import { GrupoUsuarios as GrupoUsuariosInterface } from '../../../interfaces/grupoUsuarios';
import { Menu as MenuInterface } from '../../../interfaces/menu';
import Accordion from 'react-bootstrap/Accordion';
import { Menu } from '../../../hooks/menu';
import { Checkbox, listItemIconClasses } from '@mui/material';

const FormGrupoUsuarios: React.FC = () => {
  const history = useHistory();
  const { id } = useParams() as any;
  const checkRef = useRef(null);
  const { menuLista, getAll } = Menu();
  const [model, setModel] = useState<GrupoUsuariosInterface>({ nome: '', listMenuGrupoUsuario: [] })
  const [usuarioIds, setUsuarioIds] = useState<Array<Number>>([])

  useEffect(() => {
    getAll();
  }, [getAll])

  useEffect(() => {
    if (id !== undefined) {
      findGrupoUsuarios(id)
    }
  }, [])

  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    setModel({
      ...model,
      [e.target.name]: e.target.value
    });
  }

  async function findGrupoUsuarios(id: string) {
    const response = await api.get(`/v1/grupos-usuario/${id}`);
    setModel({
      id: response.data[0].id,
      nome: response.data[0].nome,
      listMenuGrupoUsuario: response.data[0].listMenuGrupoUsuario || []
    })
    const array = response.data[0].listMenuGrupoUsuario?.map((item: any) => { return item.menu.id }) || [];
    setUsuarioIds(array)
  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    let arrayUsIds = usuarioIds;
    if (id !== undefined) {
      model.listMenuGrupoUsuario?.map(async item => {
        //        se veio no state inicial, e tem no atual, ignora
        if (arrayUsIds.some(i => i === item.menu.id)) {
          arrayUsIds = arrayUsIds.filter(j => j !== item.menu.id)
          return
          //        se veio no state inicial, e nào tem no atual, apaga
        }else{ 
          if (!arrayUsIds.some(i => i === item.menu.id)) {
            await api.delete(
              `/v1/menus-grupo-usuario/${item.id}`)
              .catch(
                err =>
                  alert(`Falha ao remover menu! - ${err}`)
              )
            return
          }
        }
    })
      //      se sobrou algum id, precisa persistir
      arrayUsIds?.map(async item => {
        await api.post(
          `/v1/menus-grupo-usuario`, {
          grupoUsuario: {
            id: model.id
          },
          menu: {
            id: item,
          }
        })
          .catch(
            err =>
              console.log(`Falha ao salvar menus! - ${err}`)
          )
      })
    } else {
      const resGruposUsuario = await api.post(`/v1/grupos-usuario`, { nome: model.nome.toUpperCase() });
      if (resGruposUsuario.status !== 200 && resGruposUsuario.status !== 201) {
        alert('Falha ao salvar Grupo de Usuários!')
        return;
      }
      arrayUsIds.map(
        async item => {
          if (item)
            await api.post(
              `/v1/menus-grupo-usuario`, {
              grupoUsuario: {
                id: resGruposUsuario.data[0].id
              },
              menu: {
                id: item,
              }
            })
              .catch(
                err =>
                  alert(`Falha ao salvar Menus de Grupo de Usuários! - ${err}`)
              )
        }
      );
    }
    history.push('/grupoUsuarios');
  }

  function back() {
    history.goBack();
  }

  function RenderMenu(menu: { id: string; }, indexMenu: number) {
    return (
      <Accordion.Item key={indexMenu} eventKey={menu.id}>
        <Accordion.Header>
          {menuLista[indexMenu].nome}
        </Accordion.Header>
        {menuLista[indexMenu].menusInferiores?.map(
          (item: any, index) =>
            RenderSubMenu(menu, indexMenu, item, index)
        )}
      </Accordion.Item>
    );
  }

  function RenderSubMenu(menu: { id: string; }, indexMenu: number, subMenu: { id: string; }, indexSubMenu: number) {
    return (
      <Accordion.Body key={indexSubMenu} style={{ padding: 0 }}>
        <ViewSubMenu>
          {menuLista[indexMenu]?.menusInferiores[indexSubMenu]?.nome}
          <Checkbox
            ref={checkRef}
            id={subMenu.id.toString()}
            value={subMenu.id}
            checked={isChecked(Number(subMenu.id))}
            onChange={handleChangeCheck} style={{ alignSelf: 'flex-end' }}
          />
        </ViewSubMenu>
      </Accordion.Body>
    );
  }

  function isChecked(subMenu: number): boolean {
    return usuarioIds?.some(i => i === subMenu);
  }

  function handleChangeCheck(e: ChangeEvent<HTMLInputElement>) {
    const idSubMenu = Number(e.target.value);
    const hasMenu = usuarioIds?.find(item => item === idSubMenu);
    let array;
    if (hasMenu) {
      array = usuarioIds?.filter(item => item !== idSubMenu);
    } else {
      array = [...usuarioIds, idSubMenu];
    }
    setUsuarioIds(array);
  }

  return (
    <>
      <Header />
      <Container>
        <CardContainer>
          <div className='title'><h2>Cadastro de Grupo de Usuários</h2></div>
          <Form className="form-border" onSubmit={onSubmit}>
            <Form.Group className="inputCargo">
              <Form.Label>Nome do Grupo de Usuário</Form.Label>
              <Form.Control
                type="text"
                name="nome"
                placeholder="Grupo de Usuário"
                value={model.nome}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
              />
            </Form.Group>
            <Form.Group className="listMenus">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px'
                }}
              >
                <Accordion defaultActiveKey={['0']} alwaysOpen style={{ flex: 1 }}>
                  {menuLista?.map(
                    (item: any, index) =>
                      item.menusInferiores ?
                        RenderMenu(item, index)
                        : <></>)}
                </Accordion>
              </div>
            </Form.Group>
            <Form.Group className="buttonsCargo">
              <Button onClick={() => back()}>Cancelar</Button>
              <Button type="submit">Salvar</Button>
            </Form.Group>
          </Form>
        </CardContainer>
      </Container>
    </>
  )
}

export default FormGrupoUsuarios