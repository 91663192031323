import styled from 'styled-components';

const background = ["#c62828", "#ad1457", "#512da8", "#7b1fa2", "#0d47a1", "#0097a7", "#00897b", "#388e3c", "#455a64"]


export const Container = styled.div`

   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;


   Form{
      label{
      color:red;
      font-size: 40px;
      }
   }

   .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 800px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
   }

   input{
       width: 100%;
       height: 100%;
       border: none;
   }
`;


export const Teste = styled.div`
   display: flex;
   flex: 1;
   align-items: center;
   width: 50px;
`;

export const Section = styled.section`
   display: flex;
   
   margin-top: 48px;
   width: 400px;
   

   strong {
       display: flex;
       flex-wrap: wrap;
       flex: 1;
       justify-content: right;
       color: #999591;
       font-size: 40px;
       line-height: 26px;
       border-bottom: 1px solid #797571;
       display: block;
       padding-bottom: 16px;
   }

`
export const Titulo = styled.div`
      display: flex;
      flex: 1;
      align-items: center;
`

export const Cargos = styled.div`
     display: flex;
     flex-direction: column;
     

     a{
        display: flex;
        justify-content: left;
        flex: 1;
        width: 400px;
        align-items: center;
        padding: 16px 14px;
        border-radius: 10px;
        margin-top: 10px;
        position: relative;
        background: #969cb3;
        color: #f4ede8;
     }

     span {
         display: flex;
         align-items: center;
         justify-content: center;
         color: #363f5f;
         font-size: 30px;
         width: 45px;
         height: 45px;

         border-radius: 50%;
         background: #f4ede8;
     }

     strong{
         margin-left: 20px;
     }

     input {
         margin-top: 15px;
     }
`
export const Tabelas = styled.div`
   max-width: 800px;
   width: 100%;
   display: flex;

   .Mui-selected {
    background-color: #ffffff;
    border-bottom: solid 3px;
    border-color: var(--blue);
    font-weight: bold;
   }

   .tabela{
     vertical-align: center;
     box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);

     
   }

   .pagination2{
      display: flex;
      justify-content: center;
   }



   .linha{
      /* height: 60px; */
      vertical-align: center;
      font-size: 13px;
   }

   .id{
      display: flex;
      justify-content: center;
      max-width: 135px;
   }

   .ativar{
      text-align: center;
   }
   
   .pesquisa{
      width: 100%;
   }

   .tituloTabela{
      font-size: 13px;
   }

   .ativar{
      height: 10px;
   }

   .tr{
      tr:hover {background-color: none;}
   }

   tr:hover {
    background-color: #ffffff; /* Assuming you want the hover color to be white */
   }


   thead{
      //margin-bottom: 2px;
      tr, th{
         div{
            margin-bottom: 7px;
         }
         //height: 60px;
      }
   }



   .table-bordered>:not(caption)>*>* {
    border-width: 0 0;
   }

   .table>:not(:first-child) {
      border-top: 0px solid currentColor;
   }
   

   .MuiInput-root{
      width: 100%;
   }

   .PrimeiraLinha{
      height: 30px;
   }

   .PrimeiroTH{
      border-bottom: red;
   }

`;

export const Botao = styled.div`
   margin-left: 30px;

   .btn, .btn-primary{
      background: var(--blue);;
   }
 
`;

export const PrimeiraLetra = styled.div`
   vertical-align: center;   
   width: 40px;
   height: 40px;
   border-radius: 20px;
   font-size: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 20px;
   color: #ffffff;
`;