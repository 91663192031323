import React, { ChangeEvent } from "react";
import { Form, Button } from 'react-bootstrap';
import { Container } from './styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import api from '../../../services/api';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../hooks/toast';
import NumberFormat from "react-number-format";
import { bancos } from '../../../mock/bancos';
import { SelectChangeEvent } from '@mui/material/Select';
import { Col, Row } from "../styles";

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      //decimalSeparator={","}

      prefix={"R$ "}
    // isNumericString
    />
  );
}



export function Etapa5DadosBancarios(props: any) {
  const { addToast } = useToast();
  const { DadosVenda, setAlteraDados, setAlteraPagina } = props
  const [checked, setChecked] = React.useState(false);
  const [age, setAge] = React.useState<Date | null>(null);

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != undefined) {
      setAge(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
      let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      setAlteraDados({ ...DadosVenda, dataNascimento: newValue, dataNascimentoFormatada: DataFormatada1 })
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "conta") {
      let dadosGerais = `Agência: ${DadosVenda.agencia} | Conta: ${e.target.value} - ${DadosVenda.digito} | Banco : ${DadosVenda.banco}`
      console.log(dadosGerais)
      setAlteraDados({
        ...DadosVenda, tempoFuncao: dadosGerais, [e.target.name]: e.target.value.toUpperCase(),

      })
    } else if (e.target.name === "digito") {
      let dadosGerais = `Agência: ${DadosVenda.agencia} | Conta: ${DadosVenda.conta} - ${e.target.value}| Banco : ${DadosVenda.banco}`
      console.log(dadosGerais)
      setAlteraDados({
        ...DadosVenda, tempoFuncao: dadosGerais, [e.target.name]: e.target.value.toUpperCase(),

      })
    } else if (e.target.name === "agencia") {
      let dadosGerais = `Agência: ${e.target.value} | Conta: ${DadosVenda.conta} - ${DadosVenda.digito}| Banco : ${DadosVenda.banco}`
      console.log(dadosGerais)
      setAlteraDados({
        ...DadosVenda, tempoFuncao: dadosGerais, [e.target.name]: e.target.value.toUpperCase(),

      })

    } else if (e.target.name === "chavePix") {

      let dadosGerais = `chavePix: ${e.target.value} | Tipo : ${DadosVenda.tipoPix}`
      console.log('chegou aqui' + dadosGerais)
      setAlteraDados({
        ...DadosVenda, [e.target.name]: e.target.value.toUpperCase(),
        tempoFuncao: dadosGerais
      })
    } else {
      setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value.toUpperCase() })
    }

  };

  function TestaCPF(strCPF: string) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }




  /*function formatData(x: string) {
    let dataAss = new Date(x)
    let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }*/

  function handleChangeCheckPessoa(e: ChangeEvent<HTMLInputElement>) {
    if (parseInt(e.target.value) === 1) {
      setAlteraDados({ ...DadosVenda, informaConta: 1 })
    } else {
      setAlteraDados({ ...DadosVenda, informaConta: 0, tempoFuncao: "(X)Não desejo informar conta bancária para depósitos dos recursos relativos a devolução de valores!" })

    }
  }

  function handleChangeCheckTipo(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value === 'Pix') {
      setAlteraDados({
        ...DadosVenda,
        [e.target.name]: e.target.value,
        codBanco: 0,
        banco: "",
        agencia: 0,
        conta: "",
        digito: 0
      })
    } else {
      setAlteraDados({
        ...DadosVenda,
        [e.target.name]: e.target.value,
        tipoPix: "",
        chavePix: "",
      })

    }
  }

  function handleChangeSelect(e: ChangeEvent<HTMLSelectElement>) {
    const dadosBanco = bancos.find(item => item.codBanco === e.target.value)
    let dadosGerais = `Agência: ${DadosVenda.agencia} | Conta: ${DadosVenda.conta} - ${DadosVenda.digito}| Banco : ${dadosBanco?.banco.toUpperCase()}`
    setAlteraDados({ ...DadosVenda, tempoFuncao: dadosGerais, codBanco: e.target.value, banco: dadosBanco?.banco.toUpperCase() })

  }

  function handleChangeSelectTipo(e: ChangeEvent<HTMLSelectElement>) {
    setAlteraDados({
      ...DadosVenda, tipoPix: e.target.value,
    })
  }

  function handleChangeSelectNovo(e: SelectChangeEvent) {
    const dadosBanco = bancos.find(item => item.codBanco === e.target.value)
    setAlteraDados({ ...DadosVenda, codBanco: e.target.value, banco: dadosBanco?.banco.toUpperCase() })
  }

  function handleChangeSelectTipoNovo(e: SelectChangeEvent) {
    setAlteraDados({ ...DadosVenda, tipoPix: e.target.value })
  }


  async function passaFase4() {
    if (DadosVenda.codB === 0) {
      addToast({
        type: 'error',
        title: 'Erro bem selecionado',
        description: 'Ao retornar a primeira pagina, por favor selecione o bem novamente!'
      });
      setAlteraPagina(0)
    }
    if (DadosVenda.tipoPix === "CPF") {
      let resultado = DadosVenda.chavePix.replace(".", "").replace("-", "").replace("/", "")
      let teste = TestaCPF(resultado)
      if (teste === false) {
        addToast({
          type: 'error',
          title: 'CPF Inválido',
          description: 'Por favor insira um CPF Válido!'
        });
        return;
      }
      const response = await api.get(`/v1/dados-usuario/${DadosVenda.idVendedor}`);
      console.log('dados contato')
      console.log(response.data[0]?.listContato)
      if (response.data[0]?.listContato != undefined) {
        setAlteraDados({ ...DadosVenda, telVend: response.data[0].listContato[0] })
      }
    }

    if (DadosVenda.tipoPix === "email") {
      let re = /\S+@\S+\.\S+/;
      let valido = re.test(DadosVenda.chavePix);
      if (!valido) {
        addToast({
          type: 'info',
          title: 'Digite E-mail Válido',
          description: 'Digite um e-mail Válido!'
        })
        return;
      }

    }
    const tudo = JSON.stringify(DadosVenda)
    const teste = JSON.parse(tudo)
    if (DadosVenda?.id > 0) {
      const response = await api.put(`v1/pre-vendas/${DadosVenda.id}`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 5,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        status: "PENDENTE",
        posVenda: "PENDENTE",

      })

      console.log(response.status)

      if (response.status === 200) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa6: false,

        })
      }

      setAlteraPagina(5)
    } else {
      const response = await api.post(`v1/pre-vendas`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 5,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        status: "PENDENTE",
        posVenda: "PENDENTE",

      })

      if (response.status === 201) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa6: false,

        })

        setAlteraPagina(5)
      }
    }


  }

  function testaCPF() {
    if (DadosVenda.tipoPix === "CPF") {
      let resultado = DadosVenda.chavePix.replace(".", "").replace("-", "").replace("/", "")
      let teste = TestaCPF(resultado)
    } else if (DadosVenda.tipoPix === "email") {
      let re = /\S+@\S+\.\S+/;
      let valido = re.test(DadosVenda.chavePix);
      if (!valido) {
        addToast({
          type: 'info',
          title: 'Digite E-mail Válido',
          description: 'Digite um e-mail Válido!'
        })
      }
    }
  }


  return (
    <>
      <Container>
        <Row>
          <Col>
            Deseja informar dados bancários para a devolução de valores remanescentes ou devolução quando estiver na condição de desistente/excluído?
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="tipoPessoaRadio"
              onChange={handleChangeCheckPessoa}
              value={DadosVenda.informaConta}
            >
              <FormControlLabel value="1" control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Sim" />
              <FormControlLabel value="0" control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Não" />
            </RadioGroup>
          </Col>
        </Row>
        {DadosVenda.informaConta === 1 ? <>
          <Row style={{ marginTop: 20 }}>
            <Col>
              Conta própria
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="tipoConta"
                className="tipoPessoaRadio"
                onChange={handleChangeCheckTipo}
                value={DadosVenda.tipoConta}
              >
                <FormControlLabel value="ContaCorrente" control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Conta Corrente" />
                <FormControlLabel value="ContaPoupanca" control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Conta Poupança" />
                <FormControlLabel value="Pix" control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Pix" />
              </RadioGroup>
            </Col>
          </Row>

          {DadosVenda.tipoConta !== "Pix" ? <>
            <Row start style={{ marginTop: 15 }}>
              <Col min='80px' max='120px' >
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.codBanco === 0 ? false : true} >
                    Código  *
                  </InputLabel>
                  <NativeSelect
                    value={DadosVenda.codBanco}
                    onChange={handleChangeSelect}
                    inputProps={{
                      name: 'codBanco',
                      id: 'uncontrolled-native'
                    }}
                  >
                    <option value={""}/>
                    {bancos.map(item =>
                      <option value={item.codBanco}>{item.codBanco}</option>
                    )}
                  </NativeSelect>
                </FormControl>
              </Col>
              <Col min='260px' max='460px' >
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.codBanco === 0 ? false : true} >
                    Banco  *
                  </InputLabel>
                  <NativeSelect
                    value={DadosVenda.codBanco}
                    onChange={handleChangeSelect}
                    inputProps={{
                      name: 'codBanco',
                      id: 'uncontrolled-native',

                    }}
                  >
                    <option value={""}></option>
                    {bancos.map(item =>
                      <option value={item.codBanco}>{item.banco.toUpperCase()}</option>
                    )}
                  </NativeSelect>
                </FormControl>
              </Col>
            </Row>

            <Row start style={{ marginTop: 5 }}>
              <Col min='160px' max='160px'>
                <div className="inputNovo">
                  <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                    <TextField
                      InputLabelProps={{ shrink: DadosVenda.agencia !== "" ? true : false }}
                      className='inputNovo'
                      id="standard-required"
                      label="Agência nº *"
                      variant="standard"
                      name='agencia'
                      value={DadosVenda.agencia}
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </div>
              </Col>
            </Row>

            <Row start style={{ marginTop: 5 }}>
              <Col min='160px' max='260px'>
                <div className="inputNovo">
                  <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                    <TextField
                      InputLabelProps={{ shrink: DadosVenda.conta !== "" ? true : false }}
                      className='inputNovo'
                      id="standard-required"
                      label={DadosVenda.tipoConta === "ContaCorrente" ? "Conta Corrente *" : "Conta Poupança  *"}
                      variant="standard"
                      name="conta"
                      value={DadosVenda.conta}
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </div>
              </Col>
              <Col min='100px' max='100px'>
                <div className="inputNovo">
                  <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                    <TextField
                      InputLabelProps={{ shrink: DadosVenda.digito !== "" ? true : false }}
                      className='inputNovo'
                      id="standard-required"
                      label="Digito"
                      variant="standard"
                      name='digito'
                      value={DadosVenda.digito}
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </div>
              </Col>
            </Row>
          </> : <>
            <Row between style={{ marginTop: 15 }}>
              <Col>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={DadosVenda.tipoPix === "" ? false : true} >
                    Tipo  *
                  </InputLabel>
                  <NativeSelect
                    value={DadosVenda.tipoPix}
                    onChange={handleChangeSelectTipo}
                    inputProps={{
                      name: 'tipoPix',
                      id: 'uncontrolled-native',

                    }}
                  >
                    <option value={""}></option>
                    <option value="CPF">CPF</option>
                    <option value="email">E-mail</option>
                    <option value="telefone">Telefone</option>
                    <option value="chaveAleatoria">Chave Aleatória</option>
                  </NativeSelect>
                </FormControl>
              </Col>
              <Col>
                <div className="inputNovo">
                  <FormControl className="inputNovo" variant="standard" >
                    <TextField
                      InputLabelProps={{ shrink: DadosVenda.chavePix !== "" ? true : false }}
                      className='inputNovo'
                      id="standard-required"
                      label="ChavePix  *"
                      variant="standard"
                      name='chavePix'
                      value={DadosVenda.chavePix}
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </div>
              </Col>
            </Row>
          </>}
        </> : <></>}

        <Row end style={{ marginTop: 20 }}>
          <Col>
            <Form.Group className="mb-3 teste right" as={Col} controlId="formGridEmail">
              <div className="botaoPesquisar">
                <Button variant="primary" type="submit" className="btnPesquisar" onClick={passaFase4}
                  disabled={(DadosVenda.codBanco === 0 ||
                    DadosVenda.banco === "" ||
                    DadosVenda.agencia === "" ||
                    DadosVenda.conta === "" ||
                    DadosVenda.digito === "") && (DadosVenda.informaConta === "sim") &&
                    (
                      DadosVenda.tipoPix === "" ||
                      DadosVenda.chavePix === "")
                    ? true : false
                  }
                >
                  <Typography variant="button" gutterBottom> PRÓXIMO</Typography>
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </>
  )
}