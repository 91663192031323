import { InformationDashboard } from "../Informations_Dashboard";
import { Menu } from "../Menu";
import { Container } from "./styles";

export function Dashboard() {
    return (
       <Container>
           <>
           <div><Menu /></div>
           <div><InformationDashboard /></div>
           </>
           
           
       </Container>
    );
}