import styled from "styled-components";

export const Container = styled.div`

   //width: 1400px;
   margin: none;

   .labelCampo{
    font-size:12px;
    margin-bottom:8px;
   }

   .teste{
       align-items: right;
   }

   .labelBem{
     margin-bottom: 30px;
   }

   .botaoPesquisar{
     display: flex;
     //margin-right: 28px;
     float: right;
     margin-bottom: 30px;
   }

   .btnPesquisar1{
     color: var(--blue);
     background-color: transparent;
     font-size: 20px;
     border: none;
   }

   .btnPesquisar{
     background-color: var(--blue);
     width: 120px;
     height: 45px;
     font-size: 20px;
   }

   .inputNovo{
     width: 100%
   }

   .datePick{
        width: 100%;
        margin-top: 0px;
        margin-left: 7px;

        .MuiTextField-root{
            width: 100%;
            
        }

        .MuiFormLabel-filled{
            font-size: 16px;
            position: absolute;
            top: -5px;
            left: -12px;
        }

        .MuiInputLabel-outlined{
            font-size: 17px;
            position: absolute;
            top: 8px;
            left: -14px;
            
        }
        .MuiOutlinedInput-input{
            height: 24px;
            margin-top: 30px;
            padding: 0;
            padding-bottom: 3px;
        } 
        

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
          //height: 24px;
          //margin-top: 30px;
          //padding: 0;
          //padding-bottom: 3px;
        }

        .Mui-focused .MuiOutlinedInput-notchedOutline{
          border-width: 0;
          border-color: #1976d2;
          border-bottom-width: 2px;

        }

        fieldset{
            border-radius: 0;
            border-width: 0;
            border-bottom-width: 1px;
            border-color: rgba(0, 0, 0, 0.43);

            legend{
                
                span{
                    font-size: 16px;
                }
            }
        }
    }


`;

export const Tabela = styled.div`
  table{
      display: table;
      border: 0;
      box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;

  }

  td, th {
      border: 0;
  padding: .7em;
  
  text-align: center;
  display: table-cell;
    vertical-align: inherit;
    font-size: 13px;
  
}

.testea{
     display: flex;
     margin-top: 50px;
     justify-content: flex-end;
     margin-right: 10px;

   }

`;