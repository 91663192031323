import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas, Botao, PrimeiraLetra } from './styles';
import { Header } from '../../components/Header';
import { FormHandles } from '@unform/core';
import { FiPlus } from 'react-icons/fi';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Planoh } from '../../hooks/plano';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Planos } from '../../interfaces/planos'
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationPlano';
import Switch from '@mui/material/Switch';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';



const AutorizaCpfCnpj: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const idCargo = useRef(null);
  const { planosLista, ativosLista, getAll, getAllAtivos } = Planoh();
  const [checked, setChecked] = React.useState(false);
  const [listaSearchPlano, setListaSearchPlano] = useState<Planos[]>(planosLista);
  const [listaCargoAtivo, setListaCargoAtivo] = useState<Omit<Planos[], 'descricao'>>(ativosLista);
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchPlano.length / PER_PAGE);
  const _DATA = usePagination(listaSearchPlano, PER_PAGE);
  const [filtroDescricao, setFiltroDescricao] = useState<string>();
  const [filtroAtivo, setFiltroAtivo] = useState<string>('Todos');
  const [filtroTipoBem, setFiltroTipoBem] = useState<number>(0);
  const [filtroTipoVenda, setFiltroTipoVenda] = useState<number>();
  const [dataInicial, setDataInicial] = React.useState<Date | null>(null);
  const [dataFinal, setDataFinal] = React.useState<Date | null>(null);


  function handleSearchTodos() {

    let listaGeral: Planos[];

    if (filtroAtivo === 'Todos') {
      listaGeral = planosLista;
      setListaSearchPlano(listaGeral);
    } else {
      const lista: Planos[] = planosLista.filter(function (el) {
        return ativosLista.find(e => e.codigoGrupoBem === el.codigoGrupoBem && e.codigoTipoVenda === el.codigoTipoVenda && e.sequenciaAgrupamento === el.sequenciaAgrupamento);
      })
      listaGeral = lista
      setListaSearchPlano(listaGeral);

    };

    if (filtroDescricao != null || filtroDescricao != undefined) {
      const lista = listaGeral.filter(item => filtroDescricao ? (item.descricao + item.sequenciaAgrupamento.toString()).toLowerCase().includes(filtroDescricao.toLowerCase()) : item);
      listaGeral = lista
      setListaSearchPlano(listaGeral);
    }

    if (filtroTipoVenda != null || filtroTipoVenda != undefined) {
      if (filtroTipoVenda > -1) {
        const lista = listaGeral.filter(item => filtroTipoVenda?.toString() ? item.codigoTipoVenda.toString().includes(filtroTipoVenda.toString()) : item);
        listaGeral = lista
        setListaSearchPlano(listaGeral);
      }
    }

    if (filtroTipoBem != null || filtroTipoBem != undefined) {
      if (filtroTipoBem > 0) {
        const lista = listaGeral.filter(item => filtroTipoBem?.toString() ? item.codigoGrupoBem.toString().includes(filtroTipoBem.toString()) : item);
        console.log(filtroTipoBem)
        listaGeral = lista
        setListaSearchPlano(listaGeral);
      }
    }

  }

  const handleChangePagination = (event: object, e: number) => {
    setPage(e);
    _DATA.jump(e);
  };

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    setFiltroDescricao(event.target.value)
  }

  function handleSearchCodigoVenda(event: ChangeEvent<HTMLInputElement>) {
    setFiltroTipoVenda(parseInt(event.target.value))
  }

  function handleSearchAtivos(event: ChangeEvent<HTMLInputElement>) {
    setFiltroAtivo(event.target.value)
  }

  function handleChangeSelected(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroTipoBem(parseInt(e.target.value))
  }

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }


  async function handleSwitch(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked === true) {

      const plano = planosLista.find(item => item.id.toString() === event.target.id)

      if (plano != undefined) {
        try {

          const response = await api.post(`/v1/sequencias-agrupamento-interno`,
            {
              id: plano.id,
              sequenciaAgrupamento: plano.sequenciaAgrupamento,
              codigoGrupoBem: plano.codigoGrupoBem,
              codigoTipoVenda: plano.codigoTipoVenda,

            })

          getAllAtivos();

          if (response.status === 201) {
            addToast({
              type: 'success',
              title: 'Dados Salvos',
              description: 'Dados foram salvos com sucesso!'
            })
          } else {
            addToast({
              type: 'error',
              title: 'Erro ao tentar salvar!',
              description: 'Informe este erro ao TI do consórcio2!'
            })
          }
        } catch (error) {
          console.log(error)
          addToast({
            type: 'error',
            title: 'Erro ao tentar salvar!',
            description: 'Informe este erro ao TI do consórcio!'
          })
        }

      }
    } else {
      try {
        const idSA = planosLista.find(e => e.id.toString() === event.target.id)
        const idSAAtivo = ativosLista.find(e => e.codigoGrupoBem === idSA?.codigoGrupoBem && e.codigoTipoVenda === idSA.codigoTipoVenda && e.sequenciaAgrupamento === idSA.sequenciaAgrupamento);
        if (idSAAtivo != null) {
          const responseDel = await api.delete(`/v1/sequencias-agrupamento-interno/${idSAAtivo.id}`)
          console.log(responseDel)
          getAllAtivos();

          addToast({
            type: 'info',
            title: 'Deletado com sucesso!',
            description: 'Plano foi deletado com sucesso!'
          })
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao tentar salvar!',
            description: 'Informe este erro ao TI do consórcio!'
          })
        }

      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao tentar salvar!',
          description: 'Informe este erro ao TI do consórcio!'
        })
      }
    }
  }

  useEffect(() => {
    addToast({
      type: 'info',
      title: 'Carregando a Listagem',
      description: 'Por favor aguarde!'
    })
    getAll();
    getAllAtivos();
  }, [getAll, getAllAtivos])



  useEffect(() => {
    setListaSearchPlano(planosLista)
    setListaCargoAtivo(ativosLista)
  }, [planosLista, ativosLista])

  useEffect(() => {
    handleSearchTodos()
  }, [filtroAtivo, filtroDescricao, filtroTipoBem, filtroTipoVenda])

  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != undefined) {
      setDataInicial(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
    }
  };

  const handleChangeDateFinal = (newValue: Date | null) => {
    if (newValue != undefined) {
      setDataFinal(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
    }
  };


  return (
    <>
      <Header />
      <Container>
        <div className='title'><h2>Autoriza CPF/CNPJ</h2></div>
        <Tabelas>
          <Table bordered hover className='tabela'>
            <thead>
              <tr className='PrimeiroTH'>
                <th colSpan={6} className='PrimeiroTH'>
                  <Row className='PrimeiraLinha'>
                    <Col xs={6}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="tipoPessoaRadio"
                        onChange={handleSearchAtivos}
                        defaultValue="Todos"
                      >
                        <FormControlLabel value="Fisica" control={<Radio />} label="Fisica" />
                        <FormControlLabel value="Juridica" control={<Radio />} label="Juridica" />
                      </RadioGroup>
                    </Col>
                    <Col xs={6}>
                      <TextField className='pesquisa' variant='standard' type="number" placeholder="Digite CPF" onChange={handleSearchCodigoVenda} name="procurar" />
                    </Col>
                  </Row>



                </th>

              </tr>

              <tr>
                <th colSpan={6}>
                  <Row className='PrimeiraLinha1'>
                    <Col xs={4}>
                      <div className='datePick'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                          <DesktopDatePicker
                            label="Data Inicial"
                            inputFormat="dd/MM/yyyy"
                            value={dataInicial}
                            onChange={handleChangeDate}
                            renderInput={(params) => <TextField {...params}
                            />
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className='datePick'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                          <DesktopDatePicker
                            label="Data Final"
                            inputFormat="dd/MM/yyyy"
                            value={dataFinal}
                            onChange={handleChangeDateFinal}
                            renderInput={(params) => <TextField {...params}
                            />
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <NativeSelect
                        className="status"
                        defaultValue={30}
                        onChange={handleChangeSelected}
                        //value={model.usuario.id}
                        inputProps={{
                          name: 'age',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option id="0" value="0">TODOS</option>
                        <option id="0" value="0">EM ANÁLISE</option>
                        <option id="0" value="0">LIBERADO</option>
                        <option id="0" value="0">BLOQUEADO</option>
                      </NativeSelect>
                    </Col>
                  </Row>
                </th>
              </tr>
              <tr>
                <th colSpan={6}>
                  <Row className='PrimeiraLinha1'>
                    <Col className="botoesPesquisar">
                      <Form.Group className="buttonsCargo">
                        <Button className="botaoContrario">Limpar</Button>
                        <Button type="submit">Pesquisar</Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
          </Table>

          <Table bordered hover className='tabela'>
            <thead>
              <tr className='tituloTabela'>
                <th>CPF/CNPJ</th>
                <th>Solicitação</th>
                <th>Motivo</th>
                <th>Status</th>
                <th>Expiração</th>
                <th>Vendedor</th>
              </tr>
            </thead>
            <tbody className='teste'>
              <tr className='linha1'>
                <td>
                  345.103.09.56
                </td>
                <td>
                  17/05/2022
                </td>
                <td>
                  Renda Insuficiente
                </td>
                <td>
                  <NativeSelect
                    className="status1"
                    defaultValue={30}
                    onChange={handleChangeSelected}
                    //value={model.usuario.id}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option id="0" value="0">EM ANÁLISE</option>
                    <option id="0" value="0">LIBERADO</option>
                    <option id="0" value="0">BLOQUEADO</option>
                  </NativeSelect>
                </td>
                <td>
                  <div className='datePick1'>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DesktopDatePicker
                        label="Data Inicial"
                        inputFormat="dd/MM/yyyy"
                        value={dataInicial}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params}
                        />
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </td>
                <td>
                  ROGERIO
                </td>
              </tr>
              <tr className='linha1'>
                <td>
                  345.103.09.56
                </td>
                <td>
                  17/05/2022
                </td>
                <td>
                  Renda Insuficiente
                </td>
                <td>
                  <NativeSelect
                    className="status1"
                    defaultValue={30}
                    onChange={handleChangeSelected}
                    //value={model.usuario.id}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option id="0" value="0">EM ANÁLISE</option>
                    <option id="0" value="0">LIBERADO</option>
                    <option id="0" value="0">BLOQUEADO</option>
                  </NativeSelect>
                </td>
                <td>
                  <div className='datePick1'>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DesktopDatePicker
                        label="Data Inicial"
                        inputFormat="dd/MM/yyyy"
                        value={dataInicial}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params}
                        />
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </td>
                <td>
                  ROGERIO
                </td>
              </tr>
              <tr className='linha1'>
                <td>
                  345.103.09.56
                </td>
                <td>
                  17/05/2022
                </td>
                <td>
                  Renda Insuficiente
                </td>
                <td>
                  <NativeSelect
                    className="status1"
                    defaultValue={30}
                    onChange={handleChangeSelected}
                    //value={model.usuario.id}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option id="0" value="0">EM ANÁLISE</option>
                    <option id="0" value="0">LIBERADO</option>
                    <option id="0" value="0">BLOQUEADO</option>
                  </NativeSelect>
                </td>
                <td>
                  <div className='datePick1'>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DesktopDatePicker
                        label="Data Inicial"
                        inputFormat="dd/MM/yyyy"
                        value={dataInicial}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params}
                        />
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </td>
                <td>
                  ROGERIO
                </td>
              </tr>
              <tr className='linha1'>
                <td>
                  345.103.09.56
                </td>
                <td>
                  17/05/2022
                </td>
                <td>
                  Renda Insuficiente
                </td>
                <td>
                  <NativeSelect
                    className="status1"
                    defaultValue={30}
                    onChange={handleChangeSelected}
                    //value={model.usuario.id}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option id="0" value="0">EM ANÁLISE</option>
                    <option id="0" value="0">LIBERADO</option>
                    <option id="0" value="0">BLOQUEADO</option>
                  </NativeSelect>
                </td>
                <td>
                  <div className='datePick1'>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DesktopDatePicker
                        label="Data Inicial"
                        inputFormat="dd/MM/yyyy"
                        value={dataInicial}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params}
                        />
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </td>
                <td>
                  ROGERIO
                </td>
              </tr>
              <tr className='linha1'>
                <td>
                  345.103.09.56
                </td>
                <td>
                  17/05/2022
                </td>
                <td>
                  Renda Insuficiente
                </td>
                <td>
                  <NativeSelect
                    className="status1"
                    defaultValue={30}
                    onChange={handleChangeSelected}
                    //value={model.usuario.id}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option id="0" value="0">EM ANÁLISE</option>
                    <option id="0" value="0">LIBERADO</option>
                    <option id="0" value="0">BLOQUEADO</option>
                  </NativeSelect>
                </td>
                <td>
                  <div className='datePick1'>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DesktopDatePicker
                        label="Data Inicial"
                        inputFormat="dd/MM/yyyy"
                        value={dataInicial}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params}
                        />
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </td>
                <td>
                  ROGERIO
                </td>
              </tr>
              {/*{_DATA.currentData().map(function (item) {
                const response = ativosLista.find(e => e.codigoGrupoBem === item.codigoGrupoBem && e.codigoTipoVenda === item.codigoTipoVenda && e.sequenciaAgrupamento === item.sequenciaAgrupamento);
                let result = false
                if (response != undefined) {
                  result = true
                }
                return (
                  <tr className="linha">
                    <td className="id">{item.codigoTipoVenda}</td>
                    <td className="celula">

                      {item.sequenciaAgrupamento} - {item.descricao.toUpperCase()}
                    </td>
                    <td className="ativar">
                      <Switch
                        name={item.descricao}
                        onChange={handleSwitch}
                        size="small"
                        value={item.id}
                        id={item.id.toString()}
                        checked={result}
                      /></td>
                  </tr>
                )
              })}*/}
              <tr className="linha2">
                <td colSpan={6} className="pagination1" >
                  <Pagination style={{ alignItems: "center" }} count={count} page={page} onChange={handleChangePagination} size="large" />
                </td>
              </tr>
            </tbody>
          </Table>
        </Tabelas>


      </Container>
    </>
  )
}

export default AutorizaCpfCnpj