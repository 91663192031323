import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas } from './styles';
import { Header } from '../../../components/Header';
import { FiRotateCcw, FiArrowLeft } from 'react-icons/fi';
import { Table, Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../../components/PaginationUsuarios';
import Switch from '@mui/material/Switch';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Usuarios } from '../../../hooks/usuario';
import { ConsultaUsuario } from '../../../interfaces/usuarioConsulta';
import { TabelaUsuariosAtivos } from '../../../interfaces/tabelaUsuariosAtivos';



const Etapa4: React.FC = () => {
  const { tabela } = useParams() as any
  const { addToast } = useToast();
  const history = useHistory();
  const { usuariosLista, getAll, usuariosAtivosTabela, getAllUsuariosAtivos } = Usuarios();
  const [listaSearchPlano, setListaSearchPlano] = useState<ConsultaUsuario[]>(usuariosLista);
  const [listaUsuarioAtivo, setListaUsuarioAtivo] = useState<TabelaUsuariosAtivos[]>(usuariosAtivosTabela);
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchPlano.length / PER_PAGE);
  const _DATA = usePagination(listaSearchPlano, PER_PAGE);
  const [filtroDescricao, setFiltroDescricao] = useState<string>();
  const [filtroAtivo, setFiltroAtivo] = useState<string>('Todos');

  function incluirStatus(){
      usuariosLista?.forEach(function (item){
        const response = usuariosAtivosTabela.find(e => e.idDadoUsuario === item.dadoUsuario.id && e.tabelaVenda.id === parseInt(tabela));
        let result = false
        if (response != undefined) {
          result = true
        }
        item.ativo = result
        return item
        })
  }

  function handleSearchTodos() {

    let listaGeral: ConsultaUsuario[] = usuariosLista;




    if (filtroAtivo === 'Todos') {
      setListaSearchPlano(listaGeral);
    } else {
      const lista: ConsultaUsuario[] = listaGeral.filter(function (el) {
        return listaUsuarioAtivo.find(e => e.idDadoUsuario === el.dadoUsuario.id && e.tabelaVenda.id === parseInt(tabela));
      })
      listaGeral = lista
      setListaSearchPlano(listaGeral);

    };

    if (filtroDescricao != null || filtroDescricao != undefined) {
      const lista = listaGeral.filter(item => filtroDescricao ? ((item.dadoUsuario.nomePrincipal||"")+(item.dadoUsuario.nomeSecundario||"").toString()).toLowerCase().includes(filtroDescricao.toLowerCase()) : item);
      listaGeral = lista
      setListaSearchPlano(listaGeral);
    }


  }

  const handleChangePagination = (event: object, e: number) => {
    setPage(e);
    _DATA.jump(e);
  };

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    setFiltroDescricao(event.target.value)
  }

  function handleSearchAtivos(event: ChangeEvent<HTMLInputElement>) {
    setFiltroAtivo(event.target.value)
  }


  async function handleSwitch(event: ChangeEvent<HTMLInputElement>) {
    const index = listaSearchPlano.findIndex(item => item.id === parseInt(event.target.value))

    if (event.target.checked === true) {  
      listaSearchPlano[index].ativo = true;
      
      const user = usuariosAtivosTabela.find(e => e.idDadoUsuario.toString() === event.target.id && e.tabelaVenda.id === parseInt(tabela))

      if (user === undefined) {
        try {
          const response = await api.post(`/v1/tabelas-venda-usuario`,
            {
              dadoUsuario: {id: event.target.id},
              tabelaVenda: {id: parseInt(tabela)}
            })


          if (response.status === 201) {
            addToast({
              type: 'success',
              title: 'Dados Salvos',
              description: 'Vendedor inserido com sucesso!'
            })
          } else {
            addToast({
              type: 'error',
              title: 'Erro ao tentar salvar!',
              description: 'Informe este erro ao TI do consórcio!'
            })
          }
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao tentar salvar!',
            description: 'Informe este erro ao TI do consórcio!'
          })
        }

      }
    } else {
      try {
        listaSearchPlano[index].ativo = false;
        const idSA = usuariosLista.find(e => e.dadoUsuario.id.toString() === event.target.id)
        const idSAAtivo = listaUsuarioAtivo.find(e => e.idDadoUsuario === idSA?.dadoUsuario.id && e.tabelaVenda.id === parseInt(tabela));

        if (idSAAtivo != null) {
          const responseDel = await api.delete(`/v1/tabelas-venda-usuario/${idSAAtivo.id}`)
          console.log(responseDel)

          
          addToast({
            type: 'info',
            title: 'Deletado com sucesso!',
            description: 'Vendedor foi deletado com sucesso!'
          })
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao tentar salvar!',
            description: 'Informe este erro ao TI do consórcio!'
          })
        }

      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao tentar salvar!',
          description: 'Informe este erro ao TI do consórcio!'
        })
      }
    }
  }

  useEffect(() => {
    addToast({
      type: 'info',
      title: 'Carregando a Listagem',
      description: 'Por favor aguarde!'
    })
    getAll();
    getAllUsuariosAtivos();
  }, [])



  useEffect(() => {
    incluirStatus()
    setListaSearchPlano(usuariosLista)
    setListaUsuarioAtivo(usuariosAtivosTabela)
  }, [usuariosLista, usuariosAtivosTabela])

  useEffect(() => {
    handleSearchTodos()
  }, [filtroAtivo, filtroDescricao])

  function voltar() {
    history.goBack()
  }

  function atualizar(){
    history.go(0)
  }


  return (
    <>
      <Header />
      <Container>
        <div className='title'><FiArrowLeft onClick={voltar} className='voltar' /><h2 onClick={incluirStatus}>Inserir Vendedores</h2><FiRotateCcw onClick={atualizar} className='atualizar' /></div>
        <Tabelas>
          <Table bordered hover className='tabela'>
            <thead>
              <tr className='PrimeiroTH'>
                <th colSpan={3} className='PrimeiroTH'>
                  <Row className='PrimeiraLinha'>
                    <Col xs={6}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="tipoPessoaRadio"
                        onChange={handleSearchAtivos}
                        defaultValue="Todos"
                      >
                        <FormControlLabel value="Todos" control={<Radio />} label="Todos" />
                        <FormControlLabel value="Ativos" control={<Radio />} label="Ativos" />
                      </RadioGroup>
                    </Col>
                    <Col xs={6}>
                      <TextField className='pesquisa' variant='standard' type="text" placeholder="Nome Vendedor" onChange={handleSearch} name="procurar" />
                    </Col>
                  </Row>
                </th>

              </tr>
              <tr className='tituloTabela'>
                <th>Nome Vendedor</th>
                <th className="ativar">Status</th>
              </tr>
            </thead>
            <tbody className='teste'>
            {_DATA.currentData().map(function (item) {


              return(
                <tr className="linha">
                  <td className="celula">

                  {item?.dadoUsuario.nomePrincipal?.toUpperCase()} {item.dadoUsuario.nomeSecundario?.toUpperCase()}
</td>
<td className="ativar">
                      <Switch
                        name={item.dadoUsuario.nomePrincipal}
                        onChange={handleSwitch}
                        size="small"
                        value={item.id}
                        id={item.dadoUsuario.id.toString()}
                        checked={item.ativo}
                      /></td>
                </tr>
              )
            }

              /*
                  <tr className="linha">
                    <td className="id">{item.dadoUsuario.nomePrincipal}</td>
                    <td className="celula">

                      {item.dadoUsuario.nomePrincipal} - {item.dadoUsuario.nomeSecundario.toUpperCase()}
                    </td>
                    <td className="ativar">
                      <Switch
                        name={item.dadoUsuario.nomePrincipal}
                        onChange={handleSwitch}
                        size="small"
                        value={item.dadoUsuario.id}
                        id={item.dadoUsuario.id.toString()}
                        checked={result}
                      /></td>
                  </tr>
                )
              })*/)}
              <tr className='linha2'>
                <td className="pagination">
                  <Pagination style={{ alignItems: "center" }} count={count} page={page} onChange={handleChangePagination} size="large" />
                </td>
              </tr>
            </tbody>
          </Table>
        </Tabelas>


      </Container>
    </>
  )
}

export default Etapa4