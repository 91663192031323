import api from './api';
import { Seguradoras } from '../interfaces/seguradoras';

const getAll = () => api.get<Seguradoras[]>('/v1/seguradoras')

//const getAllGruposSede = () => api.get<GrupoSede[]>('/v1/grupos-sede')

//const createGrupoUsuarios = (grupoUsuarios: Pick<Grupod, 'nome' | 'listMenuGrupoUsuario'>) => api.post('/v1/grupos-usuario', grupoUsuarios)

//const alteraGrupoUsuarios = (grupoUsuarios: GrupoUsuarios) => api.put(`/v1/grupos-usuario/`, grupoUsuarios)

//const deleteGrupoUsuarios = (id: number) => api.delete(`/v1/grupos-usuario/${id}`)

export const GetSeguradoras = {
    getAll,
    //getAllGruposSede,
    //createGrupoUsuarios,
    //alteraGrupoUsuarios,
    //deleteGrupoUsuarios,
}