import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErros, {} from '../../utils/getValidationErros';
import { Link } from 'react-router-dom';

import { FiMail, FiArrowLeft} from 'react-icons/fi';
import { Container, Content } from './styles';
import logo from '../../assets/logo.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';

const RecoveryPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async(data: object) => {
     try {
       const schema = Yup.object().shape({
         usuario: Yup.string().required('E-mail obrigatório2').email('Digite um email válido')
       })

       await schema.validate(data, {
         abortEarly: false,
       })

     } catch (err) {
       console.log(err);
       
       //const errors = getValidationErros(err);

       formRef.current?.setErrors({
        usuario: 'E-mail Obrigatório',
      })
     }
  }, []);

  return (
    <>
    <Container>
        <Content>
          <img src={logo} alt='GScon' />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1> Recupere sua senha </h1>
 
            <Input 
              name="usuario" 
              icon={FiMail} 
              placeholder="E-mail" />
            <Button type="submit">Enviar</Button>
            <Link to="/"><FiArrowLeft />Voltar para Login</Link>
          </Form>
        </Content>
    </Container>
    </>
  )
};

export default RecoveryPassword;