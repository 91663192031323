import { useCallback, useState } from "react"
import { DadosPreVenda } from '../interfaces/dadosPreVenda';
import { dadosPreVenda } from '../mock/dadosPreVenda';


const DadosPreVendaTodos = () => {
    const [dadosVenda, setDadosVenda] = useState<DadosPreVenda>(dadosPreVenda);

    function alteraDados(campo:string, valor:any){
        if(campo !== undefined && valor !== undefined){
        setDadosVenda({...dadosVenda,
           [campo] : valor
        })}
    }
    
    return {
        dadosVenda,
        setDadosVenda,
        alteraDados,
    }
}

export { DadosPreVendaTodos }