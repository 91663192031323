import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas, Botao, PrimeiraLetra } from './styles';
import { Header } from '../../components/Header';
import { FiPlus } from 'react-icons/fi';
import { Table, Button, Modal } from 'react-bootstrap';
import { UsuariosLogin } from '../../hooks/usuarioSistema';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import { UsuarioSistemaInterface } from '../../interfaces/usuarioSistema';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationGrupoUsuarios';


const UsuarioSistema: React.FC = () => {
  const history = useHistory();
  const idCargo = useRef(null);
  const { usuariosLista, getAll } = UsuariosLogin();
  const [checked, setChecked] = React.useState(false);
  const [listaSearchUsuarioLista, setListaSearchUsuarioLista] = useState<Array<UsuarioSistemaInterface>>(usuariosLista);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchUsuarioLista.length / PER_PAGE);
  const _DATA = usePagination(listaSearchUsuarioLista, PER_PAGE);


  const handleChangePagination = (event: object, e: number) => {
    setPage(e);
    _DATA.jump(e);
  };

  const handleChange1 = () => {
    setChecked(!checked);
  }

  function editCargo(id: number) {
    history.push(`/usuariosSistemaForm/${id}`)
  }

  function novoCargo() {
    history.push(`/usuariosSistemaForm/`)
  }

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    const lista: UsuarioSistemaInterface[] = usuariosLista.filter(item => event.target.value ? item.nome.toLowerCase().includes(event.target.value.toLowerCase()) : item);
    setListaSearchUsuarioLista(lista);

    setPage(1)
    _DATA.jump(1);
  }

  useEffect(() => {
    getAll();
  }, [getAll])

  useEffect(() => {
    setListaSearchUsuarioLista(usuariosLista)
  }, [usuariosLista])

  function randomDarkRGB() {
    var r = Math.floor(Math.random() * 156);
    var g = Math.floor(Math.random() * 156);
    var b = Math.floor(Math.random() * 156);
    var bgColor = "rgb(" + r + "," + g + "," + b + ")";
    return bgColor;
  }

  return (
    <>
      <Header />
      <Container>
        <div className='title'><h2>Usuários do Sistema</h2></div>
        <Tabelas>
          <Table bordered hover className='tabela'>
            <thead>
              <tr>
                <th><TextField className='pesquisa' variant='standard' type="text" placeholder="Pesquisar" onChange={handleSearch} /></th>
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map((item, index) =>
                <tr key={index} className="linha">
                  <td
                    className="celula"
                    key={item.id}
                    ref={idCargo}
                    id={item.id?.toString()}
                    onClick={() => editCargo(item.id || 0)}>
                    <PrimeiraLetra style={{ backgroundColor: `${randomDarkRGB()}` }}>
                      {item.nome.substring(0, 1).toUpperCase()}
                    </PrimeiraLetra>
                    {item.nome.toUpperCase()}
                  </td>
                </tr>


              )}

              <td className='pagination'>
                <Pagination count={count} page={page} onChange={handleChangePagination} size="large" />
              </td>
            </tbody>
          </Table>

          <Botao>
            <Button variant="primary" onClick={novoCargo} style={{ background: 'var(--blue)', border: 'none', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems: "center" }}><FiPlus size={30} /></Button>
          </Botao>
        </Tabelas>


      </Container>
    </>
  )
}

export default UsuarioSistema