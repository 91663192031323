import React, { ChangeEvent, useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { Container } from './styles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from '../../../services/api';
import FormControl from '@mui/material/FormControl';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../hooks/toast';
import { Contato } from '../../../interfaces/contato';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import { BiPhone } from 'react-icons/bi';
import { AiOutlineMail } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Parametros } from '../../../interfaces/parametros';
import cep from 'cep-promise';
import { cores } from '../../../mock/cores';
import { Col, Row } from "../styles";


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const FIXO = {
  telefone: 'Telefone Residencial',
  mascara: '(99) 9999-9999',
}

const WHATSAPP = {
  telefone: 'WhatsApp',
  mascara: '(99) 99999-9999'
}

const CELULAR = {
  telefone: 'Celular',
  mascara: '(99) 99999-9999',
}



export function Etapa4DadosSocioPJ(props: any) {

  const { addToast } = useToast();
  const { DadosVenda, setAlteraDados, setAlteraPagina } = props
  const [contatosBanco, setContatosBanco] = useState<Parametros[]>();
  const [contact, setContact] = useState<Omit<Contato, "id" | "dadoUsuario">[]>([])
  const [campoEmail, setCampoEmail] = React.useState({ tipoContato: "EMAIL", valor: '' })
  const [campoFone, setCamposFone] = React.useState(CELULAR)
  const [aut, setAut] = useState(false)


  const [modelContato, setModelContato] = useState<Omit<Contato, "id" | "dadoUsuario">>({
    tipoContato: 'CELULAR',
    valor: '',
  })


  const pessoaJuridica = {
    documentoPrincipal: 'CNPJ',
    mascaraPessoa: '99.999.999/9999-99',
  }

  const [camposPessoa, setCamposPessoa] = React.useState(pessoaJuridica);
  const [age, setAge] = React.useState<Date | null>(null);
  const [model, setModel] = useState({
    data: ''
  })

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != undefined) {
      setAge(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
      let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      setAlteraDados({ ...DadosVenda, age3: newValue, dataNascimentoSocio: newValue, dataNascimentoF: DataFormatada1 })
    }
  };

  /*function formatData(x: string){
    let dataAss = new Date(x)
    let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth()+ 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }*/

  async function inserirContatoTelefone() {
    if (modelContato.valor.length < 13) {
      addToast({
        type: 'info',
        title: 'Digite Telefone Válido',
        description: 'Digite um telefone Válido!'
      })
      return
    }

    let tel = ""

    if (DadosVenda.telefoneCom1 === "") {
      tel = `telefoneCom1`;
    } else if (DadosVenda.telefoneCom2 === "") {
      tel = `telefoneCom2`;
    } else if (DadosVenda.telefoneCom3 === "") {
      tel = 'telefoneCom3'
    } else {
      tel = 'telefoneCom4'
    }


    if (DadosVenda.idTelefoneCom >= 5) {
      addToast({
        type: 'info',
        title: 'Limite Máximo de Telefones',
        description: 'O limite máximo de telefones é 4!'
      })
      return
    }

    let valor = modelContato.valor
    if (modelContato.valor.length === 13) {
      const parte1 = modelContato.valor.slice(0, 9)
      const parte2 = modelContato.valor.slice(9, 13)
      console.log(parte1)
      console.log(parte2)
      valor = `${parte1}-${parte2}`
    } else {
      const parte1 = modelContato.valor.slice(0, 10)
      const parte2 = modelContato.valor.slice(10, 14)
      valor = `${parte1}-${parte2}`
    }
    console.log(modelContato.valor.length)

    setAlteraDados({ ...DadosVenda, [tel]: valor, idTelefoneCom: DadosVenda.idTelefoneCom + 1 })




    setModelContato({
      ...modelContato,
      valor: ''
    })
  }

  async function inserirContatoEmail() {
    let re = /\S+@\S+\.\S+/;
    let valido = re.test(campoEmail.valor);
    if (!valido) {
      addToast({
        type: 'info',
        title: 'Digite E-mail Válido',
        description: 'Digite um e-mail Válido!'
      })
      return
    }

    let email = "";

    if (DadosVenda.emailCom1 === "") {
      email = `emailCom1`;
    } else if (DadosVenda.emailCom2 === "") {
      email = `emailCom2`;
    } else if (DadosVenda.emailCom3 === "") {
      email = 'emailCom3'
    } else {
      email = 'emailCom4'
    }

    if (DadosVenda.idEmailCom >= 5) {
      addToast({
        type: 'info',
        title: 'Limite Máximo de Emails',
        description: 'O limite máximo de emails é 4!'
      })
      return
    }

    setAlteraDados({ ...DadosVenda, [email]: campoEmail.valor, idEmailCom: DadosVenda.idEmailCom + 1 })

    setCampoEmail({ ...campoEmail, valor: '' })


  }

  function randomDarkRGB() {
    var r = Math.floor(Math.random() * 156);
    var g = Math.floor(Math.random() * 156);
    var b = Math.floor(Math.random() * 156);
    var bgColor = "rgb(" + r + "," + g + "," + b + ")";
    return bgColor;
  }

  async function excluirEmail(dado: string) {
    setAlteraDados({ ...DadosVenda, [dado]: "", idEmailCom: DadosVenda.idEmailCom - 1 })
  }

  async function excluirFone(dado: string) {
    setAlteraDados({ ...DadosVenda, [dado]: "", idTelefoneCom: DadosVenda.idTelefoneCom - 1 })
  }


  async function buscaCep(x: string) {
    if (x.length === 8) {
      try {
        let resultado = await cep(x)
        console.log(resultado)
        if (resultado.street) {
          setAlteraDados({
            ...DadosVenda,
            cepCom: x,
            enderecoCom: resultado.street.toUpperCase(),
            bairroCom: resultado.neighborhood.toUpperCase(),
            cidadeCom: resultado.city.toUpperCase(),
            estadoCom: resultado.state.toUpperCase(),
          })
        } else {
          addToast({
            type: 'info',
            title: 'Cep não encontrato!',
            description: 'Não encontramos este cep na base de dados!'
          });
          setAlteraDados({
            ...DadosVenda,
            cepCom: x,
            enderecoCom: "",
            bairroCom: "",
            cidadeCom: "",
            estadoCom: "",
          })
        }
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Cep não encontrato!',
          description: 'Não encontramos este cep na base de dados!'
        });
        setAlteraDados({
          ...DadosVenda,
          cepCom: x,
          enderecoCom: "",
          bairroCom: "",
          cidadeCom: "",
          estadoCom: "",
        })
      }
    }
  }

  function handleChangeSelectContato(e: ChangeEvent<HTMLSelectElement>) {

    setModelContato({
      ...modelContato,
      [e.target.name]: e.target.value
    })

    if (e.target.value === 'FIXO') {
      setCamposFone(FIXO)
    } else if (e.target.value === 'WHATSAPP') {
      setCamposFone(WHATSAPP)
    } else {
      setCamposFone(CELULAR)
    }
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let resultado = e.target.value

    if (e.target.name === "documentoSocio") {
      resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
      if (resultado.length >= 11) {
        console.log('chegou aqui')
        const result = TestaCPF(resultado)
        if (!result) {
          addToast({
            type: 'error',
            title: 'CPF Inválido',
            description: 'Por favor insira um CPF Válido!'
          });
          return;
        } else {
          setAlteraDados({ ...DadosVenda, [e.target.name]: resultado })
        }
      } else {
        setAlteraDados({ ...DadosVenda, [e.target.name]: resultado })
      }
    }


    if (e.target.name === "cepCom") {
      resultado = e.target.value.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "");
      buscaCep(resultado)
    }

    setAlteraDados({ ...DadosVenda, [e.target.name]: resultado.toUpperCase() })


  };

  async function handleChangeInputContato(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;

    resultado = resultado.replaceAll("_", "").replaceAll(".", "");
    setModelContato({
      ...modelContato,
      [e.target.name]: resultado
    });
  }

  async function handleChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;
    console.log(resultado)
    setCampoEmail({
      tipoContato: "EMAIL", valor: resultado
    });
  }

  function enderecoCobranca(e: ChangeEvent<HTMLInputElement>) {
    if (DadosVenda.enderecoCobranca === "RES") {
      setAlteraDados({ ...DadosVenda, enderecoCobranca: "COM" })
    } else {
      setAlteraDados({ ...DadosVenda, enderecoCobranca: "RES" })
    }
  }

  function autonomo(e: ChangeEvent<HTMLInputElement>) {
    if (aut) {
      setAut(false)
    } else {
      setAut(true)
      setAlteraDados({
        ...DadosVenda,
        nomeEmpresa: "",
        cepCom: "",
        enderecoCom: "",
        numeroCom: "",
        bairroCom: "",
        aptoCom: "",
        complementoCom: "",
        estadoCom: "",
        cidadeCom: "",
        idTelefoneCom: 1,
        telefoneCom1: "",
        telefoneCom2: "",
        telefoneCom3: "",
        telefoneCom4: "",
        idEmailCom: 1,
        emailCom1: "",
        emailCom2: "",
        emailCom3: "",
        emailCom4: "",
        enderecoCobranca: "RES",
      })
    }
  }

  const copiarEndereco = () => {
    setAlteraDados({
      ...DadosVenda,
      cepCom: DadosVenda.cep,
      enderecoCom: DadosVenda.endereco,
      numeroCom: DadosVenda.numero,
      bairroCom: DadosVenda.bairro,
      aptoCom: DadosVenda.apto,
      complementoCom: DadosVenda.complemento,
      estadoCom: DadosVenda.uf,
      cidadeCom: DadosVenda.cidade,
    })
  }

  async function passaFase4() {
    if (DadosVenda.codB === 0) {
      addToast({
        type: 'error',
        title: 'Erro bem selecionado',
        description: 'Ao retornar a primeira pagina, por favor selecione o bem novamente!'
      });
      setAlteraPagina(0)
    }
    const tudo = JSON.stringify(DadosVenda)
    const teste = JSON.parse(tudo)
    if (DadosVenda?.id > 0) {
      const response = await api.put(`v1/pre-vendas/${DadosVenda.id}`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 4,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        status: "PENDENTE",
        posVenda: "PENDENTE",

      })

      console.log(response.status)

      if (response.status === 200) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa5: false,

        })
      }

      setAlteraPagina(4)
    } else {
      const response = await api.post(`v1/pre-vendas`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 4,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        status: "PENDENTE",
        posVenda: "PENDENTE",

      })

      if (response.status === 201) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa5: false,

        })

        setAlteraPagina(4)
      }
    }


  }

  function TestaCPF(strCPF: string) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }




  return (
    <>
      <Container>
        <Row>
          <Col style={{ color: "#5f5f61" }}>
            Dados Sócio Majoritário
          </Col>
          <Col>
            <div className="botaoPesquisar1 botaoPesquisar" style={{ marginTop: 10 }}>
              <Button variant="primary" type="submit" className="btnPesquisar1" onClick={copiarEndereco}>
                <Typography variant="button" gutterBottom><b> COPIAR ENDEREÇO</b></Typography>
              </Button>
            </div>
          </Col>
        </Row>

        <Row between>
          <Col xs={12} sm={3}>
            <FormControl className="inputNovo" variant="standard" >
              <InputMask
                mask='999.999.999-99'
                onChange={handleChangeInput}
                value={DadosVenda.orgEm}
              >
                {() =>
                  <TextField
                    className='inputNovo'
                    required
                    id={DadosVenda.orgEm}
                    label='CPF'
                    variant="standard"
                    name='orgEm'
                  />
                }
              </InputMask>
            </FormControl>
          </Col>

          <Col xs={12} sm={6}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard">
                <TextField
                  required
                  className='inputNovo'
                  id="standard-required"
                  inputProps={{ maxlength: 50 }}
                  label="Nome"
                  variant="standard"
                  name='nomeEmpresa'
                  value={DadosVenda.nomeEmpresa}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col xs={12} sm={3}>
            <div className='datePick'>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DesktopDatePicker
                  label="Data de Nascimento"
                  inputFormat="dd/MM/yyyy"
                  value={DadosVenda.age3}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params}
                  />
                  }
                />
              </LocalizationProvider>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={2}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <InputMask
                  mask="99.999-999"
                  onChange={handleChangeInput}
                  value={DadosVenda.cepCom}
                >
                  {() =>
                    <TextField
                      className='inputNovo'
                      required
                      id="standard-required"
                      label="CEP"
                      variant="standard"
                      name='cepCom'
                    />
                  }
                </InputMask>
              </FormControl>
            </div>
          </Col>
          <Col  xs={12} sm={5}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  InputLabelProps={{ shrink: DadosVenda.enderecoCom.length > 0 ? true : false }}
                  required
                  className='inputNovo'
                  id="standard-required"
                  label="Endereço (Rua, Av, Praça)"
                  inputProps={{ maxlength: 64 }}
                  variant="standard"
                  name='enderecoCom'
                  value={DadosVenda.enderecoCom}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col xs={3} sm={2}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  required
                  className='inputNovo'
                  id="standard-required"
                  label="Número"
                  inputProps={{ maxlength: 6 }}
                  variant="standard"
                  name='numeroCom'
                  value={DadosVenda.numeroCom}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col xs={9} sm={3}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  InputLabelProps={{ shrink: DadosVenda.bairroCom.length > 0 ? true : false }}
                  required
                  className='inputNovo'
                  id="standard-required"
                  inputProps={{ maxlength: 36 }}
                  label="Bairro"
                  variant="standard"
                  name='bairroCom'
                  value={DadosVenda.bairroCom}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={3} sm={2}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  className='inputNovo'
                  id="standard-required"
                  label="Apto"
                  inputProps={{ maxlength: 6 }}
                  variant="standard"
                  name='aptoCom'
                  value={DadosVenda.aptoCom}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col xs={9} sm={3}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  className='inputNovo'
                  id="standard-required"
                  label="Complemento"
                  variant="standard"
                  name='complementoCom'
                  value={DadosVenda.complementoCom}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col  xs={3} sm={1}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  InputLabelProps={{ shrink: DadosVenda.estadoCom.length > 0 ? true : false }}
                  required
                  className='inputNovo'
                  id="standard-required"
                  label="Estado"
                  inputProps={{ maxlength: 2 }}
                  variant="standard"
                  name='estadoCom'
                  value={DadosVenda.estadoCom}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col  xs={9} sm={4}>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  InputLabelProps={{ shrink: DadosVenda.cidadeCom.length > 0 ? true : false }}
                  required
                  className='inputNovo'
                  id="standard-required"
                  label="Cidade"
                  inputProps={{ maxlength: 40 }}
                  variant="standard"
                  name='cidadeCom'
                  value={DadosVenda.cidadeCom}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col xs={12} sm={2} className="d-flex justify-content-end">
            <FormControlLabel style={{ marginTop: "26px" }} control={<Checkbox onChange={enderecoCobranca} checked={DadosVenda.enderecoCobranca === "COM" ? true : false} sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="End. cob." />
          </Col>
        </Row>

        <Row between>
        <Row start nowrap  style={{ maxWidth: 520, minWidth: 180 }}>
          <Col min='180px'>
            <FormControl className="inputNovo" variant="standard" sx={{ width: '25ch' }}>
              <InputMask
                mask="(99) 999999999"
                onChange={handleChangeInputContato}
                value={modelContato.valor}
              >
                {() =>
                  <TextField
                    className='inputNovo'
                    id="standard-required"
                    label="Telefone"
                    variant="standard"
                    name="valor"
                  />
                }
              </InputMask>
            </FormControl>
          </Col>
          <Col min='40px' max='60px'>
            <div style={{ backgroundColor: "rgb(19,52,131)", height: "30px", width: "30px", marginTop: "18px", cursor: "pointer" }} onClick={inserirContatoTelefone}>
              <FiPlus color="#fff" style={{ backgroundColor: "rgb(19,52,131)", marginTop: "5px", marginLeft: "5px" }} size={20} />
            </div>
          </Col>
        </Row>

       
            <Row start nowrap  style={{ maxWidth: 520, minWidth: 180 }}>
          <Col min='180px'>
            <FormControl className="inputNovo" variant="standard">
              <TextField
                className='inputNovo'
                id="standard-required"
                label="E-mail"
                variant="standard"
                name="EMAIL"
                type="email"
                value={campoEmail.valor}
                onChange={handleChangeEmail}
              />
            </FormControl>
          </Col>
          <Col min='40px' max='60px'>
            <div style={{ backgroundColor: "rgb(19,52,131)", height: "30px", width: "30px", marginTop: "18px", cursor: "pointer" }} onClick={inserirContatoEmail}>
              <FiPlus color="#fff" style={{ backgroundColor: "rgb(19,52,131)", marginTop: "5px", marginLeft: "5px" }} size={20} />
            </div>
          </Col>
        </Row>
        </Row>

        <Row>
          <Col xs={12} sm={6}>
            {DadosVenda.telefoneCom1 !== "" ?
              <Card style={{ marginTop: "10px" }}>
                <LightTooltip title={DadosVenda.telefoneCom1}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: cores.telefone1 }} aria-label="recipe">
                        <BiPhone></BiPhone>
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={() => excluirFone("telefoneCom1")}>
                        <FiXCircle />
                      </IconButton>
                    }
                    title={DadosVenda.telefoneCom1}
                  />
                </LightTooltip>
              </Card> : <></>
            }

            {DadosVenda.telefoneCom2 !== "" ?
              <Card style={{ marginTop: "10px" }}>
                <LightTooltip title={DadosVenda.telefoneCom2}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: cores.telefone2 }} aria-label="recipe">
                        <BiPhone></BiPhone>

                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={() => excluirFone("telefoneCom2")}>
                        <FiXCircle />
                      </IconButton>
                    }
                    title={DadosVenda.telefoneCom2}
                  />
                </LightTooltip>
              </Card> : <></>
            }

            {DadosVenda.telefoneCom3 !== "" ?
              <Card style={{ marginTop: "10px" }}>
                <LightTooltip title={DadosVenda.telefoneCom3}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: cores.telefone3 }} aria-label="recipe">
                        <BiPhone />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={() => excluirFone("telefoneCom3")}>
                        <FiXCircle />
                      </IconButton>
                    }
                    title={DadosVenda.telefoneCom3}
                  />
                </LightTooltip>
              </Card> : <></>
            }

            {DadosVenda.telefoneCom4 !== "" ?
              <Card style={{ marginTop: "10px" }}>
                <LightTooltip title={DadosVenda.telefoneCom4}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: cores.telefone4 }} aria-label="recipe">
                        <BiPhone />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={() => excluirFone("telefoneCom4")}>
                        <FiXCircle />
                      </IconButton>
                    }
                    title={DadosVenda.telefoneCom4}
                  />
                </LightTooltip>
              </Card> : <></>
            }
          </Col>
          <Col xs={12} sm={6}>
            {DadosVenda.emailCom1 !== "" ?
              <Card style={{ marginTop: "10px" }}>
                <LightTooltip title={DadosVenda.emailCom1}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: cores.email1 }} aria-label="recipe">
                        <AiOutlineMail />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={() => excluirEmail("emailCom1")}>
                        <FiXCircle />
                      </IconButton>
                    }
                    title={DadosVenda.emailCom1}
                  />
                </LightTooltip>
              </Card> : <></>
            }

            {DadosVenda.emailCom2 !== "" ?
              <Card style={{ marginTop: "10px" }}>
                <LightTooltip title={DadosVenda.emailCom1}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: cores.email2 }} aria-label="recipe">
                        <AiOutlineMail />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={() => excluirEmail("emailCom2")}>
                        <FiXCircle />
                      </IconButton>
                    }
                    title={DadosVenda.emailCom2}
                  />
                </LightTooltip>
              </Card> : <></>
            }

            {DadosVenda.emailCom3 !== "" ?
              <Card style={{ marginTop: "10px" }}>
                <LightTooltip title={DadosVenda.emailCom3}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: cores.email3 }} aria-label="recipe">
                        <AiOutlineMail />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={() => excluirEmail("emailCom3")}>
                        <FiXCircle />
                      </IconButton>
                    }
                    title={DadosVenda.emailCom3}
                  />
                </LightTooltip>
              </Card> : <></>
            }

            {DadosVenda.emailCom4 !== "" ?
              <Card style={{ marginTop: "10px" }}>
                <LightTooltip title={DadosVenda.emailCom4}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: cores.email4 }} aria-label="recipe">
                        <AiOutlineMail />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={() => excluirEmail("emailCom4")}>
                        <FiXCircle />
                      </IconButton>
                    }
                    title={DadosVenda.emailCom4}
                  />
                </LightTooltip>
              </Card> : <></>
            }
          </Col>
        </Row>

        <Row end style={{ marginTop: 35 }}>
          <Col min='180px' max='320px'>
            <Form.Group className="mb-3 teste right" as={Col} controlId="formGridEmail">
              <div className="botaoPesquisar">
                <Button variant="primary" type="submit" className="btnPesquisar" onClick={passaFase4}
                  disabled={(DadosVenda.nomeEmpresa === "" ||
                    DadosVenda.orgEm === "" ||
                    DadosVenda.dataNascimentoF === "" ||
                    DadosVenda.numeroCom === "" ||
                    DadosVenda.bairroCom === "" ||
                    DadosVenda.estadoCom === "" ||
                    DadosVenda.cidadeCom === "") ||
                    (DadosVenda.telefoneCom1 === "" && DadosVenda.telefoneCom2 === "" && DadosVenda.telefoneCom3 === "" && DadosVenda.telefoneCom4 === "") ||
                    (DadosVenda.emailCom1 === "" && DadosVenda.emailCom2 === "" && DadosVenda.emailCom3 === "" && DadosVenda.emailCom4 === "")
                    ? true : false
                  }
                >
                  <Typography variant="button" gutterBottom> PRÓXIMO</Typography>
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </>
  )
}