import {Container} from './styles';
import { Dashboard } from "../Dashboard";
import { Header } from "../Header";
import { Footer } from "../Footer"
import { MenuSideBar } from '../MenuSideBar';

export function Principal() {
   return (
     <>
       <Container>
          <Header />
          <Dashboard />
          <Footer />
       </Container>
     </>
   );
}