export const templates = [
  {
    id: "NDc1NTA=",
    name: "Contrato",
    type: "html",
    variables: [
      "numero"
    ]
  },
  {
    id: "NTY1ODk=",
    name: "CG_PJ_RED.docx",
    type: "word"
  },
  {
    id: "NTY1OTI=",
    name: "CG_PJ_RED_TER.docx",
    type: "word"
  },
  {
    id: "NTY1OTM=",
    name: "CG_PJ_RED_TER_REC.docx",
    type: "word"
  },
  {
    id: "NTY1OTQ=",
    name: "CG_PJ_SEG.docx",
    type: "word"
  },
  {
    id: "NTY1OTU=",
    name: "CG_PJ_SEG_REC.docx",
    type: "word"
  },
  {
    id: "NTY1OTY=",
    name: "CG_PJ_SEG_RED.docx",
    type: "word"
  },
  {
    id: "NTY1OTc=",
    name: "CG_PJ_SEG_RED_REC.docx",
    type: "word"
  },
  {
    id: "NTY1OTg=",
    name: "CG_PJ_SEG_RED_TER.docx",
    type: "word"
  },
  {
    id: "NTY1OTk=",
    name: "CG_PJ_SEG_RED_TER_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MDA=",
    name: "CG_PJ_SEG_TER.docx",
    type: "word"
  },
  {
    id: "NTY2MDE=",
    name: "CG_PJ_SEG_TER_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MDI=",
    name: "CG_PJ_TER.docx",
    type: "word"
  },
  {
    id: "NTY2MDM=",
    name: "CG_PJ_TER_REC.docx",
    type: "word"
  },
  {
    id: "NTY1ODc=",
    name: "CG_PJ.docx",
    type: "word"
  },
  {
    id: "NTY1OTA=",
    name: "CG_PJ_RED_REC.docx",
    type: "word"
  },
  {
    id: "NTY1ODg=",
    name: "CG_PJ_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MDQ=",
    name: "CG_PF.docx",
    type: "word"
  },
  {
    id: "NTY2MDU=",
    name: "CG_PF_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MDY=",
    name: "CG_PF_RED.docx",
    type: "word"
  },
  {
    id: "NTY2MDc=",
    name: "CG_PF_RED_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MDk=",
    name: "CG_PF_RED_TER_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MTI=",
    name: "CG_PF_SEG_RED.docx",
    type: "word"
  },
  {
    id: "NTY2MTM=",
    name: "CG_PF_SEG_RED_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MTQ=",
    name: "CG_PF_SEG_RED_TER.docx",
    type: "word"
  },
  {
    id: "NTY2MTU=",
    name: "CG_PF_SEG_RED_TER_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MTk=",
    name: "CG_PF_TER.docx",
    type: "word"
  },
  {
    id: "NTY2MTg=",
    name: "CG_PF_TER_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MTE=",
    name: "CG_PF_SEG_REC.docx",
    type: "word"
  },
  {
    id: "NTY2MTY=",
    name: "CG_PF_SEG_TER.docx",
    type: "word"
  },
  {
    id: "NTY2MTc=",
    name: "CG_PF_SEG_TER_REC.docx",
    type: "word"
  },
  {
    id: "NTY2ODQ=",
    name: "CG_PF_SEG.docx",
    type: "word"
  },
  {
    id: "NTY2MDg=",
    name: "CG_PF_RED_TER.docx",
    type: "word"
  },
  {
    id: "NTY2NDE=",
    name: "CG_PJ_TER_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NDA=",
    name: "CG_PJ_TER_AM.docx",
    type: "word"
  },
  {
    id: "NTY2Mzk=",
    name: "CG_PJ_SEG_TER_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2Mzg=",
    name: "CG_PJ_SEG_TER_AM.docx",
    type: "word"
  },
  {
    id: "NTY2Mzc=",
    name: "CG_PJ_SEG_RED_TER_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2MzY=",
    name: "CG_PJ_SEG_RED_TER_AM.docx",
    type: "word"
  },
  {
    id: "NTY2MzM=",
    name: "CG_PJ_SEG_RED_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2MzI=",
    name: "CG_PJ_SEG_RED_AM.docx",
    type: "word"
  },
  {
    id: "NTY2MzE=",
    name: "CG_PJ_SEG_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2MzA=",
    name: "CG_PJ_SEG_AM.docx",
    type: "word"
  },
  {
    id: "NTY2Mjk=",
    name: "CG_PJ_RED_TER_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2Mjg=",
    name: "CG_PJ_RED_TER_AM.docx",
    type: "word"
  },
  {
    id: "NTY2Mjc=",
    name: "CG_PJ_RED_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2MjU=",
    name: "CG_PJ_RED_AM.docx",
    type: "word"
  },
  {
    id: "NTY2MjA=",
    name: "CG_PJ_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NTc=",
    name: "CG_PF_TER_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NTY=",
    name: "CG_PF_TER_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NTU=",
    name: "CG_PF_SEG_TER_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NTQ=",
    name: "CG_PF_SEG_TER_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NTM=",
    name: "CG_PF_SEG_RED_TER_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NTI=",
    name: "CG_PF_SEG_RED_TER_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NTA=",
    name: "CG_PF_SEG_RED_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NTE=",
    name: "CG_PF_SEG_RED_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NDk=",
    name: "CG_PF_SEG_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NDg=",
    name: "CG_PF_SEG_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NDc=",
    name: "CG_PF_RED_TER_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NDY=",
    name: "CG_PF_RED_TER_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NDU=",
    name: "CG_PF_RED_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2NDQ=",
    name: "CG_PF_RED_AM.docx",
    type: "word"
  },
  {
    id: "NTY2ODg=",
    name: "CG_PF_AM.docx",
    type: "word"
  },
  {
    id: "NTY2MjQ=",
    name: "CG_PJ_REC_AM.docx",
    type: "word"
  },
  {
    id: "NTY2ODk=",
    name: "CG_PF_REC_AM.docx",
    type: "word"
  },
]
