import styled from "styled-components";

export const Container = styled.div`
    max-width: 1120px;
    margin: 0 auto;
    padding: 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    
    strong {
        font-size: 2rem;
        color: var(--blue);
    }

    p {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    button {
        font-size: 1rem;
        color: #fff;
        background: var(--blue);
        border: 0;
        padding: 0 2rem;
        border-radius: 0.25rem;
        height: 4.5rem;
        width: 15rem;
        margin-top: 1rem;

        transition: filter 0.2s;

        &:hover {
            filter: saturate(70%) drop-shadow(5px 5px 11px gray);
        }
    }
`;