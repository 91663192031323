import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas } from './styles';
import { Header } from '../../../components/Header';
import { Table, Row, Col } from 'react-bootstrap';
import { SeguradoraTodos } from '../../../hooks/seguradoras';
import { useHistory, useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Seguradoras } from '../../../interfaces/seguradoras';
import { SeguroGrupos } from '../../../interfaces/seguroGrupos';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../../components/PaginationSeguro';
import Switch from '@mui/material/Switch';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { FiArrowLeft } from 'react-icons/fi';



const FormGrupos: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const idCargo = useRef(null);
  const { seguradoraLista, getAll, getAllSegurosGrupo, seguroGrupoLista } = SeguradoraTodos();
  const [checked, setChecked] = React.useState(false);
  const [listaSearchPlano, setListaSearchPlano] = useState<Seguradoras[]>(seguradoraLista);
  const [listaCargoAtivo, setListaCargoAtivo] = useState<SeguroGrupos[]>(seguroGrupoLista);
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchPlano.length / PER_PAGE);
  const _DATA = usePagination(listaSearchPlano, PER_PAGE);
  const [filtroDescricao, setFiltroDescricao] = useState<string>();
  const [filtroAtivo, setFiltroAtivo] = useState<string>('Todos');
  const [filtroTipoBem, setFiltroTipoBem] = useState<number>(0);
  const [filtroTipoVenda, setFiltroTipoVenda] = useState<number>();

  const { grupo } = useParams() as any

  function handleSearchTodos() {

    let listaGeral: Seguradoras[];

    listaGeral = seguradoraLista;
    setListaSearchPlano(listaGeral);

    if (filtroDescricao != null || filtroDescricao != undefined) {
      const lista = listaGeral.filter(item => filtroDescricao ? (item.nome).toLowerCase().includes(filtroDescricao.toLowerCase()) : item);
      listaGeral = lista
      setListaSearchPlano(listaGeral);

    }

    if (filtroTipoVenda != null || filtroTipoVenda != undefined) {

      if (filtroTipoVenda > -1) {
        console.log(filtroTipoVenda)
        const lista = listaGeral.filter(item => filtroTipoVenda?.toString() ? item.codigoSeguradora.toString().includes(filtroTipoVenda.toString()) : item);
        console.log(lista)
        listaGeral = lista
        setListaSearchPlano(listaGeral);
      }
    }


  }

  const handleChangePagination = (event: object, e: number) => {
    setPage(e);
    _DATA.jump(e);
  };

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    setFiltroDescricao(event.target.value)
  }

  function handleSearchCodigoVenda(event: ChangeEvent<HTMLInputElement>) {
    setFiltroTipoVenda(parseInt(event.target.value))
  }

  function handleSearchAtivos(event: ChangeEvent<HTMLInputElement>) {
    setFiltroAtivo(event.target.value)
  }

  function handleChangeSelected(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroTipoBem(parseInt(e.target.value))
  }


  async function handleSwitch(event: ChangeEvent<HTMLInputElement>) {
    if (grupo === undefined) {
      addToast({
        type: 'error',
        title: 'Grupo',
        description: 'Volte e Selecione um Grupo!'
      })

      return
    }

    const plano = seguradoraLista.find(item => item.id?.toString() === event.target.id)

    if (event.target.checked === true) {
      if (plano != undefined) {
        try {
          const response = await api.post(`/v1/grupos-seguradora`,
            {
              codigoSeguradora: plano.codigoSeguradora,
              codigoGrupo: grupo,
              obrigatorio: false,
              opcional: true,
            })

          getAllSegurosGrupo();

          if (response.status === 201) {
            addToast({
              type: 'success',
              title: 'Dados Salvos',
              description: 'Dados foram salvos com sucesso!'
            })
          } else {
            addToast({
              type: 'error',
              title: 'Erro ao tentar salvar!',
              description: 'Informe este erro ao TI do consórcio2!'
            })
          }
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao tentar salvar!',
            description: 'Informe este erro ao TI do consórcio!'
          })
        }
      }

    } else {
      try {
        if (event.target.id != null) {
          const result = seguradoraLista.find(e => e.codigoSeguradora === parseInt(event.target.id))
          const proc = seguroGrupoLista.find(e => e.codigoSeguradora === result?.codigoSeguradora && e.codigoGrupo === parseInt(grupo));
          console.log(seguroGrupoLista)
          console.log(seguradoraLista)
          const responseDel = await api.delete(`/v1/grupos-seguradora/${proc?.id}`)
          getAllSegurosGrupo();
          

          addToast({
            type: 'info',
            title: 'Deletado com sucesso!',
            description: 'Plano foi deletado com sucesso!'
          })
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao tentar salvar!',
            description: 'Informe este erro ao TI do consórcio!'
          })
        }

      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao tentar salvar!',
          description: 'Informe este erro ao TI do consórcio!'
        })
      }
    }

    /*if (plano != undefined) {

  } else {
    try {
      if (event.target.id != null) {
        const responseDel = await api.delete(`/v1/sequencias-agrupamento-interno/${event.target.id}`)
        getAllSegurosGrupo();

        addToast({
          type: 'info',
          title: 'Deletado com sucesso!',
          description: 'Plano foi deletado com sucesso!'
        })
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao tentar salvar!',
          description: 'Informe este erro ao TI do consórcio!'
        })
      }

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao tentar salvar!',
        description: 'Informe este erro ao TI do consórcio!'
      })
    }
  }*/
  }

  useEffect(() => {
    addToast({
      type: 'info',
      title: 'Carregando a Listagem',
      description: 'Por favor aguarde!'
    })
    getAll();
    getAllSegurosGrupo();
  }, [getAll, getAllSegurosGrupo])



  useEffect(() => {
    setListaSearchPlano(seguradoraLista)
    setListaCargoAtivo(seguroGrupoLista)
  }, [seguradoraLista, seguroGrupoLista])

  useEffect(() => {
    handleSearchTodos()
  }, [filtroAtivo, filtroDescricao, filtroTipoBem, filtroTipoVenda])
  
  function voltar(){
    history.goBack()
  }

  return (
    <>
      <Header />
      <Container>
        
        <div className='title'><FiArrowLeft onClick={voltar} className='voltar'/><h2>Seguros (Grupo {grupo})</h2><h5></h5></div>
        <Tabelas>
          <Table bordered hover className='tabela'>
            <thead>


              <tr>
                <th colSpan={5}>
                  <Row>
                    <Col xs={6}>
                      <TextField className='pesquisa' variant='standard' type="number" placeholder="Código Seguro" onChange={handleSearchCodigoVenda} name="procurar" />
                    </Col>
                    <Col xs={6}>
                      <TextField className='pesquisa' variant='standard' type="text" placeholder="Descrição Seguro" onChange={handleSearch} name="procurar" />
                    </Col>
                  </Row>
                </th>
              </tr>
              <tr className='tituloTabela'>
                <th>Código</th>
                <th>Nome</th>
                <th>Seg. Vida(%)</th>
                <th>Seg. quebra(%)</th>
                <th className="ativar">Opcional</th>
              </tr>
            </thead>
            <tbody className='teste'>
              {_DATA.currentData().map(function (item) {
                const response = seguroGrupoLista.find(e => e.codigoSeguradora === item.codigoSeguradora && e.codigoGrupo === parseInt(grupo));
                let result = false
                if (response != undefined) {
                  result = true
                }
                return (
                  <tr className="linha">
                    <td className="id">{item.codigoSeguradora}</td>
                    <td>
                      {item.nome.toUpperCase()}
                    </td>
                    <td className="ativar">
                      {item.percentualSegVida}
                    </td>
                    <td className="ativar">
                      {item.percentualSegQuebra}
                    </td>
                    <td className="ativar">
                      <Switch
                        name={item.nome}
                        onChange={handleSwitch}
                        size="small"
                        value={item.id}
                        id={item.id?.toString()}
                        checked={result}
                      /></td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan={5} style={{ textAlign: 'center' }}>
                  <Pagination style={{ paddingLeft: '260px' }} count={count} page={page} onChange={handleChangePagination} size="large" />
                </td>
              </tr>
            </tbody>
          </Table>
        </Tabelas>


      </Container>
    </>
  )
}

export default FormGrupos