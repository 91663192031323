import styled from "styled-components";

export const Container = styled.header`
    //top:0;
    //z-index:10;
    width: 100%;
    background: var(--blue);
    height: 12vh;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 18vh;
        /*height: 100px;*/
    }

    button {
        color: white;
        background-color: transparent;
        border: 0px;
        padding: 20px;
        //margin-right: 30px;
        //margin-left:20px;

    }


`
export const Content = styled.header`
    //max-width: 1120px;
    //width: 900px;
    margin: 0 auto;
    display: flex;
`

export const LeftGroup = styled.header`
    display: flex;
    justify-items: left;
    align-items: center;
    padding: 0 1rem;
`
export const LabelHeader = styled.header`
    color: #ffffff;
    margin-left: 2rem;

    strong{
        //color: var(--name);
        //margin-left: 0.5rem;
    }
`

export const RightGroup = styled.header`
    display: flex;''
    
    //align-items: center;
    //padding: 0 1rem;
    //margin-right: 30px;
    
    /*img {
        width: 50px;
        margin-left: 10px;
        cursor: pointer;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(1.7);
        }

    }*/
`