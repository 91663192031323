import React from "react";
import { Form, Button } from 'react-bootstrap';
import { Container } from './styles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from '../../../services/api';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../hooks/toast';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Checkbox from '@mui/material/Checkbox';
import NumberFormat from "react-number-format";
import { Col, Row } from "../styles";

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      //decimalSeparator={","}

      prefix={"R$ "}
      format={currencyFormatter}
    />
  );
}

function currencyFormatter(value: any) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value / 100);

  return amount

}

export function Etapa2DadosCadastraisPJ(props: any) {

  const { DadosVenda, setAlteraDados, setAlteraPagina } = props
  const { addToast } = useToast();
  const [checked, setChecked] = React.useState(false);
  const [age, setAge] = React.useState<Date | null>(null);

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != undefined) {
      setAge(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
      let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      setAlteraDados({ ...DadosVenda, age1: newValue, dataNascimento: DataFormatada, dtEF: DataFormatada1 })
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "isento") {
      console.log(e.target)
      setChecked(e.target.checked)
      if (e.target.checked) {
        setAlteraDados({ ...DadosVenda, isento: "x", isentoChecked: true, inscricaoEstadual: "" })
      } else {
        setAlteraDados({ ...DadosVenda, isento: "", isentoChecked: false })
      }

    } else {
      setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value.toUpperCase() })
    }
  };


  /*function formatData(x: string) {
    let dataAss = new Date(x)
    let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }*/

  async function passaFase2() {
    if (DadosVenda.codB === 0) {
      addToast({
        type: 'error',
        title: 'Erro bem selecionado',
        description: 'Ao retornar a primeira pagina, por favor selecione o bem novamente!'
      });
      setAlteraPagina(0)
    }
    const tudo = JSON.stringify(DadosVenda)
    const teste = JSON.parse(tudo)
    if (DadosVenda?.id > 0) {
      const response = await api.put(`v1/pre-vendas/${DadosVenda.id}`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 2,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        nomeCliente: DadosVenda.nomeCliente,
        status: "PENDENTE",
        posVenda: "PENDENTE",

      })

      if (response.status === 200) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa3: false,

        })
      }

      setAlteraPagina(2)
    } else {
      const response = await api.post(`v1/pre-vendas`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 2,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        nomeCliente: DadosVenda.nomeCliente,
        status: "PENDENTE",
        posVenda: "PENDENTE",

      })

      if (response.status === 201) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa4: false,

        })
        setAlteraPagina(2)
      }
    }
  }

  return (
    <>
      <Container>
        <Row between style={{ marginTop: 15 }}>
          <Col min='180px' max='200px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  InputLabelProps={{ shrink: DadosVenda.documento > 0 ? true : false }}
                  disabled
                  className='inputNovo'
                  id="standard-required"
                  label="CNPJ"
                  variant="standard"
                  name='documento'
                  value={DadosVenda.documentoFormatado}
                />
              </FormControl>
            </div>
          </Col>
          <Col min='280px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  required
                  className='inputNovo'
                  inputProps={{ maxlength: 50 }}
                  id="standard-required"
                  label="Razão Social"
                  variant="standard"
                  name='nomeCliente'
                  value={DadosVenda.nomeCliente}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col  min='280px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  required
                  className='inputNovo'
                  inputProps={{ maxlength: 50 }}
                  id="standard-required"
                  label="Nome Fantasia"
                  variant="standard"
                  name='nomeFantasia'
                  value={DadosVenda.nomeFantasia}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
        </Row>

        <Row between style={{ marginTop: 17 }}>
          <Col min='180px' max='260px'>
            <div className='datePick'>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DesktopDatePicker
                  label="Data constituição da empresa *"
                  inputFormat="dd/MM/yyyy"
                  value={DadosVenda.age1}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params}
                  />
                  }
                />
              </LocalizationProvider>
            </div>
          </Col>
          <Col min='180px' max='260px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  InputLabelProps={{ shrink: DadosVenda.capitalSocial > 0 ? true : false }}
                  label="Capital social da empresa"
                  required
                  value={DadosVenda.capitalSocial > 0 ? DadosVenda.capitalSocial : ""}
                  onChange={handleChangeInput}
                  variant="standard"
                  name="capitalSocial"
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </FormControl>
            </div>
          </Col>
          <Col min='180px' max='260px'>
            <div className="inputNovo">
              <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                <TextField
                  disabled={checked}
                  className='inputNovo'
                  id="standard-required"
                  label={DadosVenda.isento === "x" ? "Inscrição estadual" : "Inscrição estadual*"}
                  variant="standard"
                  name='inscricaoEstadual'
                  value={DadosVenda.inscricaoEstadual}
                  onChange={handleChangeInput}
                />
              </FormControl>
            </div>
          </Col>
          <Col min='180px' max='320px'>
            <FormControlLabel style={{ marginTop: "26px" }} control={<Checkbox onChange={handleChangeInput} name="isento" checked={DadosVenda.isentoChecked} sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Isento" />
          </Col>
        </Row>

        <Row end>
          <Col min='180px' max='320px'>
            <Form.Group className="mb-3 teste right" as={Col} controlId="formGridEmail">
              <div className="botaoPesquisar">
                <Button variant="primary" type="submit" className="btnPesquisar" onClick={passaFase2}
                  disabled={(DadosVenda.nomeCliente === "" ||
                    DadosVenda.nomeFantasia === "" ||
                    DadosVenda.dataNascimento === "" ||
                    (DadosVenda.inscricaoEstadual === "" &&
                      DadosVenda.isentoChecked === false))
                    ? true : false
                  }
                >
                  <Typography variant="button" gutterBottom> PRÓXIMO</Typography>
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </>
  )
}