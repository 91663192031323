import React, { ChangeEvent, useState, useEffect } from "react";
import { Form, Button, Row, Col, Table } from 'react-bootstrap';
import { Container } from './styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Tabela } from './styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import api from '../../../services/api';
import FormControl from '@mui/material/FormControl';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../hooks/toast';
import { Contato } from '../../../interfaces/contato';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import IconButton from '@mui/material/IconButton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ContatoConsulta } from '../../../interfaces/contatoConsulta';
import copy from 'copy-to-clipboard';
import NumberFormat from "react-number-format";

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      //decimalSeparator={","}
    
      prefix={"R$ "}
      // isNumericString

      format={currencyFormatter}
    />
  );
}

function currencyFormatter(value: any){
  if(!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value/100);

  return amount

}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const FIXO = {
  telefone: 'Telefone Comercial',
  mascara: '(99) 9999-9999',
}

const WHATSAPP = {
  telefone: 'WhatsApp',
  mascara: '(99) 99999-9999'
}

const CELULAR = {
  telefone: 'Celular',
  mascara: '(99) 99999-9999',
}

export function Etapa6CapacidadeFinanceiraPF(props: any) {

  const { addToast } = useToast();

  const { DadosVenda, setAlteraDados, setAlteraPagina } = props

  const [contatosBanco, setContatosBanco] = useState<ContatoConsulta[]>();
  const [contact, setContact] = useState<Omit<Contato, "id" | "dadoUsuario">[]>([])
  const [campoEmail, setCampoEmail] = React.useState({ tipoContato: "EMAIL", valor: '' })
  const [campoFone, setCamposFone] = React.useState(CELULAR)


  const [modelContato, setModelContato] = useState<Omit<Contato, "id" | "dadoUsuario">>({
    tipoContato: 'CELULAR',
    valor: '',
  })


  const pessoaJuridica = {
    documentoPrincipal: 'CNPJ',
    mascaraPessoa: '99.999.999/9999-99',
  }

  const [camposPessoa, setCamposPessoa] = React.useState(pessoaJuridica);
  const [age, setAge] = React.useState<Date | null>(null);
  const [model, setModel] = useState({
    data: ''
  })

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != undefined) {
      setAge(newValue)
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
      setModel({
        ...model,
        data: DataFormatada
      });
    }
  };

  async function inserirContatoTelefone() {
    if (modelContato.valor.length < 13) {
      addToast({
        type: 'info',
        title: 'Digite Telefone Válido',
        description: 'Digite um telefone Válido!'
      })
      return
    }
    if (contatosBanco != undefined) {
      const a = contatosBanco.filter(item => item.valor === modelContato.valor)

      if (a.length > 0) {
        addToast({
          type: 'info',
          title: 'Contato Cadastrado',
          description: 'Contato já existe neste Cadastro!'
        })
        return
      }
    }
    try {
      const response = await api.post(`/v1/contatos`,
        {
          tipoContato: modelContato.tipoContato,
          valor: modelContato.valor,
          //dadoUsuario: { id: model.id }
        })
      //findContato(model.id)

      setContact([...contact, { tipoContato: modelContato.tipoContato, valor: modelContato.valor }])
    } catch (err) {
      console.log(err)
    }

    setModelContato({
      ...modelContato,
      valor: ''
    })
  }



  function randomDarkRGB() {
    var r = Math.floor(Math.random() * 156);
    var g = Math.floor(Math.random() * 156);
    var b = Math.floor(Math.random() * 156);
    var bgColor = "rgb(" + r + "," + g + "," + b + ")";
    return bgColor;
  }

  async function excluirFone(x: number) {
    const response = await api.delete(`/v1/contatos/${x}`)
    //findContato(model.id)
  }

  function Copiar(x: string) {
    copy(x)
    addToast({
      type: 'success',
      title: 'Dado Copiado',
      description: 'Para utilizar basta apenas colar onde quiser!'
    })
  }

  function handleChangeCheck(e: ChangeEvent<HTMLInputElement>) {
    if(e.target.name === "t_p7"){
      if(parseInt(e.target.value) === 1 || parseInt(e.target.value) === 3 || parseInt(e.target.value) === 4){
        setAlteraDados({ ...DadosVenda, [e.target.name]: parseInt(e.target.value), valorAluguelPrestacao: "" })
      }else{
        setAlteraDados({ ...DadosVenda, [e.target.name]: parseInt(e.target.value) })
      }
    }
    setAlteraDados({ ...DadosVenda, [e.target.name]: parseInt(e.target.value) })
  }

  function handleChangeCheck1(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      console.log(e.target.name)
      setAlteraDados({ ...DadosVenda, [e.target.name]: "X" })
    } else {
      setAlteraDados({ ...DadosVenda, [e.target.name]: "" })
    }
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.name)
    console.log(e.target.value)

    if(e.target.name === "valorAluguelPrestacao"){
      let valor = parseFloat(e.target.value)/100
      let valorF = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      console.log(valorF)
      setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value.toUpperCase(),
      valorA: valorF})
    }else{
    setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value.toUpperCase() })
  }
  };

  async function passaFase6() {
    if(DadosVenda.codB === 0){
      addToast({
        type: 'error',
        title: 'Erro bem selecionado',
        description: 'Ao retornar a primeira pagina, por favor selecione o bem novamente!'
      });
      setAlteraPagina(0)
    }
    const tudo = JSON.stringify(DadosVenda)
    const teste = JSON.parse(tudo)
    if (DadosVenda?.id > 0) {
      const response = await api.put(`v1/pre-vendas/${DadosVenda.id}`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 6,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        status: "PENDENTE",
        posVenda: "PENDENTE",
        
      })

      console.log(response.status)

      if (response.status === 200) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa7: false,

        })
      }

      setAlteraPagina(6)
    } else {
      const response = await api.post(`v1/pre-vendas`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 6,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        status: "PENDENTE",
        posVenda: "PENDENTE",
        
      })

      if (response.status === 201) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa7: false,

        })

        setAlteraPagina(6)
      }
    }


  }


  return (
    <>
      <Container>
        <Row style={{ marginTop: 0 }}>
          <Col style={{ backgroundColor: "rgb(240,240,240" }}>
            <div style={{ color: "rgb(110,110,110", marginTop: 10 }}>QUAL O GRAU DE ESCOLARIDADE?</div>
            <RadioGroup style={{ marginBottom: 10 }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p1"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p1}
            >
              <FormControlLabel value="1" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="1º Grau incompleto" />
              <FormControlLabel value="2" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="2º Grau incompleto" />
              <FormControlLabel value="3" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Superior incompleto" />
              <FormControlLabel value="4" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="1º Grau completo" />
              <FormControlLabel value="5" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="2º Grau completo" />
              <FormControlLabel value="6" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Superior completo" />
            </RadioGroup>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col style={{ backgroundColor: "rgb(240,240,240" }}>
            <div style={{ color: "rgb(110,110,110", marginTop: 10 }}>EXERCE ATIVIDADE REMUNERADA?</div>
            <RadioGroup style={{ marginBottom: 10 }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p2"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p2}
            >
              <FormControlLabel value="1" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Sim" />
              <FormControlLabel value="2" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Não" />

            </RadioGroup>
          </Col>
        </Row>
        {DadosVenda.t_p2 === 1 ? <>
          <Row>
            <Col style={{ backgroundColor: "rgb(240,240,240" }}>
              <div className="inputNovo" style={{ marginBottom: 10 }}>
                <FormControl className="inputNovo" variant="standard"  >
                  <TextField
                    required
                    className='inputNovo'
                    id="standard-required"
                    label="Há quanto tempo"
                    variant="standard"
                    name='atividadeRemunerada'
                    value={DadosVenda.atividadeRemunerada.toUpperCase()}
                    onChange={handleChangeInput}
                  />
                </FormControl>
              </div>
            </Col>
          </Row>
        </> : <></>}

        <Row style={{ marginTop: 10 }}>
          <Col style={{ backgroundColor: "rgb(240,240,240" }}>
            <div style={{ color: "rgb(110,110,110", marginTop: 10 }}>QUAL SUA SITUAÇÃO DE TRABALHO?</div>
            <RadioGroup style={{ marginBottom: 10 }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p3"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p3}
            >
              <FormControlLabel value="1" style={{ width: "300px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Trabalha com vínculo empregatício (Carteira assinada/contrato de trabalho)" />
              <FormControlLabel value="2" style={{ width: "300px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Desempregado" />
              <FormControlLabel value="4" style={{ width: "300px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Autônomo" />
                            <FormControlLabel value="3" style={{ width: "300px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Trabalha sem vínculo empregatício (Prestador de serviços terceirizados)" />
              <FormControlLabel value="5" style={{ width: "300px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Aponsetado" />
              
              
            </RadioGroup>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col style={{ backgroundColor: "rgb(240,240,240" }}>
            <div style={{ color: "rgb(110,110,110", marginTop: 10 }}>SOMANDO A SUA RENDA COM A RENDA DAS PESSOAS QUE MORAM COM VOCÊ, QUANTO É APROXIMADAMENTE, A RENDA MENSAL FAMILIAR?</div>
            <RadioGroup style={{ marginBottom: 10 }}
          
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p4"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p4}
            > 
              <Row>
                <Col>
                <FormControlLabel value="1" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Até R$ 1.000,00" />
                <FormControlLabel value="4" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 1.001,00 a R$ 1.500,00" />
                <FormControlLabel value="7" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 1.501,00 a R$ 2.000,00" />
                <FormControlLabel value="10" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 2.001,00 a R$ 2.500,00" />
                <FormControlLabel value="13" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 2.501,00 a R$ 3.000,00" />
                <FormControlLabel value="15" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 3.001,00 a R$ 3.500,00" />
              </Col>
                <Col>
                <FormControlLabel value="2" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 3.501,00 a R$ 4.000,00" />
                <FormControlLabel value="5" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 4.001,00 a R$ 5.000,00" />
                <FormControlLabel value="8" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 5.001,00 a R$ 6.000,00" />
                <FormControlLabel value="11" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 6.001,00 a R$ 7.000,00" />
                <FormControlLabel value="14" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 7.001,00 a R$ 8.000,00" />
              
              </Col>
                <Col>
                <FormControlLabel value="16" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 8.001,00 a R$ 9.000,00" />
                <FormControlLabel value="3" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 9.001,00 a R$ 10.000,00" />
                <FormControlLabel value="6" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Acima de R$ 10.000,00" />
                <FormControlLabel value="9" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Acima de R$ 15.000,00" />
                <FormControlLabel value="12" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Acima de R$ 20.000,00" />
              </Col>
              </Row>
            </RadioGroup>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col style={{ backgroundColor: "rgb(240,240,240" }}>
            <div style={{ color: "rgb(110,110,110", marginTop: 10 }}>QUAL SUA PARTICIPAÇÃO NA RENDA FAMILIAR?</div>
            <RadioGroup style={{ marginBottom: 10 }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p5"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p5}
            >
              <FormControlLabel value="1" style={{ width: "300px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Não trabalho e meus gastos são financiados pela família" />
              <FormControlLabel value="2" style={{ width: "300px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Trabalho e me sustento" />
              <FormControlLabel value="3" style={{ width: "400px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Trabalho e sou o principal responsável pelo sustento da família" />
              <FormControlLabel value="4" style={{ width: "300px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Trabalho e recebo ajuda da família" />
              <FormControlLabel value="5" style={{ width: "300px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Trabalho e contribuo para o sustento da família" />
            </RadioGroup>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col style={{ backgroundColor: "rgb(240,240,240" }}>
            <div style={{ color: "rgb(110,110,110", marginTop: 10 }}>QUAIS AS DESPESAS MENSAIS DA FAMÍLIA? (APROXIMADAMENTE)</div>
            <RadioGroup style={{ marginBottom: 10 }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p6"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p6}
            >
              <Row>
                <Col>
                <FormControlLabel value="1" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Até R$ 1.000,00" />
                <FormControlLabel value="4" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 1.001,00 a R$ 1.500,00" />
                <FormControlLabel value="7" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 1.501,00 a R$ 2.000,00" />
                <FormControlLabel value="10" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 2.001,00 a R$ 2.500,00" />
                <FormControlLabel value="13" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 2.501,00 a R$ 3.000,00" />
                <FormControlLabel value="15" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 3.001,00 a R$ 3.500,00" />
                </Col>
                <Col>
                <FormControlLabel value="2" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 3.501,00 a R$ 4.000,00" />
                <FormControlLabel value="5" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 4.001,00 a R$ 5.000,00" />
                <FormControlLabel value="8" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 5.001,00 a R$ 6.000,00" />
                <FormControlLabel value="11" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 6.001,00 a R$ 7.000,00" />
                <FormControlLabel value="14" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 7.001,00 a R$ 8.000,00" />
                </Col>
                <Col>
                <FormControlLabel value="16" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 8.001,00 a R$ 9.000,00" />
                <FormControlLabel value="3" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="De R$ 9.001,00 a R$ 10.000,00" />
                <FormControlLabel value="6" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Acima de R$ 10.000,00" />
                <FormControlLabel value="9" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Acima de R$ 15.000,00" />
                <FormControlLabel value="12" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Acima de R$ 20.000,00" />
                </Col>

              </Row>
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
            </RadioGroup>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col style={{ backgroundColor: "rgb(240,240,240" }}>
            <div style={{ color: "rgb(110,110,110", marginTop: 10 }}>A CASA QUE VOCÊ MORA É:</div>
            <RadioGroup style={{ marginBottom: 10 }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p7"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p7}
            >
              <FormControlLabel value="1" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Própria" />
              <FormControlLabel value="3" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Doada" />
              <FormControlLabel value="4" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Emprestada" />
              <FormControlLabel value="5" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Financiada" />
              <FormControlLabel value="2" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Alugada" />
            </RadioGroup>
          </Col>
        </Row>
        {DadosVenda.t_p7 === 5 || DadosVenda.t_p7 === 2 ? <>
          <Row>
            <Col style={{ backgroundColor: "rgb(240,240,240" }}>
              <div className="inputNovo" style={{ marginBottom: 10 }}>
                <FormControl variant="standard" style={{ width: "100%" }} >

                  <TextField
                    style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
                    InputLabelProps={{ shrink: DadosVenda.valorAluguelPrestacao > 0 ? true : false }}
                    label="QUAL O VALOR DA PRESTAÇÃO"
                    required
                    value={DadosVenda.valorAluguelPrestacao > 0 ? DadosVenda.valorAluguelPrestacao : ""}
                    onChange={handleChangeInput}
                    variant="standard"
                    name="valorAluguelPrestacao"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />

                </FormControl>
              </div>
            </Col>
          </Row>
        </> : <></>}

        <Row style={{ marginTop: 10 }}>
          <Col style={{ backgroundColor: "rgb(240,240,240" }}>
            <div style={{ color: "rgb(110,110,110", marginTop: 10 }}>A FAMÍLIA POSSUI BENS?</div>
            <RadioGroup style={{ marginBottom: 10 }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="t_p8"
              className="tipoPessoaRadio"
              onChange={handleChangeCheck}
              value={DadosVenda.t_p8}
            >
              <FormControlLabel value="1" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="SIM" />
              <FormControlLabel value="2" style={{ width: "300px", height: "30px" }} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="NÃO" />
            </RadioGroup>
          </Col>
        </Row>
        {DadosVenda.t_p8 === 1 ? <>
          <Row style={{ marginTop: 10 }}>
            <Col style={{ backgroundColor: "rgb(240,240,240" }}>
              <div style={{ color: "rgb(110,110,110", marginTop: 10 }}>QUAIS BENS?</div>
              <RadioGroup style={{ marginBottom: 10 }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"

              >
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b1" checked={DadosVenda.b1 === "X" ? true : false} onChange={handleChangeCheck1} sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Casa" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b4" checked={DadosVenda.b4 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Carro" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b7" checked={DadosVenda.b7 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Fazenda" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b2" checked={DadosVenda.b2 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Apartamento" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b5" checked={DadosVenda.b5 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Moto" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b8" checked={DadosVenda.b8 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Outros" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b3" checked={DadosVenda.b3 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Terreno" />
                <FormControlLabel style={{ width: "300px", height: "30px" }} control={<Checkbox name="b6" checked={DadosVenda.b6 === "X" ? true : false} onChange={handleChangeCheck1} defaultChecked sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label="Chacára" />
              </RadioGroup>
            </Col>
          </Row>
          {DadosVenda.b8 === "X" ? <>
            <Row>
              <Col style={{ backgroundColor: "rgb(240,240,240" }}>
                <div className="inputNovo">
                  <FormControl className="inputNovo" variant="standard" style={{ marginBottom: 10 }}  >
                    <TextField
                      required
                      className='inputNovo'
                      id="standard-required"
                      label="Quais?"
                      variant="standard"
                      name='b9'
                      value={DadosVenda.b9.toUpperCase()}
                      onChange={handleChangeInput}
                    />
                  </FormControl>
                </div>
              </Col>
            </Row>
          </> : <></>}

        </> : <></>}


        <Row style={{ marginTop: 39 }}>
          <Col xs={3}>


          </Col>
          <Col xs={6}></Col>
          <Col xs={3}>
            <Form.Group className="mb-3 teste right" as={Col} controlId="formGridEmail">
              <div className="botaoPesquisar">
                <Button variant="primary" type="submit" className="btnPesquisar" onClick={passaFase6}
                  disabled={(DadosVenda.t_p1 === 0 ||
                    DadosVenda.t_p2 === 0 ||
                    DadosVenda.t_p3 === 0 ||
                    DadosVenda.t_p4 === 0 ||
                    DadosVenda.t_p5 === 0 ||
                    DadosVenda.t_p6 === 0 ||
                    DadosVenda.t_p7 === 0 ||
                    DadosVenda.t_p8 === 0)
                    ? true : false
                  }
                >
                  <Typography variant="button" gutterBottom> PRÓXIMO</Typography>
                </Button>
              </div>
            </Form.Group>

          </Col>

        </Row>




      </Container>
    </>
  )
}