import React, {useRef, useCallback } from 'react';
import { FiUser, FiLock } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErros';

import { Container, Content, AnimationContainer } from './styles';
import logo from '../../assets/logo.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { isGetAccessor } from 'typescript';
import axios, { AxiosError } from 'axios';

interface SignInFormData {
  usuario: string,
  senha : string,
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();


  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async(data: SignInFormData) => {
     try {
       
      formRef.current?.setErrors({});
       const schema = Yup.object().shape({
         usuario: Yup.string().required('Usuário obrigatório'),
         senha: Yup.string().required('Senha obrigatória'),
       });

       await schema.validate(data, {
         abortEarly: false,
       })

       await signIn({
         usuario: data.usuario,
         senha: data.senha
       });
       
       history.push('/dashboard');
       
     } catch (err) {
      console.log('só pra saber onde estou')
      
      const error = err as AxiosError
      if(error.response?.status === 401){

        if(error.response?.data.developerMessage === 'Senha/token inválido!'){
        addToast({
          type: 'error',
          title: 'Usuário ou senha inválida!',
          description: 'Tente Novamente!'
        })}else{
          addToast({
            type: 'error',
            title: 'Usuário bloqueado!',
            description: 'Entre em contato com a Administradora!'
          })
        }
      }else{
        addToast({
          type: 'error',
          title: 'Problema no Sistema!',
          description: 'Aguarde alguns minutos e tente novamente!'
        })
      }

       if(err instanceof Yup.ValidationError){
         const errors = getValidationErrors(err);

         formRef.current?.setErrors(errors);

         return;
       } 

       

  
      
     }
  }, [signIn, addToast]);

  return (
   <Container>
       <Content>
         <AnimationContainer>
          <img src={logo} alt='GScon' />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1> Faça seu Login</h1>

            <Input name="usuario" icon={FiUser} placeholder="Usuário" />
            <Input name="senha" icon={FiLock} type="password" placeholder="Senha" />
            <Button type="submit">Entrar</Button>
            <Link to="/RecoveryPassword">Esqueci minha senha</Link>
          </Form>
         </AnimationContainer> 
       </Content>
   </Container>
)};

export default SignIn