import { useCallback, useState } from "react"
import { GetGrupo } from '../services/Grupo';
import { Grupos } from '../interfaces/grupos';
import { GrupoSede } from '../interfaces/grupoSede';


const GruposTodos = () => {
    const [grupoLista, setGrupoLista] = useState<Grupos[]>([]);
    const [grupoSedeLista, setGrupoSedeLista] = useState<GrupoSede[]>([]);
    
    const getAll = useCallback(async () => {
        const { status, data } = await GetGrupo.getAllGrupos();

        if(status !== 200) throw new Error();

        setGrupoLista(data) 
    
    },[]);

    const getAllGruposSede = useCallback(async () => {
        const { status, data } = await GetGrupo.getAllGruposSede();

        if(status !== 200) throw new Error();

        setGrupoSedeLista(data) 
    
    },[]);

    /*const createGrupoUsuarios = useCallback(async (grupoUsuarios: Pick<GrupoUsuarios, 'nome' | 'listMenuGrupoUsuario' >) => {
        const { status, data } = await GetGrupoUsuarios.createGrupoUsuarios(grupoUsuarios);
        
        if(status !== 201) throw new Error();

    }, [])

    const alteraGrupoUsuarios = useCallback(async (grupoUsuarios: GrupoUsuarios) => {
        const { status, data } = await GetGrupoUsuarios.alteraGrupoUsuarios(grupoUsuarios);
        
        if(status !== 201) throw new Error();

    }, [])*/


    return {
        grupoLista,
        getAll,
        getAllGruposSede,
        grupoSedeLista,
        //createGrupoUsuarios,
        //alteraGrupoUsuarios,
    }
}

export { GruposTodos }