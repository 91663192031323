import api from './api';
import { Usuario } from '../interfaces/usuario';
import { ConsultaUsuario } from '../interfaces/usuarioConsulta';
import { TabelaUsuariosAtivos } from '../interfaces/tabelaUsuariosAtivos';

const getAll = () => api.get<ConsultaUsuario[]>('/v1/usuarios')

const getAllUsuariosAtivos = () => api.get<TabelaUsuariosAtivos[]>('/v1/tabelas-venda-usuario')

const createUsuario = (usuario: Omit<Usuario, `id`>) => api.post('/v1/usuarios', usuario)

const alteraUsuario = (cargo: Usuario) => api.put(`/v1/usuarios/`, cargo)

const deleteUsuario = (id: number) => api.delete(`/v1/cargos/${id}`)

const resetSenha = (nome: string) => api.post(`/v1/usuarios/esqueci-senha/${nome}`)

const saveSenha = (token: string, senhaNova: string) => api.post(`/v1/usuarios/alterar-senha/`, {token, senhaNova})

const deleteMenuUsuario = (id: number) => api.delete(`/v1/menus-usuario/${id}`)

export const GetUsuario = {
    getAll,
    createUsuario,
    alteraUsuario,
    deleteUsuario,
    resetSenha,
    saveSenha,
    deleteMenuUsuario,
    getAllUsuariosAtivos
}