export const dadosAssembleia = 
    {
        vencimento01: {
            '01':{
                dataAss: "25/01/2024",
                dataLot: "24/01/2024",
                dataVen: "22/01/2024"
            },
            '02':{
                dataAss: "26/02/2024",
                dataLot: "24/02/2024",
                dataVen: "21/02/2024"
            },
            '03':{
                dataAss: "25/03/2024",
                dataLot: "23/03/2024",
                dataVen: "21/03/2024"
            },
            '04':{
                dataAss: "25/04/2024",
                dataLot: "24/04/2024",
                dataVen: "22/04/2024"
            },
            '05':{
                dataAss: "23/05/2024",
                dataLot: "22/05/2024",
                dataVen: "21/05/2024"
            },
            '06':{
                dataAss: "24/06/2024",
                dataLot: "22/06/2024",
                dataVen: "21/06/2024"
            },
            '07':{
                dataAss: "25/07/2024",
                dataLot: "24/07/2024",
                dataVen: "22/07/2024"
            },
            '08': {
                dataAss: "26/08/2024",
                dataLot: "24/08/2024",
                dataVen: "21/08/2024",
            },
            '09': {
                dataAss: "26/09/2024",
                dataLot: "25/09/2024",
                dataVen: "23/09/2024"
            },
            '10': {
                dataAss: "24/10/2024",
                dataLot: "23/10/2024",
                dataVen: "21/10/2024"
            },
            '11':{
                dataAss: "25/11/2024",
                dataLot: "23/11/2024",
                dataVen: "21/11/2024"
            },
            '12':{
                dataAss: "19/12/2024",
                dataLot: "18/12/2024",
                dataVen: "18/12/2024"
            }
        },
        vencimento02: {
            '01':{
                dataAss: "15/01/2024",
                dataLot: "13/01/2024",
                dataVen: "10/01/2024"
            },
            '02':{
                dataAss: "19/02/2024",
                dataLot: "17/02/2024",
                dataVen: "15/02/2024"
            },
            '03':{
                dataAss: "14/03/2024",
                dataLot: "13/03/2024",
                dataVen: "11/03/2024"
            },
            '04':{
                dataAss: "15/04/2024",
                dataLot: "13/04/2024",
                dataVen: "10/04/2024"
            },
            '05':{
                dataAss: "13/05/2024",
                dataLot: "11/05/2024",
                dataVen: "10/05/2024"
            },
            '06':{
                dataAss: "13/06/2024",
                dataLot: "12/06/2024",
                dataVen: "10/06/2024"
            },
            '07':{
                dataAss: "15/07/2024",
                dataLot: "13/07/2024",
                dataVen: "10/07/2024"
            },
            '08': {
                dataAss: "15/08/2024",
                dataLot: "14/08/2024",
                dataVen: "12/08/2024",
            },
            '09': {
                dataAss: "12/09/2024",
                dataLot: "11/09/2024",
                dataVen: "10/09/2024"
            },
            '10': {
                dataAss: "17/10/2024",
                dataLot: "16/10/2024",
                dataVen: "10/10/2024"
            },
            '11':{
                dataAss: "14/11/2024",
                dataLot: "13/11/2024",
                dataVen: "11/11/2024"
            },
            '12':{
                dataAss: "12/12/2024",
                dataLot: "11/12/2024",
                dataVen: "10/12/2024"
            }
        }
    }
