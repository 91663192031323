import api from './api';
import { Menu } from '../interfaces/menu';

const getAll = () => api.get<Menu[]>('/v1/menus')

const createMenu = (menu: Pick<Menu, 'nome' | 'menusInferiores'>) => api.post('/v1/menus', menu)

const alteraMenu = (menu: Menu) => api.put(`/v1/menus/`, menu)

const deleteMenu = (id: number) => api.delete(`/v1/menus/${id}`)

export const GetMenu = {
    getAll,
    createMenu,
    alteraMenu,
    deleteMenu,
}