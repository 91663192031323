import React, { useRef, useState, useEffect, ChangeEvent, useCallback } from 'react';
import { Container, TabelasLista, Botao, PrimeiraLetra } from './styles';
import { Header } from '../../components/Header';
import { FiPlus, FiEdit, FiUsers, FiGrid } from 'react-icons/fi';
import { BsPrinterFill } from 'react-icons/bs';
import { Table, Button, Modal } from 'react-bootstrap';
import { Tabelas } from '../../hooks/tabelas';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationTabelas';
import { Tabela } from '../../interfaces/tabela';
import moment from 'moment';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { logoGroscon } from '../../mock/logo';
import { SequenciaCard } from '../../interfaces/sequenciaCard';
import { TabelaBensParcela } from '../../interfaces/tabelasBensParcela';
import { RelatoriosParcela } from '../../hooks/relatorioParcelas';


const TabelaVendas: React.FC = () => {
  const history = useHistory();
  const idTabela = useRef(null);
  moment.locale('pt-br');
  const { tabelaLista, getAll, sequenciaCards, getAllSequenciaCards } = Tabelas();
  const [checked, setChecked] = React.useState(false);
  const [listaSearchTabelas, setListaSearchTabelas] = useState<Tabela[]>(tabelaLista);
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchTabelas.length / PER_PAGE);
  const _DATA = usePagination(listaSearchTabelas, PER_PAGE);
  const [listaCards, setListaCards] = useState<SequenciaCard[]>(sequenciaCards);
  const { parcelas, getAllTabelaParcelas } = RelatoriosParcela();
  const [listaParcela, setListaParcela] = useState<TabelaBensParcela[]>(parcelas);
  //const [parcelas, setParcelas] = useState([]);
  console.log('Chegou aqui ta listando?')
  console.log(listaParcela)
  


  const handleChangePagination = (event: object, e: number) => {
    setPage(e);
    _DATA.jump(e);
  };


  const handleChange = () => {
    setChecked(!checked);
  }

  function editCargo(id: number) {
    history.push(`/cargoCadastro/${id}`)
  }

  function novaTabela() {
    history.push(`/tabelasEtapa1/`)
  }

  function editarTabela(id: number) {
    history.push(`/tabelasEtapa1/${id}`)
  }

  function parte2Tabela(id: number) {
    history.push(`/tabelasEtapa2/${id}`)
  }

  function parte3Tabela(id: number) {
    history.push(`/tabelasEtapa3/${id}`)
  }

  function parte4Tabela(id: number) {
    history.push(`/tabelasEtapa4/${id}`)
  }

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    const lista: Tabela[] = tabelaLista.filter(item => event.target.value ? item.nome.toLowerCase().includes(event.target.value.toLowerCase()) : item);
    setListaSearchTabelas(lista);
    setPage(1)
    _DATA.jump(1);
  }

  useEffect(() => {
    getAll();
    getAllSequenciaCards();
  }, [getAll])

  useEffect(() => {
    setListaSearchTabelas(tabelaLista)
    setListaCards(sequenciaCards)
  }, [tabelaLista, sequenciaCards])

  useEffect(() => {
    console.log(parcelas)
  },[getAllTabelaParcelas])

  

  function randomDarkRGB() {
    var r = Math.floor(Math.random() * 156);
    var g = Math.floor(Math.random() * 156);
    var b = Math.floor(Math.random() * 156);
    var bgColor = "rgb(" + r + "," + g + "," + b + ")";
    return bgColor;
  }

  function NovaTabela() {
    history.push(`/tabelasEtapa1/`)
  }

  

  const dadosTabela = async (id: number) => {
    try{
      const resposta = await getAllTabelaParcelas(id)
      teste(id)
    }catch(error){
      console.log(error)
    }

  }

  function teste(id:number){

  setTimeout(function time(id: number){
    console.log(parcelas)
    console.log('olha ai pra cima')
    contratoImpressao(id, parcelas)
},3000)
}

 

  /*const dadosTabela = useCallback(async(id: number) => {
    await getAllTabelaParcelas(id)

  },[parcelas])

  const teste1 = async (id: number) => {
    //await setListaParcela(()=>parcelas)
    teste2(id)
  }

  const teste2 = async (id: number) => {
    await contratoImpressao(id, parcelas)
  }*/


    /*async function dadosTabela(id: number){
      await getAllTabelaParcelas(id)
      setListaParcela(parcelas)
      contratoImpressao(id)
      console.log('chegou aqui item 1')
      console.log(parcelas)
      
    }*/
  

  async function contratoImpressao(id: number, parcelas2: TabelaBensParcela[]) {

    const dataAtual = moment().format('DD/MM/YYYY HH:mm:ss')
    const pdf = new jsPDF("l", "mm", "a4");
    const nome = localStorage.getItem('@Gscon:user')?.toUpperCase()

    

    let body2: any = []
    let dadosBem: any = []
    let parcelas1 : any = []

    listaParcela?.map(function (item) {
      item.bens.map(function (item) {
        item.parcelas.map(function (item){
          parcelas1.push(item.valorParcela)
          parcelas1.push(item.valorParcelaComSeguro)
        })
        dadosBem.push(item.nomeBem+' - '+item.codigoBem, item.valorBem)
        body2.push(dadosBem.concat(parcelas1))
        parcelas1 = []
        dadosBem = []
      })
  })
    
    let body: any = []

    listaCards.map(function (item) {

      if (item.tabelaVenda.id === id) {
        return body.push([item.sequencia.prazo, item.adesao + '%', item.adesao + '%', item.sequencia.descricao])
      }
    })
    /*dadosPreVendaUsuarioF.map(item => item.etapa > 2 ? body.push([moment(item.dataCriacao).format('DD/MM/YYYY'),
    item.idContrato, item.nomeCliente, item.valorCredito, item.status, item.tipoAssinatura==='DIGITAL'?'DIGITAL':'MANUAL', item.tipoPagamento==='BOLETO'?'BOLETO':'MANUAL']) : "")*/

    autoTable(pdf, {
      tableWidth: 'auto',
      head: [{ teste: 'Prazo', teste1: 'Adesao', teste2: 'Redução', teste3: 'Plano de Venda' }],
      body: body,
      margin: { top: 27, bottom: 30, left: 10, right: 10 },
      willDrawPage: function (data) {
        pdf.setFontSize(8);
        pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
        /*pdf.text(`FILTROS : VENDEDOR: ${filtros.vendedor === 0 ? "TODOS" : filtros.vendedor} | STATUS: ${filtros.status} | DATA: ${(age1 || age2) === null ? " " : moment(age1).format('DD/MM/YYYY') + ' até ' + moment(age2).format('DD/MM/YYYY')}`, 10, 25, { align: 'left' });*/
        pdf.setFontSize(7);
        pdf.text(`USUÁRIO: ${nome}`, 285, 17, { align: 'right' });
        pdf.text(`DATA : ${dataAtual}`, 285, 12, { align: 'right' });
        pdf.setFontSize(13);
        pdf.setFont("arial", "bold")
        pdf.text('teste', 130, 17, { align: 'center' });


      },
      didDrawPage: function (data) {
        pdf.setFontSize(10);
        pdf.setFont("times", "normal");
        pdf.text(`Página ${pdf.getNumberOfPages()}`, 10, 285, { align: 'left' });
        pdf.text("Groscon Administradora de Consórcios LTDA", 200, 285, { align: 'right' });
        pdf.setDrawColor(0, 0, 0);
        pdf.line(10, 280, 200, 280);
      },
      headStyles: { halign: 'center' },
      columnStyles: { 0: { halign: 'center', fontSize: 8 }, 1: { halign: 'center', fontSize: 8 }, 2: { halign: 'center', fontSize: 8 }, 3: { halign: 'center', fontSize: 8 }, 4: { halign: 'center', fontSize: 8 }, 5: { halign: 'center', fontSize: 8 }, 6: { halign: 'center', fontSize: 8 } },

    })

    autoTable(pdf, {
      tableWidth: 'auto',
      head: [{ teste: 'Bem', teste1: 'Valor Bem', teste2: 'Parcela Sem Seguro', teste3: 'Parcela com Seguro',teste4: 'Parcela Sem Seguro', teste5: 'Parcela com Seguro' }],
      body: body2,
      margin: { top: 27, bottom: 30, left: 10, right: 10 },
      headStyles: { halign: 'center' },
      columnStyles: { 0: { halign: 'center', fontSize: 8 }, 1: { halign: 'center', fontSize: 8 }, 2: { halign: 'center', fontSize: 8 }, 3: { halign: 'center', fontSize: 8 }, 4: { halign: 'center', fontSize: 8 }, 5: { halign: 'center', fontSize: 8 }, 6: { halign: 'center', fontSize: 8 } },
    })

    window.open(pdf.output('bloburl'));
  }

  return (
    <>
      <Header />
      <Container>
        <div className='title'><h2>Tabelas de Vendas</h2></div>
        <TabelasLista>
          <Table bordered hover className='tabela'>
            <thead>
              <tr>
                <th colSpan={5} ><TextField className='pesquisa' variant='standard' type="text" placeholder="Pesquisar" onChange={handleSearch} /></th>
              </tr>
            </thead>
            <tbody>

              {_DATA.currentData().map(function (item) {
                if (item.id !== undefined) {
                  return (
                    <tr className="linha">
                      <td
                        className="celula"
                        key={item.id}
                        ref={idTabela}
                        id={item.id.toString()}
                      >
                        <PrimeiraLetra style={{ backgroundColor: `${randomDarkRGB()}` }}>
                          {item.nome.substring(0, 1).toUpperCase()}
                        </PrimeiraLetra>
                        {item.nome.toUpperCase()}
                      </td>
                      <td className='opcoes'>
                        <Button variant="primary" onClick={() => editarTabela(item.id)} style={{ background: 'var(--blue)', border: 'none', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: "center" }}><FiEdit size={30}></FiEdit></Button>
                      </td>
                      {/*  <td className='opcoes'>
                        <Button variant="primary" onClick={() => parte2Tabela(item.id)} style={{ background: 'var(--blue)', border: 'none', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: "center" }}><FiSettings size={30}></FiSettings></Button>
                      </td>*/}
                      <td className='opcoes'>
                        <Button variant="primary" onClick={() => parte3Tabela(item.id)} style={{ background: 'var(--blue)', border: 'none', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: "center" }}><FiGrid size={30}></FiGrid></Button>
                      </td>
                      <td className='opcoes'>
                        <Button variant="primary" onClick={() => parte4Tabela(item.id)} style={{ background: 'var(--blue)', border: 'none', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: "center" }}><FiUsers size={30}></FiUsers></Button>
                      </td>
                      {/*<td className='opcoes'>
                        <Button variant="primary" onClick={() => dadosTabela(item.id)} style={{ background: 'var(--blue)', border: 'none', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: "center" }}><BsPrinterFill size={30}></BsPrinterFill></Button>
                    </td>*/}
                    </tr>
                  )
                }
              }

              )}
              <tr className="linha2">
                <td className='pagination'>
                  <Pagination count={count} page={page} onChange={handleChangePagination} size="large" />
                </td>
              </tr>
            </tbody>
          </Table>

          <Botao>
            <Button variant="primary" onClick={novaTabela} style={{ border: 'none', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems: "center" }}><FiPlus size={30} /></Button>
          </Botao>
        </TabelasLista>


      </Container>
    </>
  )
}

export default TabelaVendas