import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas, Botao, PrimeiraLetra } from './styles';
import { Header } from '../../components/Header';
import { Table, Row, Col } from 'react-bootstrap';
import { Usuarios } from '../../hooks/usuario';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import { ConsultaUsuario } from '../../interfaces/usuarioConsulta';
import Chip from '@mui/material/Chip';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/Pagination';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Tipo {
  type: "success" | "primary";
}

const Users: React.FC = () => {
  const history = useHistory();
  const idCargo = useRef(null);
  const { usuariosLista, getAll } = Usuarios();
  const [checked, setChecked] = React.useState(false);
  const [listaSearchCargo, setListaSearchCargo] = useState<ConsultaUsuario[]>(usuariosLista);
  const [page, setPage] = useState(1);
  const PER_PAGE = 8;
  const count = Math.ceil(listaSearchCargo.length / PER_PAGE);
  const _DATA = usePagination(listaSearchCargo, PER_PAGE);
  const [tipoNome, setTipoNome] = useState('usuario');



  

  const handleChangePagination = (event: object, e:number) => {
    console.log(e)
    setPage(e);
    _DATA.jump(e);
  };

  const handleChange = () => {
    setChecked(!checked);
  }

  function editCargo(id: number) {
    history.push(`/usuariosForm/${id}`)
  }

  function novoCargo() {
    history.push(`/usuariosForm/`)
  }

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    if(tipoNome==='usuario'){
    const lista: ConsultaUsuario[] = usuariosLista.filter(item => event.target.value ? item.nome.toLowerCase().includes(event.target.value.toLowerCase()) : item);
    setListaSearchCargo(lista);
    }else{
      const lista: ConsultaUsuario[] = usuariosLista.filter(item => event.target.value ? item.dadoUsuario?.nomePrincipal?.toLowerCase().includes(event.target.value.toLowerCase()) || item.dadoUsuario?.nomeSecundario?.toLowerCase().includes(event.target.value.toLowerCase()) : item);
      setListaSearchCargo(lista);
    }
    setPage(1)
    _DATA.jump(1);

  }

  useEffect(() => {
    getAll();
  }, [getAll])

  useEffect(() => {
    setListaSearchCargo(usuariosLista)
    console.log(usuariosLista)
  }, [usuariosLista])

  function randomDarkRGB() {
    var r = Math.floor(Math.random() * 156);
    var g = Math.floor(Math.random() * 156);
    var b = Math.floor(Math.random() * 156);
    var bgColor = "rgb(" + r + "," + g + "," + b + ")";
    return bgColor;
  }

  function teste(event: ChangeEvent<MouseEvent>) {
    alert(event)
  }

  function verificarTipoNome() {
    tipoNome==='usuario'?setTipoNome('nome'):setTipoNome('usuario')
  }

  return (
    <>
      <Header />
      <Container>
        <div className='title'><h2>Cadastro de Usuários</h2></div>

        <Tabelas>
          
          <Table bordered hover className='tabela'>

            <thead>
              <tr>

                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="tipoPessoaRadio"
                        onChange={verificarTipoNome}
                        defaultValue="usuario"
                      >
                        <FormControlLabel value="usuario" control={<Radio />} label="Usuário" />
                        <FormControlLabel value="nome" control={<Radio />} label="Nome" />
                      </RadioGroup>

              </tr>
              <tr>
                <th><TextField className='pesquisa' variant='standard' type="text" placeholder="Pesquisar" onChange={handleSearch} /></th>
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map(function (item) {
                let tipo = ""
                let titulo = ""
                if (item.dadoUsuario.nomePrincipal) {
                  tipo = "#198754"
                  titulo = "Cadastro Completo"
                } else {
                  tipo = "var(--blue);"
                  titulo = "Finalize Cadastro"
                }
                return (
                  <tr className="linha">
                    <td
                      className="celula"
                      key={item.id}
                      ref={idCargo}
                      id={item.id.toString()}
                      onClick={() => editCargo(item.id)}>
                      <PrimeiraLetra style={{ backgroundColor: `${randomDarkRGB()}` }}>
                        {item.nome.substring(0, 1).toUpperCase()}
                      </PrimeiraLetra>

                      <div className='nomeUsuarioSistema'>{tipoNome==='usuario'?item.nome.toUpperCase():item.dadoUsuario?.nomePrincipal.toUpperCase()} {item.dadoUsuario?.tipoPessoa==="PESSOA_FISICA" && tipoNome==='nome'?item.dadoUsuario?.nomeSecundario?.toUpperCase():''}</div>
                      <div className='nomeUsuario'>

                        <Chip className="tamannhoChip" label={titulo} sx={{ bgcolor: tipo, color: 'white', width: '150px' }} variant="outlined" />

                      </div>
                    </td>
                  </tr>
                )
              })}


              <tr>
                <td className='pagination'>
                  <Pagination count={count} page={page} onChange={handleChangePagination} size="large" />
                </td>
              </tr>
            </tbody>
          </Table>
        </Tabelas>



      </Container>
    </>
  )
}

export default Users