import { ChangeEvent, useEffect, useState, } from 'react';
import { Container1, CardContainer1, Cards1 } from './styles';
import { Button, Form, Row, Col } from 'react-bootstrap';
import api from '../../services/api';
import { ParametrosTabelas } from '../../interfaces/parametrosTabelas';
import { ParametrosTabelasGet } from '../../interfaces/parametrosTabelasGet';
import { TiposBens } from '../../interfaces/tiposBem';
import { SequenciaCard } from '../../interfaces/sequenciaCard';
import { SequenciaAgrupamentoTabelaVendas } from '../../interfaces/sequenciaAgrupamentoTabela';
import { SequenciaAgrupamento } from '../../interfaces/sequenciaAgrupamento';
import { PlanoAgrupamento } from '../../interfaces/planoAgrupamentos';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import { Tabelas } from '../../hooks/tabelas';
import { Planoh } from '../../hooks/plano';
import { Planos } from '../../interfaces/planos';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { FiPlus, FiX } from 'react-icons/fi';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Table } from 'react-bootstrap';
import { useToast } from '../../hooks/toast';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export function EtapaTeste(props: any) {
    
	const { addToast } = useToast();
	const { getAllSequenciaCards, sequenciaCards, getAllPlanos, planosAgrupamento, getAllSequenciaAgrupamento, sequenciaAgrupamento } = Tabelas();
	const [listaCards, setListaCards] = useState<SequenciaCard[]>(sequenciaCards)
	const [listaPlanos, setListaPlanos] = useState<PlanoAgrupamento[]>(planosAgrupamento)
	const [listaSeqAgrup, setListaSeqAgrup] = useState<SequenciaAgrupamento[]>(sequenciaAgrupamento)
	const [grupo, setGrupo] = useState<number>();
	const { ativosLista, getAllAtivos } = Planoh();
	const [ativos, setAtivos] = useState<Planos[]>(ativosLista)
	const [ parametrosTabela, setParametrosTabela] = useState<ParametrosTabelasGet[]>();
	const [openAdesao, setOpenAdesao] = useState(false);
	const [openReducao, setOpenReducao] = useState(false);
	const handleOpenAdesao = () => setOpenAdesao(true);
	const handleCloseAdesao = () => setOpenAdesao(false);
	const handleOpenReducao = () => setOpenReducao(true);
	const handleCloseReducao = () => setOpenReducao(false);
	const [modelP, setModelP] = useState<Omit<ParametrosTabelas, "id">>({
		tabelaVenda: {id: props.Tabela},
		tipo: "ADESAO",
		valor: 0,
	 })

	useEffect(() => {
		getAllAtivos();
		getAllSequenciaCards();
		getAllPlanos();
		getAllSequenciaAgrupamento();
		
	}, [getAllAtivos, getAllSequenciaCards])

	useEffect(() => {
		carregaParametro()
	}, [])


	useEffect(() => {
		setAtivos(ativosLista)
		setListaCards(sequenciaCards)
		setListaPlanos(planosAgrupamento)
		setListaSeqAgrup(sequenciaAgrupamento)
	}, [ ativosLista, sequenciaCards, planosAgrupamento])

	useEffect(()=> {
       ordenar()
	},[])

	const [model, setModel] = useState<Omit<SequenciaAgrupamentoTabelaVendas, "id">>({
	   adesao: 0,
	   reducao: 0,
	   codigoGrupoBem: 0,
	   codigoTipoVenda: 0,
	   sequenciaAgrupamento: 0,
	})

	async function carregaParametro(){
		const response2 = await api.get('/v1/parametros-tabela-venda/paginado?page=0&size=99999&sort=valor,asc')
		setParametrosTabela(response2.data.content)
	}


	async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
		const ativo = ativosLista.find(item => item.id === model.sequenciaAgrupamento)
		e.preventDefault()
		if (ativo !== undefined) {
			if(model.sequenciaAgrupamento > 0){
				const response = await api.post(`/v1/sequencias-agrupamento-tabela-venda/`, 
				{ 
					sequenciaAgrupamento: ativo.sequenciaAgrupamento,
					codigoGrupoBem: ativo.codigoGrupoBem,
					codigoTipoVenda: ativo.codigoTipoVenda,
					adesao: model.adesao,
					reducao: model.reducao,
					tabelaVenda: {id: props.Tabela}
				})
				addToast({
					type: 'success',
					title: 'Sucesso ao salvar',
					description: 'Card criado com sucesso!'
				});
			}else{
				addToast({
					type: 'error',
					title: 'Erro ao salvar',
					description: 'Faltou preencher Plano de Venda, card não foi criado!'
				});
				return
			}
		} else {
			addToast({
				type: 'error',
				title: 'Erro ao salvar',
				description: 'Faltou preencher Plano de Venda, card não foi criado!'
			});
			return
		}

		setModel({
			adesao: 0,
	        reducao: 0,
	   codigoGrupoBem: 0,
	   codigoTipoVenda: 0,
	   sequenciaAgrupamento: 0,
	})
			
	getAllSequenciaCards();
		//back();
	}

	async function findTabela(id: string) {
		const response = await api.get(`/v1/tabelas-venda/${id}`);
		setModel({
          ...model
		})

		getAllSequenciaCards();

	}

	async function deletaCard(id: number) {
		const response = await api.delete(`/v1/sequencias-agrupamento-tabela-venda/${id}`);
        getAllSequenciaCards();
	}

	async function salvarParametroAdesao() {
		if(parametrosTabela != undefined){
        const lista: ParametrosTabelasGet[] = parametrosTabela?.filter(item => "ADESAO" ? item.tipo.includes("ADESAO") : item);
		const lista1 = lista.filter(item => props.Tabela ? item.idTabelaVenda.toString().includes(props.Tabela.toString()): item)
		const listaF = lista1.find(item => item.valor === modelP.valor)
		
		if(listaF !== undefined){
			addToast({
				type: 'info',
				title: 'Já existe este percentual ',
				description: 'Insira outro percentual!'
			  });
			return;
		}
	}

        const response = await api.post(`/v1/parametros-tabela-venda/`,
		   {
			 tipo: "ADESAO",
			 valor: modelP.valor,
			 tabelaVenda: {id: props.Tabela}
		   }
		)
		carregaParametro()
		handleCloseAdesao()

		setModelP({
			tabelaVenda: {id: props.Tabela},
			tipo: "ADESAO",
			valor: 0,
		 })
	}

	async function salvarParametroReducao() {

		if(parametrosTabela != undefined){
			const lista: ParametrosTabelasGet[] = parametrosTabela?.filter(item => "REDUCAO" ? item.tipo.includes("REDUCAO") : item);
			const lista1 = lista.filter(item => props.Tabela ? item.idTabelaVenda.toString().includes(props.Tabela.toString()): item)
			const listaF = lista1.find(item => item.valor === modelP.valor)
			
			if(listaF !== undefined){
				addToast({
					type: 'info',
					title: 'Já existe este percentual ',
					description: 'Insira outro percentual!'
				  });
				return;
			}
		}

        const response = await api.post(`/v1/parametros-tabela-venda/`,{
				tipo: "REDUCAO",
				valor: modelP.valor,
				tabelaVenda: {id: props.Tabela}
		})
		carregaParametro()
		handleCloseReducao()

		setModelP({
			tabelaVenda: {id: props.Tabela},
			tipo: "REDUCAO",
			valor: 0,
		 })
	}

	function handleChangeAdesao(e: ChangeEvent<HTMLSelectElement>) {
		setModel({
			...model,
			adesao: parseFloat(e.target.value)
		})
	}

	function handleChangeReducao(e: ChangeEvent<HTMLSelectElement>) {
		setModel({
			...model,
			reducao: parseFloat(e.target.value)
		})
	}

	async function handleGrupo(e: ChangeEvent<HTMLSelectElement>) {

		setModel({
			...model,
	        sequenciaAgrupamento: parseInt(e.target.value)
		});
	}

	function verificar() {
		if (!grupo) {
			return (
				<option></option>
			)
		}
	}

	function listaSequencia(sequencia: number){
		const lista = listaPlanos.filter(item => sequencia.toString() ? item.sequenciaAgrupamento.toString() === (sequencia.toString()) : item);
		return lista
	}

	function adicionaZero(numero: number) {
		if (numero <= 9)
		  return "00" + numero;
		else if (numero <= 99)
		  return "0" + numero;
		else
		  return numero
	  }

	  function handleChangeInputP(e: ChangeEvent<HTMLInputElement>) {
		setModelP({
			...modelP,
			valor: parseFloat(e.target.value)
		});
	}

	function ordenar(){
            let ListaCardOrdenado = listaCards?.sort(function compare(a,b){
			if(a.sequencia.prazo < b.sequencia.prazo) return -1;
			if(a.sequencia.prazo > b.sequencia.prazo) return 1;
			return 0
		})
		setListaCards(ListaCardOrdenado)
	}


	return (
		<>
			<Container1>
				<CardContainer1>
					<div className='title1'><div></div><h2>Regra de Cobrança</h2><h5></h5></div>
					<Form className="form-border1" onSubmit={onSubmit}>
						<Row>
							<Col xs={2}>
								<Form.Group className="inputNovo2">
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Adesão
									</InputLabel>
									<NativeSelect
										className="inputNovo3"
										onChange={handleChangeAdesao}
										value={model.adesao}
										inputProps={{
											name: 'adesao',
											id: 'uncontrolled-native',
										}}
									>
										{
											parametrosTabela?.map(function (item) {
                                                if(item.idTabelaVenda === parseInt(props.Tabela) && item.tipo === "ADESAO"){
													return(
														<option
														  id={item.valor.toString()}
														  value={item.valor}
														>
															{item.valor}%
														</option>
													)
												}
											})
										}

									</NativeSelect>
								</Form.Group>
							</Col>
							<Col xs={1}>
                  <Button className="botaoAdd" onClick={handleOpenAdesao}><FiPlus size={20} /></Button>
                </Col>
							<Col xs={2}>
								<Form.Group className="inputNovo2">
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Redução
									</InputLabel>
									<NativeSelect
										className="inputNovo3"
										onChange={handleChangeReducao}
										value={model.reducao}
										inputProps={{
											name: 'reducao',
											id: 'uncontrolled-native',
										}}
									>
										{
											parametrosTabela?.map(function (item) {
                                                if(item.idTabelaVenda === parseInt(props.Tabela) && item.tipo === "REDUCAO"){
													return(
														<option
														  id={item.valor.toString()}
														  value={item.valor}
														>
															{item.valor}%
														</option>
													)
												}
											})
										}
									</NativeSelect>
								</Form.Group>
								
							</Col>
							<Col xs={1}>
                  <Button className="botaoAdd" onClick={handleOpenReducao}><FiPlus size={20} /></Button>
                </Col>
							<Col xs={5}>
								<Form.Group className="inputNovo4">
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Plano de Venda
									</InputLabel>
									<NativeSelect
										className="inputNovo3"
										value={model.sequenciaAgrupamento}
										onChange={handleGrupo}
										defaultValue=""
										inputProps={{
											name: 'cargo',
											id: 'uncontrolled-native',
										}}
									>
										{verificar()
										}
										{ativosLista.map(item => {
											console.log(item)
											//if (item.id === props.Tabela) {
											return (
												<option
													id={item.codigoTipoVenda.toString()}
													value={item.id}>

													{item.descricao}
												</option>
											)
											//}
										})

										}



									</NativeSelect>
								</Form.Group>
							</Col>
							
						</Row>



						<Form.Group className="buttonsCargo">
							<Button type="submit">Adicionar</Button>
						</Form.Group>
					</Form>
					<div>
					<div className='tituloLista'>Lista Simplificada</div>

          <Table bordered hover className='tabela'>
            <thead>
              <tr>
                <th>Prazo</th>
				<th>Adesão</th>
				<th>Redução</th>
				<th className='ac'>Plano de Venda</th>
              </tr>
            </thead>
            <tbody>
			{
				listaCards?.map(function (item) {
                    const x:PlanoAgrupamento[] = listaSequencia(item.sequenciaAgrupamento)
					let descricao = '';
					if(item.tabelaVenda.id === parseInt(props.Tabela)){
						const tipo = listaSeqAgrup.find(item2 => item2.sequenciaAgrupamento === item.sequenciaAgrupamento && item.codigoTipoVenda === item2.codigoTipoVenda && item2.prazo === item.sequencia.prazo)
						if(tipo?.descricao !== undefined)
						descricao = ' - '+tipo.descricao
                  return (
                <tr className="linha">
                  <td className='ac'>
				  {item.sequencia.prazo} 
                  </td>
				  <td className='ac'>
				  {item.adesao}% 
                  </td>
				  <td className='ac'>
				  {item.reducao}% 
                  </td>
				  <td >
				  {item.codigoTipoVenda}{descricao}
                  </td>
                </tr>
              )}})}
            </tbody>
          </Table>
</div>
                    <Cards1>
					

					{listaCards?.map(function (item) {
                    const x:PlanoAgrupamento[] = listaSequencia(item.sequenciaAgrupamento)
					if(item.tabelaVenda.id === parseInt(props.Tabela)){
                  return (
                    <div style={{marginBottom: 10, border: 20}}>	
					<Card sx={{ maxWidth: 345, width: 248, marginRight: 2, marginBottom: 10, boxShadow: 5 }}>
						<CardContent>
							<Typography gutterBottom variant="h5" component="div" className='cabecalhoCard'>
							<div className='spam'><div>{item.sequencia.prazo} Meses</div> <FiX className='fechar' style={{cursor: 'pointer'}} onClick={() => deletaCard(item.id)}></FiX></div>
							</Typography>
							<Typography variant="body2" color="text.secondary">
								<div className='informacoes'>
								Adesão
								<div className='valor'>{item.adesao}%</div>
								Redução
								<div className='valor'>{item.reducao}%</div>
								Plano de Venda
								<div className='valor'>{item.codigoTipoVenda}</div>
								<div className='barra'></div>
								{x.map((item) => 
								   <div className='percentual'>{adicionaZero(item.parcelaInicial)} à {adicionaZero(item.parcelaFinal)} = TX {item.percentualTaxaAdministracao}% e FC {item.percentualNormal}%</div>
								)}
                                </div>
							</Typography>
						</CardContent>
					</Card>
					</div>
                  )}
                
              })}
					</Cards1>
					
				</CardContainer1>
			</Container1>
			<div>
      <Modal
        open={openAdesao}
        onClose={handleCloseAdesao}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cadastrar Adesão
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
			  <Row>
				  <Col>
				  <Form.Group className="inputCargo">
									<Form.Control
										type="number"
										name="nome"
										placeholder="Valor"
										value={modelP.valor}
										onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputP(e)}
									/>
								</Form.Group>
				  </Col>

			  </Row>
			  <Form.Group style={{display:'flex', marginTop:'30px', justifyContent: 'flex-end' }} className="buttonsCargo">
							<Button style={{backgroundColor: 'rgb(19,52,131)'}} onClick={handleCloseAdesao}>Cancelar</Button>
							<Button style={{marginLeft: '15px', backgroundColor: 'rgb(19,52,131)'}} onClick={salvarParametroAdesao}>Salvar</Button>
						</Form.Group>

          </Typography>
        </Box>
      </Modal>

	  <Modal
        open={openReducao}
        onClose={handleCloseReducao}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cadastrar Redução
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
			  <Row>
				  <Col>
				  <Form.Group className="inputCargo">
									<Form.Control
										type="number"
										name="nome"
										placeholder="Valor"
										value={modelP.valor}
										onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputP(e)}
									/>
								</Form.Group>
				  </Col>

			  </Row>
			  <Form.Group style={{display:'flex', marginTop:'30px', justifyContent: 'flex-end' }} className="buttonsCargo">
							<Button style={{backgroundColor: 'rgb(19,52,131)'}} onClick={handleCloseReducao}>Cancelar</Button>
							<Button style={{marginLeft: '15px', backgroundColor: 'rgb(19,52,131)'}} onClick={salvarParametroReducao}>Salvar</Button>
						</Form.Group>

          </Typography>
        </Box>
      </Modal>
    </div>
		</>
		
	)
}
