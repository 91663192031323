import { useCallback, useState } from "react"
import { getTabelas } from '../services/Tabelas';
import { Tabela } from '../interfaces/tabela';
import { TiposBens } from '../interfaces/tiposBem';
import { SequenciaCard } from "../interfaces/sequenciaCard";
import { PlanoAgrupamento } from '../interfaces/planoAgrupamentos';
import { SequenciaAgrupamento } from '../interfaces/sequenciaAgrupamento';


const Tabelas = () => {
    const [tabelaLista, setTabelaLista] = useState<Tabela[]>([]);
    const [tiposBem, setTiposBem ] = useState<TiposBens[]>([]);
    const [sequenciaCards, setSequenciaCards] = useState<SequenciaCard[]>([])
    const [planosAgrupamento, setPlanosAgrupamento] = useState<PlanoAgrupamento[]>([]) 
    const [sequenciaAgrupamento, setSequenciaAgrupamento] = useState<SequenciaAgrupamento[]>([])
    
    const getAll = useCallback(async () => {
        const { status, data } = await getTabelas.getAllTabelas();

        if(status !== 200) throw new Error();

        console.log(data)
        
        setTabelaLista(data.sort(function(a,b){return a.nome < b.nome ? -1: a.nome > b.nome ? 1: 0})) 
    
    },[]);

    const getAllTiposBem = useCallback(async () => {
        const { status, data } = await getTabelas.getAllTiposBem();

        if(status !== 200) throw new Error();

        setTiposBem(data) 
    },[])

    const getAllSequenciaCards = useCallback(async () => {
        const { status, data } = await getTabelas.getAllCards();
        const x = await getTabelas.getAllCards2();
        const y:SequenciaCard[] = x.data.content
        let ListaCardOrdenado = y
        console.log('ListaCardOrdenado')
        console.log(y)
        if(y){
            ListaCardOrdenado = y.sort(function compare(a,b){
			if(a.sequencia.prazo < b.sequencia.prazo) return -1;
			if(a.sequencia.prazo > b.sequencia.prazo) return 1;
			return 0
		})}

        if(status !== 200) throw new Error();

        setSequenciaCards(ListaCardOrdenado) 
    },[])

    const getAllPlanos = useCallback(async () => {
        const { status, data } = await getTabelas.getAllPlanoAgrupamento();

        if(status !== 200) throw new Error();

        setPlanosAgrupamento(data) 
    },[])

    const getAllSequenciaAgrupamento = useCallback(async () => {
        const { status, data } = await getTabelas.getAllPlanos();

        if(status !== 200) throw new Error();

        setSequenciaAgrupamento(data)

    },[])

    /*const createCargo = useCallback(async (cargo: Pick<Cargo, 'nome' | 'ativo'>) => {
        const { status, data } = await GetCargos.createCargo(cargo);
        
        if(status !== 201) throw new Error();

    }, [])

    const alteraCargo = useCallback(async (cargo: Cargo) => {
        const { status, data } = await GetCargos.alteraCargo(cargo);
        
        if(status !== 201) throw new Error();

    }, [])*/


    return {
        tabelaLista,
        getAll,
        tiposBem,
        getAllTiposBem,
        sequenciaCards,
        getAllSequenciaCards,
        planosAgrupamento,
        getAllPlanos,
        sequenciaAgrupamento,
        getAllSequenciaAgrupamento
    }
}

export { Tabelas }