import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    margin: 20px;
    flex-direction: column;
    
    position:relative;
    align-items:center;

    
    .voltar{
        font-size: 30px;
        cursor: pointer;
      }

    .inputCargo{
        width: 900px;
        margin-top: 30px;
    }

    .buttonsCargo{
        display: flex;
        margin-top: 35px;
        justify-content:flex-end;
        
        Button{
            margin-left: 10px;
            background-color: var(--blue);;
            min-width: 100px;
            border: none;
        }
        
       
    }

    .buttonsCargo1{
        display: flex;
        justify-content:flex-end;
        
        Button{
            margin-left: 10px;
            background-color: var(--blue);;
            min-width: 100px;
            border: none;
        }
        
       
    }

    .checkCargo{
        margin-top: 20px;
    }

    .form-border{
        border-width: 1px;
        border-color: #cccccc;
        border-style: solid;
        padding: 20px;
        border-radius: 2px;
    }
`;

export const CardContainer = styled.div`
    display:flex;
    flex:1;
    align-items:center;
    justify-content:space-between;
    max-width: 800px;
    width: 800px;
    flex-direction: column;
   
    top:-10px;
    position: absolute;
    background-color:#ffffff;
    z-index: 50;
    margin-top: 50px;

    .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 900px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

   }

   .inputNovo{
       margin-top: 40px;
       
   }

   .inputNovo1{
    width: 100%;
    width: 236px;
   }
`;

export const Cards = styled.div`
   display: flex;
    margin-top: 20px;
    max-width: 900px;
    flex-wrap: wrap;

    .MuiCardContent-root{
        padding: 0px;
    }

    .cabecalhoCard{
       color: #ffffff;
       background: var(--blue);
       width: 100%;

    }

   .spam{
           display: flex;
           align-items: center;
           margin-left: 12px;
           height: 40px;
    }

    .informacoes{
        margin-left: 12px;
        margin-right: 8px;
    }

    .valor{
        color: black;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .barra{
        border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #a7a6a6;
    margin-bottom: 10px;
    }

    .percentual{
        margin-bottom: 10px;
        background: #f1efef;
        border-radius: 5%;
    }

    


`;