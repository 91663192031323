import styled, { css } from "styled-components";

import Tooltip from '../Tolltip';

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
   display: flex;
   align-items: center;
   background: #f4ede8;
   border-radius: 10px;
   border: 2px solid #f4ede8;
   //outline: 0;
   padding: 15px;
   width: 100%;
   color: #757575;
   

   & + div {
     margin-top: 8px;
   }

   ${props => props.isErrored && css`
     border-color: #c53030;
   `} 
   
   ${props => props.isFocused && css`
     color: #363f5f;
     border-color: #363f5f;
   `} 

   ${props => props.isFilled && css`
     color: #363f5f;
   `}
   
   input {
           flex: 1;
           background: transparent;
           border: 0;

        :focus {
          box-shadow: 0 0 0 0;
          outline: 0;
        }
    }

    svg {
        margin-right: 15px;
    }

`;

export const Error = styled(Tooltip)`
    height: 20px;

    svg{
      margin: 0;
      margin-left: 16px;
    }

    span {
      background: #c53030;
      color: #fff;

      &::before{
         border-color: #c53030 transparent;
      }
    }
`;