import { Container } from './styles';
import { SetStateAction, useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Accordion, ListGroup } from 'react-bootstrap';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import { menusRota } from '../../utils/menus';
import { Usuarios } from '../../hooks/usuario';
import { ConsultaUsuario } from '../../interfaces/usuarioConsulta';
import api from '../../services/api';
import { NoMeals } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export function MenuHeader() {

  const [show, setShow] = useState(false);
  const nome = localStorage.getItem('@Gscon:user')
  const [usuarios, setUsuarios] = useState<ConsultaUsuario[]>([]);
  const [usuario, setUsuario] = useState<ConsultaUsuario>();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    buscaUsuarios();
  }, [])

  useEffect(() => {
    buscaDadosUsuario();
  }, [usuarios])

  async function buscaUsuarios() {
    const { data } = await api.get('/v1/usuarios')
    setUsuarios(data)
    buscaDadosUsuario()
  }

  function buscaDadosUsuario() {
    console.log('usuarios')
    console.log(usuarios)
    if(usuarios.length > 0){
      usuarios.map(item => item)
    const dadosUsuario = usuarios.find(item => item.nome === nome);
    setUsuario(dadosUsuario)
    console.log('usuarios')
    console.log(dadosUsuario)
    }
  }

  function hasChild(father: number) {
    return usuario?.listMenuUsuario?.some((ichild: { menu: any }) =>
      menusRota.some(item =>
        item.menusInferiores.some(men => item.id === father && ichild.menu.id === men.id)
      )
    )
  }

  function renderChilds(idChild: number) {
    if (usuario !== undefined && usuario.listMenuUsuario !== undefined) {
      let array = usuario.listMenuUsuario.sort((a: any, b: any) => { return a.menu.nome < b.menu.nome ? -1 : a.menu.nome > b.menu.nome ? 1 : 0 });
      return array.map((ichild: { menu: any }) =>
        menusRota.map(item => {
          return item.menusInferiores.map((men: { id: any; nome: any; ordem?: number | undefined; rota: any; }) => {
            if (item.id === idChild && ichild.menu.id === men.id) {
              return renderMenu(men)
            }
          })
        }
        )
      )
    }
  }

  function renderMenu(menu: { id?: number; nome: any; ordem?: number; rota: any; }) {
    return <Link key={nome} style={{ textDecoration: 'none' }} to={menu.rota}>
      <ListGroup.Item key={nome}>
        <p style={{ margin: '0 0 0 20px' }}>{menu.nome}</p>
      </ListGroup.Item>
    </Link>
  }

  return (
    <>
      <Container>
        <IconButton onClick={handleShow} className="botao" >
          <MenuIcon />
        </IconButton>
      </Container>

      <Offcanvas show={show} onHide={handleClose} style={{zIndex: 12000}}>
        <Offcanvas.Header closeButton closeVariant='white' style={{ backgroundColor: 'rgb(19,52,131)' }} >
          <Offcanvas.Title onClick={handleClose} style={{ color: '#ffffff' }} >Menu Gscon</Offcanvas.Title>
        </Offcanvas.Header >
        <Offcanvas.Body>
          <Accordion defaultActiveKey="0">
            <ListGroup variant="flush">
              <Link style={{ textDecoration: 'none' }} to="/">
                <ListGroup.Item style={{
                  backgroundColor: 'rgb(19,52,131)',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}><FiHome color='#ffffff' />
                  <p style={{ margin: '0 20px', color: '#ffffff' }}>Home</p>
                </ListGroup.Item>
              </Link>
            </ListGroup>
            {hasChild(1) ?
              <Accordion.Item eventKey="0">
                <Accordion.Header>Usuários</Accordion.Header>
                <Accordion.Body style={{ padding: 0 }}>
                  <ListGroup variant="flush">
                    {renderChilds(1)}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
              : <></>
            }
            {hasChild(2) ?
              <Accordion.Item eventKey="1">
                <Accordion.Header>Configurações</Accordion.Header>
                <Accordion.Body style={{ padding: 0 }}>
                  <ListGroup>                   
                  {renderChilds(2)}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
              : <></>
            }
            {hasChild(3) ?
              <Accordion.Item eventKey="2">
                <Accordion.Header>Simulação</Accordion.Header>
                <Accordion.Body style={{ padding: 0 }}>
                  <ListGroup variant="flush">                    
                  {renderChilds(3)}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
              : <></>
            }

            {hasChild(4) ?
              <Accordion.Item eventKey="3">
                <Accordion.Header>Vendas</Accordion.Header>
                <Accordion.Body style={{ padding: 0 }}>
                  <ListGroup variant="flush">
                  {renderChilds(4)}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
              : <></>
            }
            {hasChild(5) ?
              <Accordion.Item eventKey="4">
                <Accordion.Header>BI</Accordion.Header>
                <Accordion.Body style={{ padding: 0 }}>
                  <ListGroup variant="flush">
                  {renderChilds(5)}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
              : <></>
            }
          </Accordion>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}