import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import api from '../services/api';

interface AuthState {
    token: string;
}

interface SignInCredentials{
    usuario: string;
    senha: string;
}

interface AuthContextData {
    signIn(credentials: SignInCredentials) : Promise<void>;
    signOut(): void;
}

interface JwtFormat{
    created: number;
    dadoUsuarioId: number;
    exp: number;
    parceiroId: number;
    role: string;
    sub: string;
    usuarioId: number;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
    const history = useHistory();

   const [data, setData] = useState<AuthState>(() => {
     const dataAtual = String(new Date( ).toLocaleString().substr(0, 10)); 
     const tokenDataAtual = localStorage.getItem('@Gscon:Date')

     if(dataAtual !== tokenDataAtual){
        localStorage.removeItem('@Gscon:Token')
        localStorage.removeItem('@Gscon:user')
        localStorage.removeItem('@Gscon:Date')
        localStorage.removeItem('@Gscon:Exp')
        localStorage.removeItem('@Gscon:UsuarioID')
     } 

     const token = localStorage.getItem('@Gscon:Token')
     const user = localStorage.getItem('@Gscon:user')
     const tokenData = localStorage.getItem('@Gscon:Date')
     const tokenUsuarioId = localStorage.getItem('@Gscon:UsuarioID')
     const tokenExp = localStorage.getItem('@Gscon:Exp')
     
     if (token && user) {
         if(dataAtual === tokenData){
        //  console.log(`dataAtual ${dataAtual}`)
        //  console.log(`datatoken ${tokenData}`)
         return { token, user }
         }
     }



     return {} as AuthState
   });

   const signIn = useCallback(async ({ usuario, senha }) => {
    console.log('chegou no singIn')
    let dataDia = String(new Date( ).toLocaleString().substr(0, 10));
    const response = await api.post('auth', {
           usuario,
           senha
       })

       const { token, usuarioAPI } = response.data[0];

       const tokenDados: JwtFormat = jwt_decode(token);

       localStorage.setItem('@Gscon:Token', token);
       localStorage.setItem('@Gscon:user', tokenDados.sub);
       localStorage.setItem('@Gscon:Date', dataDia);
       localStorage.setItem('@Gscon:Exp', tokenDados.exp.toString());
       localStorage.setItem('@Gscon:UsuarioID', tokenDados.usuarioId.toString());
       console.log('Teste chegada aqui'+usuarioAPI);
       setData({token});

       

   },[])

   const signOut = useCallback(() => {
    localStorage.removeItem('@Gscon:Token')
    localStorage.removeItem('@Gscon:user')
    localStorage.removeItem('@Gscon:Date')
    localStorage.removeItem('@Gscon:Exp')
    localStorage.removeItem('@Gscon:UsuarioID')
    history.push('/')
    setData({} as AuthState)
    
   },[])

   return (
       <AuthContext.Provider value= {{ signIn, signOut }}>
           {children}
       </AuthContext.Provider>
   );
} 

function useAuth(): AuthContextData {
   const context = useContext(AuthContext);
   
   if(!context){
       throw new Error('useAuth é necessário usar por volta o AuthProvider')
   }

   return context;
}

export { AuthProvider, useAuth }
