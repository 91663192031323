import styled from "styled-components";
import { Row as BRow, Col as BCol } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;

  .teste{
    align-items: right;
    width: 100%;
  }


  .MuiPaper-root{
    background-color: #ffffff;
    color: var(--blue);
    font-weight: bold;
  }

  .MuiTab-root{
    font-weight: bold;
  }

  .title{
    background-color: var(--blue);
    color: white;
    width: 100%;
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 60px;
    text-align: center;
    vertical-align: middle;
    padding-top: 10px;
  }

  .title1{
    background-color: var(--blue);
    color: white;
    width: 100%;
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 60px;
    text-align: center;
    vertical-align: middle;
    padding-top: 10px;
  }


  .Mui-selected{
    background-color: #ffffff;
    border-bottom: solid 3px;
    border-color: var(--blue);
    font-weight: bold;
    //z-index: 99;

    .borda{
      border: solid red 2px;
      margin-top: 400px;
    }

    .MuiBox-root{
      padding: none;
    }

    .tab-max-width {
      max-width: 160px;
    }

    .row-inputs {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const Row = styled(BRow)`
  ${props => (`
    margin: ${props.margin || 0}; 
    padding: ${props.padding || 0};
    display: ${props.flex || 'flex'};
    align-items: ${props.align || 'center'};
    justify-content: ${props.between ? 'space-between' : props.start ? 'flex-start' : props.end ? 'flex-end' : 'center'};
    flex-direction: ${props.column || 'row'};
    flex-wrap: ${props.nowrap ? 'nowrap' : 'wrap'}
  `)};
`;

export const Col = styled(BCol)`
  ${props => (`
    margin-top: ${props.top || '10px'};
    max-width: ${props.max || 'auto'};
    min-width: ${props.min || 'auto'}
  `)};
  ${props => 
  props.color ? `
  background-color: ${props.color}
  ` : ''}
`;

export const Formulario = styled.div`
  align-items: left;
`;

