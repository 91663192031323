export const cores = {
    "telefone1": "#1565c0",
    "telefone2": "#d84315",
    "telefone3": "#424242",
    "telefone4": "#2e7d32",
    "email1": "#2e7d32",
    "email2": "#424242",
    "email3": "#d84315",
    "email4": "#1565c0",
}
