
export const dadosPreVenda = 
    {
        id: 0,
        id_venda: 0,
        pessoa: "fisica",
        documento : "",
        documentoFormatado : "",
        bloqueado: false,
        idVendedor: 0,
        idTabela: 0,
        nomeVendedor: "",
        docVendedor: "",
        enderecoVendedor: "",
        numV: "",
        bairroVendedor: "",
        cepVen: "",
        cidadeVendedor: "",
        UFV: "",
        telVend: "(16) 3707-5500",
        codB: 0,
        descricaoBem: "",
        valorCredito: 0,
        valorCreditoF: "",
        prazo: 0,
        tipoBem: "",
        descTipo: "",
        indiceCorrecao: "",
        renda: 0,
        rendaReal: 0,
        rendaFormatado: "",
        grupo: "",
        numAssembleiaAtual: 0,
        numAssembleiaFinal: 0,
        qtdAssembleia: 0,
        termos: 0, 
        planoBasico: 0,
        numeroMax: 0,
        adesao: 0,
        reducao: 0,
        adesaoX:  "",
        reducaoX: "",
        sequenciaAgrupamento: 0,
        taxaAdministracao: 0,
        fundoComumTotal: 100,
        segVS: "",
        segVN: "--",
        dataAdesao: "",
        dataAdesaoF: "",
        primeiraAssembleia: "",
        primeiraAssF: "",
        planoVendaDescricao: "",
        seqAgrupLinha01Meses: "",
        seqAgrupLinha01FC: "",
        seqAgrupLinha01TX: "",
        seqAgrupLinha02Meses: "",
        seqAgrupLinha02FC: "",
        seqAgrupLinha02TX: "",
        seqAgrupLinha03Meses: "",
        seqAgrupLinha03FC: "",
        seqAgrupLinha03TX: "",
        seqAgrupLinha04Meses: "",
        seqAgrupLinha04FC: "",
        seqAgrupLinha04TX: "",
        nomeCliente: "",
        nomeFantasia: "",
        dataNascimento: "",
        idade: "0",
        dataNascimentoF: "",
        sexo: "",
        documento2: "",
        isentoPJ: "",
        orgEm: "",
        orgaoEm: "",
        ufe: "",
        dataExpedicao: "",
        dtEF: "",
        nacionalidade: "",
        naturalUF: "",
        naturalCidade: "",
        estadoCivil: "",
        profissao: "",
        nomePai: "",
        nomeMae: "",
        capitalSocial: 0,
        inscricaoEstadual: "",
        isento: "",
        isentoChecked: false,
        cep: "",
        enderecoCobranca: "RES",
        endereco: "",
        numero: "",
        bairro: "",
        apto: "",
        complemento: "",
        uf: "",
        cidade: "",
        idTelefone: 1,
        telefone1: "",
        telefone2: "",
        telefone3: "",
        telefone4: "",
        idEmail: 1,
        email1: "",
        email2: "",
        email3: "",
        email4: "",
        endResCob: 1, 
        nomeEmpresa: "",
        autonomo: 0,
        cepCom: "",
        enderecoCom: "",
        numeroCom: "",
        bairroCom: "",
        aptoCom: "",
        complementoCom: "",
        estadoCom: "",
        cidadeCom: "",
        idTelefoneCom: 1,
        telefoneCom1: "",
        telefoneCom2: "",
        telefoneCom3: "",
        telefoneCom4: "",
        idEmailCom: 1,
        emailCom1: "",
        emailCom2: "",
        emailCom3: "",
        emailCom4: "",
        endResCobCom: 2,
        documentoSocio: "",
        nomeSocio: "",
        dataNascimentoSocio: "",
        dataNascimentoSocioFormatada: "",
        telefoneDDDCom: "(X) Não desejo informar conta bancária para depósitos dos recursos relativos a devolução de valores!",
        telefoneCom: "",
        informaConta: 0,
        tipoConta: "ContaCorrente",
        codBanco: 0,
        banco: "",
        agencia: "",
        conta: "",
        digito: "",
        tipoPix: "",
        chavePix: "",
        t_p1: 0,
        t_p2: 0,
        atividadeRemunerada: "",
        t_p3: 0,
        t_p4: 0,
        t_p5: 0,
        t_p6: 0,
        t_p7: 0,
        valorA: "0",
        t_p8: 0,      
        tempoFuncao: "(X)Não desejo informar conta bancária para depósitos dos recursos relativos a devolução de valores!",
        t_p9: 0,
        t_p10: 0,
        t_p11: 0,
        t_p12: 0,
        capitalSocialFormulario: 0,
        capitalSocialFormularioF: "",
        faturamento: 0,
        faturamentoF: "",
        lucroMensal: 0,
        lucroMensalF: "",
        b1:"",
        b2:"",
        b3:"",
        b4:"",
        b5:"",
        b6:"",
        b7:"",
        b8:"",
        b9:"",
        bOutro: "",
        contratarSeguro: 0,
        indenizarLegislacao: 0,
        recibo: 2,
        valorAdesao: 0,
        valorAdesaoReal: 0,
        valorAdesaoFormatado: "",
        valorParcela: 0,
        valorParcelaFormatado: "",
        valorParcelaComSeguro: 0,
        valorParcelaComSeguroFormatado: "",
        valorTotal: "",
        valorTotalF: "",
        valorTotalExtenso: "",
        idTabelaVendas: 0,
        idBem: 0,
        codigoGrupoBem: 0,
        parcela: 0,
        parcelaSeguro: 0,
        mediaLance: 0,
        numeroMaximoCotas: 0,
        tipoPagamento: "",
        reciboAdesao: 0,
        reciboAdesaoFormatado: "",
        reciboPrimeiraParcela: 0,
        reciboPrimeiraParcelaFormatado: "",
        reciboValorTotal: 0,
        reciboValorTotalFormatado: "",
        reciboValorExtenso: "",
        teste: 0,
        etapa1: false,
        etapa2: true,
        etapa3: true,
        etapa4: true,
        etapa5: true,
        etapa6: true,
        etapa7: true,
        visualizaBens: false,
        rendaAprovada: false,
        template: "NTA1NzY=",
        tipoBemDescricao: "",
        dataAdesaoFormatada: "",
        primeiraAssembleiaFormatado: "",
        dataNF: null,
        dataDE: null,
        age3: null,
        age1: null,
        age5:null,
        emailGeral: "",
        idPreVenda: 0,
        status: "PENDENTE", 
        posVenda: "PENDENTE",
        dataReajuste: "",
    }
