import styled from 'styled-components';
import background from '../../assets/background.png';
import { shade } from 'polished';

export const Container = styled.div`
    box-sizing: 0;
    height: 100vh;
    display: flex;
    align-items: stretch;
    background: url(${background}) no-repeat;
    background-size: cover;
`;

export const Content = styled.div`
   display: flex;
   flex-direction: column;
   place-content: center;
   align-items: center;
   width: 100%;
   //max-width: 1024px;

   img {
       width: 340px;
   }

   form {
       margin: 80px 0;
       text-align: center;
       display: flex;
       flex-direction: column;
       align-items: center;

       h1 {
           margin-bottom: 24px;
           font-size: 20px;
           color: #f4ede8;
       }

       a {
           color: #f4ede8;
           display: block;
           margin-top: 24px;
           text-decoration: none;

           &:hover {
             color: ${shade(0.5, '#363f5f')};
           }
       }
   }
   
`
