import { useCallback, useState } from "react"
import { GetGrupoUsuarios } from '../services/GrupoUsuarios';
import { GrupoUsuarios } from '../interfaces/grupoUsuarios';


const GruposUsuarios = () => {
    const [grupoUsuariosLista, setGrupoUsuarios] = useState<GrupoUsuarios[]>([]);
    
    const getAll = useCallback(async () => {
        const { status, data } = await GetGrupoUsuarios.getAll();

        if(status !== 200) throw new Error();

        setGrupoUsuarios(data) 
    
    },[]);

    const createGrupoUsuarios = useCallback(async (grupoUsuarios: Pick<GrupoUsuarios, 'nome' | 'listMenuGrupoUsuario' >) => {
        const { status, data } = await GetGrupoUsuarios.createGrupoUsuarios(grupoUsuarios);
        
        if(status !== 201) throw new Error();

    }, [])

    const alteraGrupoUsuarios = useCallback(async (grupoUsuarios: GrupoUsuarios) => {
        const { status, data } = await GetGrupoUsuarios.alteraGrupoUsuarios(grupoUsuarios);
        
        if(status !== 201) throw new Error();

    }, [])


    return {
        grupoUsuariosLista,
        getAll,
        createGrupoUsuarios,
        alteraGrupoUsuarios,
    }
}

export { GruposUsuarios }