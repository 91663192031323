import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas, Botao, PrimeiraLetra, CardContainer } from './styles';
import { Header } from '../../components/Header';
import { FormHandles } from '@unform/core';
import { FiPlus, FiSave } from 'react-icons/fi';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { GruposTodos } from '../../hooks/grupos';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import { Grupos as GruposInterface } from '../../interfaces/grupos';
import { GrupoSede } from '../../interfaces/grupoSede';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { SeguradoraTodos } from '../../hooks/seguradoras';
import { Seguradoras } from '../../interfaces/seguradoras';
import { FiX } from 'react-icons/fi';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Theme, useTheme } from '@mui/material/styles';


import FormControl from '@mui/material/FormControl';
import { ModelTrainingRounded } from '@mui/icons-material';
import { SeguroGrupos } from '../../interfaces/seguroGrupos';


const Grupos: React.FC = () => {
  const history = useHistory();
  const idCargo = useRef(null);
  const { grupoLista, getAll, getAllGruposSede, grupoSedeLista } = GruposTodos();
  const seguroTodos = SeguradoraTodos();
  const [listaSegTodos, setListaSegTodos] = useState<Seguradoras[]>(seguroTodos.seguradoraLista);
  const [checked, setChecked] = React.useState(false);
  const [grupo, setGrupo] = React.useState<number>();
  const [listaSearchCargo, setListaSearchCargo] = useState<GruposInterface[]>(grupoLista);
  const [listaSegGrupo, setListaSegGrupo] = useState<SeguroGrupos[]>(seguroTodos.seguroGrupoLista)
  const [grupoSede, setGrupoSede] = useState<GrupoSede[]>(grupoSedeLista)
  const [opcao, setOpcao] = useState([])
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);

  const { addToast } = useToast();

  const [listaSegurosGrupo, setListaSegurosGrupo] = useState<SeguroGrupos[]>();


  const [model, setModel] = useState<GrupoSede>({
    estado: '',
    cidade: ''
  })


  function editCargo(id: number) {
    history.push(`/cargoCadastro/${id}`)
  }

  function novoCargo() {
    history.push(`/cargoCadastro/`)
  }

  function incluirSeguro() {
    if (model.codigoGrupo === 0) {
      addToast({
        type: 'info',
        title: 'Selecione um Grupo',
        description: 'Para adicionar um seguro, selecione um grupo!'
      })
      return
    }
    history.push(`/gruposCadastro/${model.codigoGrupo}`)
  }

  /*function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    const lista: Grupos[] = grupoLista.filter(item => event.target.value ? item.nome.toLowerCase().includes(event.target.value.toLowerCase()) : item);
    setListaSearchCargo(lista);
  }*/

  useEffect(() => {
    getAll();
    getAllGruposSede()
    seguroTodos.getAllSegurosGrupo()
    seguroTodos.getAll()
  }, [getAll, getAllGruposSede, seguroTodos.getAllSegurosGrupo, seguroTodos.getAll])

  useEffect(() => {
    setListaSearchCargo(grupoLista)
    setGrupoSede(grupoSedeLista)
    setListaSegGrupo(seguroTodos.seguroGrupoLista)
    setListaSegTodos(seguroTodos.seguradoraLista)
  }, [grupoLista, grupoSedeLista, seguroTodos.seguroGrupoLista, seguroTodos.seguradoraLista])


  async function handleGrupo(e: ChangeEvent<HTMLSelectElement>) {

    const response = grupoSedeLista.find(item => item.codigoGrupo === parseInt(e.target.value))
     buscaSeguroGrupo(parseInt(e.target.value))
 
 
     if (response != undefined) {
       setModel(response)
     } else {
       setModel({
         estado: '',
         cidade: '',
         codigoGrupo: parseInt(e.target.value)
       });
     }
  }

  async function buscaSeguroGrupo(id: number) {
    const response = await api.get<SeguroGrupos[]>('/v1/grupos-seguradora')
    setListaSegurosGrupo(response.data)
    const listaSeg = response.data.filter(item => item.codigoGrupo === id)
    setListaSegurosGrupo(listaSeg);
  }

  function handleEstado(e: ChangeEvent<HTMLSelectElement>) {
    console.log(e.target.value)
    //const response = await api.get('/v1/grupos-sede');
    setModel({
      ...model,
      estado: e.target.value
    });
  }

  async function salvar() {
    if (model.codigoGrupo === 0) {
      addToast({
        type: 'info',
        title: 'Selecione um Grupo',
        description: 'Para salvar alterações selecione um grupo!'
      })
      return
    }

    const response = grupoSedeLista.find(item => item.codigoGrupo === model.codigoGrupo)

    if (response != undefined) {
      const result = await api.put(`/v1/grupos-sede/${response.id}`, { id: response.id, codigoGrupo: model.codigoGrupo, estado: model.estado, cidade: model.cidade })
      if (result.status === 200) {
        addToast({
          type: 'success',
          title: 'Dados Salvos',
          description: 'Dados foram salvos com sucesso!'
        })

        getAllGruposSede();
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao Salvar',
          description: 'Ocorreu um erro ao tentar salvar as informações, favor informar ao TI do consórcio!'
        });
      }
    } else {
      const result = await api.post('/v1/grupos-sede', model)

      if (result.status === 200) {
        addToast({
          type: 'success',
          title: 'Dados Salvos',
          description: 'Dados foram salvos com sucesso!'
        })
        getAllGruposSede();
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao Salvar',
          description: 'Ocorreu um erro ao tentar salvar as informações, favor informar ao TI do consórcio!'
        });
      }
    }

  }

  function handleCidade(e: ChangeEvent<HTMLInputElement>) {
    setModel({
      ...model,
      cidade: e.target.value
    });
  }

  function addZeroes(num: number, len: number) {

    var numberWithZeroes = String(num);
    var counter = numberWithZeroes.length;

    while (counter < len) {

      numberWithZeroes = "0" + numberWithZeroes;

      counter++;

    }

    return numberWithZeroes;

  }

  async function deletar(id: number | undefined) {
    try {
      if (id != null) {

        const responseDel = await api.delete(`/v1/grupos-seguradora/${id}`)

        if (model.codigoGrupo != undefined)
          buscaSeguroGrupo(model.codigoGrupo)

        console.log(seguroTodos.seguroGrupoLista)
        addToast({
          type: 'info',
          title: 'Deletado com sucesso!',
          description: 'Plano foi deletado com sucesso!'
        })
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao tentar salvar!',
          description: 'Informe este erro ao TI do consórcio!'
        })
      }

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao tentar salvar!',
        description: 'Informe este erro ao TI do consórcio!'
      })
    }
  }

  function verificar(){
    if(!model.codigoGrupo){
    return(
      <option></option>
    )
  }
  }

  return (
    <>
      <Header />
      <Container>
        <div className='title'><h2>Grupos</h2></div>
        <CardContainer>



          <Form.Group className="select">
            <div>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" className="labelNative">
                Grupos
              </InputLabel>

              <Row>
                <Col>
                  <NativeSelect
                    className="inputNovo"
                    value={model.codigoGrupo}
                    onChange={handleGrupo}
                    defaultValue=""
                    inputProps={{
                      name: 'cargo',
                      id: 'uncontrolled-native',
                    }}
                  >
                    {verificar()
                     }
                    {grupoLista.map(item => {
                      if (item.codigoSituacao === 'A' || item.codigoSituacao === 'F' ) {
                        return (
                          <option
                            id={item.codigoGrupo.toString()}
                            value={item.codigoGrupo}>

                            {item.codigoGrupo}
                          </option>
                        )
                      }
                    })

                    }



                  </NativeSelect>

                  {/*  <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={model.codigoGrupo}
                    onChange={handleChange}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}
                  >
                    {grupoLista.map(item => {
                      if (item.codigoSituacao === 'A') {
                        return (
                          <MenuItem
                            key={item.codigoGrupo}
                            id={item.codigoGrupo.toString()}
                            value={item.codigoGrupo}>

                            {item.codigoGrupo}
                          </MenuItem>
                        )
                      }
                    })

                    }
                  </Select>*/}

                </Col>
              </Row>
            </div>

            {/*  <Row>
              <Col>
                <FormControl className="inputNovo1" variant="standard" sx={{ m: 1, mt: 3, width: '20ch' }}>
                  <NativeSelect
                    className="inputNovo1"
                    value={model.estado}
                    onChange={handleEstado}
                    inputProps={{
                      name: 'cargo',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option label="Estado" value="todos">Estado</option>
                    <option label="AL" value="AL">AL</option>
                    <option label="AM" value="AM">AM</option>
                    <option label="AP" value="AP">AP</option>
                    <option label="BA" value="BA">BA</option>
                    <option label="CE" value="CE">CE</option>
                    <option label="DF" value="DF">DF</option>
                    <option label="ES" value="ES">ES</option>
                    <option label="GO" value="GO">GO</option>
                    <option label="MA" value="MA">MA</option>
                    <option label="MG" value="MG">MG</option>
                    <option label="MS" value="MS">MS</option>
                    <option label="MT" value="MT">MT</option>
                    <option label="PA" value="PA">PA</option>
                    <option label="PB" value="PB">PB</option>
                    <option label="PE" value="PE">PE</option>
                    <option label="PI" value="PI">PI</option>
                    <option label="PR" value="PR">PR</option>
                    <option label="RJ" value="RJ">RJ</option>
                    <option label="RN" value="RN">RN</option>
                    <option label="RO" value="RO">RO</option>
                    <option label="RR" value="RR">RR</option>
                    <option label="RS" value="RS">RS</option>
                    <option label="SC" value="SC">SC</option>
                    <option label="SE" value="SE">SE</option>
                    <option label="SP" value="SP">SP</option>
                    <option label="TO" value="TO">TO</option>
                  </NativeSelect>
                </FormControl>
              </Col>
              <Col>
                <FormControl className="inputNovo2" variant="standard" sx={{ m: 1, mt: 1 }}>
                  <TextField
                    className='inputNovo2'
                    id="standard-required"
                    label="Cidade"
                    variant="standard"
                    name='codigo'
                    onChange={handleCidade}
                    value={model.cidade}
                  //onChange={handleChangeInput}

                  />
                </FormControl>
              </Col>
            </Row>*/}
            <Row>
              <Col className="inputNovo3">
                <Botao>Seguros   <Button className="botao1" onClick={incluirSeguro}><FiPlus /></Button></Botao>
              </Col>

            </Row>
            <div className='ListaGeral'>

              {listaSegurosGrupo?.map(e => {
                const response = seguroTodos.seguradoraLista.find(item => item.codigoSeguradora === e.codigoSeguradora)
                let seguradora = response?.codigoSeguradora.toString();
                if (seguradora != null && response != null) {
                  seguradora = addZeroes(response.codigoSeguradora, 2)
                }
                return (
                  <Row>
                    <Col xs={1}>
                      <b>{seguradora}</b>
                    </Col>
                    <Col xs={6}>
                      {response?.nome}
                    </Col>

                    <Col xs={3}>
                      {response?.percentualSegVida} (Vida)
                    </Col>
                    <Col xs={1}>
                      <div className="deletar"><FiX onClick={() => deletar(e.id)} style={{ fontSize: '15px;', cursor: 'pointer' }}></FiX></div>
                    </Col>
                  </Row>
                )


              })}

            </div>
            <Form.Group className="buttonsCargo">

              <Button>Cancelar</Button>
              <Button type="submit" onClick={salvar}>Salvar</Button>
            </Form.Group>

          </Form.Group>





          {/*} <Botao>
          <Button variant="success" onClick={novoCargo} style={{ border: 'none', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems: "center" }}><FiSave size={30} /></Button>
          </Botao>*/}


        </CardContainer>
      </Container>
    </>
  )
}

export default Grupos