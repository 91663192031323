import { useCallback, useState } from "react"
import { GetNivel } from '../services/Nivel';
import { Nivel } from '../interfaces/nivel';


const Niveis = () => {
    const [nivelLista, setNivel] = useState<Nivel[]>([]);
    
    const getAll = useCallback(async () => {
        const { status, data } = await GetNivel.getAll();
        const ordenado = data.sort(function(a,b){return a.nome.toUpperCase() < b.nome.toUpperCase() ? -1: a.nome.toUpperCase() > b.nome.toUpperCase()? 1:0})


        if(status !== 200) throw new Error();

        setNivel(ordenado) 
    
    },[]);

    const createNivel = useCallback(async (Nivel: Pick<Nivel, 'nome' | 'ativo'>) => {
        const { status, data } = await GetNivel.createNivel(Nivel);
        
        if(status !== 201) throw new Error();

    }, [])

    const alteraNivel = useCallback(async (Nivel: Nivel) => {
        const { status, data } = await GetNivel.alteraNivel(Nivel);
        
        if(status !== 201) throw new Error();

    }, [])


    return {
        nivelLista,
        getAll,
        createNivel,
        alteraNivel,
    }
}

export { Niveis }