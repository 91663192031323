import api from './api';
import { Grupos } from '../interfaces/grupos';
import { GrupoSede } from '../interfaces/grupoSede';

const getAllGrupos = () => api.get<Grupos[]>('/v1/grupos')

const getAllGruposSede = () => api.get<GrupoSede[]>('/v1/grupos-sede')

//const createGrupoUsuarios = (grupoUsuarios: Pick<Grupod, 'nome' | 'listMenuGrupoUsuario'>) => api.post('/v1/grupos-usuario', grupoUsuarios)

//const alteraGrupoUsuarios = (grupoUsuarios: GrupoUsuarios) => api.put(`/v1/grupos-usuario/`, grupoUsuarios)

//const deleteGrupoUsuarios = (id: number) => api.delete(`/v1/grupos-usuario/${id}`)

export const GetGrupo = {
    getAllGrupos,
    getAllGruposSede,
    //createGrupoUsuarios,
    //alteraGrupoUsuarios,
    //deleteGrupoUsuarios,
}