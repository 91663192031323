import api from './api';
import { Planos } from '../interfaces/planos';
import { PlanosOrder } from '../interfaces/planosOrder';

const getAll = () => api.get<Planos[]>('/v1/sequencias-agrupamento')

const getAllAtivos = () => api.get<Planos[]>('/v1/sequencias-agrupamento/ativo')

const getAllAtivosOrd = () => api.get<PlanosOrder>('/v1/sequencias-agrupamento-interno/paginado?page=0&size=9999&sort=codigoTipoVenda,asc')


export const GetPlanos = {
    getAll,
    getAllAtivos,
    getAllAtivosOrd
}