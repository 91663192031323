import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    margin: 20px;
    flex-direction: column;
    
    position:relative;
    align-items:center;

    
    .voltar{
        font-size: 30px;
        cursor: pointer;
      }

    .inputCargo{
        width: 900px;
        margin-top: 30px;
    }

    .buttonsCargo{
        display: flex;
        margin-top: 35px;
        justify-content:flex-end;
        
        Button{
            margin-left: 10px;
            background-color: var(--blue);;
            min-width: 100px;
            border: none;
        }
        
       
    }

    .buttonsCargo1{
        display: flex;
        justify-content:flex-end;
        
        Button{
            margin-left: 10px;
            background-color: var(--blue);;
            min-width: 100px;
            border: none;
        }
        
       
    }

    .checkCargo{
        margin-top: 20px;
    }

    .form-border{
        border-width: 1px;
        border-color: #cccccc;
        border-style: solid;
        padding: 20px;
        border-radius: 2px;
    }

    .tituloTabela{
        color: var(--blue);
        margin-top: 30px;
        margin-bottom: 20px;
    }
`;

export const CardContainer = styled.div`
    display:flex;
    flex:1;
    align-items:center;
    justify-content:space-between;
    max-width: 1000px;
    width: 900px;
    flex-direction: column;
   
    top:-10px;
    position: absolute;
    background-color:#ffffff;
    z-index: 50;
    margin-top: 50px;

    .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 900px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

   }

   .codFunc{
       margin-top: 8px;
   }

   .inputNovo{
       margin-top: 40px;
       width: 312px;
       
   }

   .inputNovo2{
    margin-top: 40px;
       //width: 300px;
       
   }

   .inputNovo1{
    width: 100%;
    width: 520px;
   }

   .tit{
       font-size: 13px;
   }


`;

export const Cards = styled.div`
   display: flex;
    margin-top: 20px;
    max-width: 900px;
    flex-wrap: wrap;

    .MuiCardContent-root{
        padding: 0px;
    }

    .cabecalhoCard{
       color: #ffffff;
       background: var(--blue);
       width: 100%;

    }

   .spam{
           display: flex;
           align-items: center;
           margin-left: 12px;
           height: 40px;
    }

    .informacoes{
        margin-left: 12px;
        margin-right: 8px;
    }

    .valor{
        color: black;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .barra{
        border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #a7a6a6;
    margin-bottom: 10px;
    }

    .percentual{
        margin-bottom: 10px;
        background: #f1efef;
        border-radius: 5%;
    }

    .tituloTabela{
        color: var(--blue);
        margin-top: 30px;
    }

    


`;

export const Tabelas1 = styled.div`
    max-width: 900px;
    font-size: 14px;
    border-left: solid 0.5px;
    border-left-color: #eeebeb;
    border-right: solid 0.5px;
    border-right-color: #eeebeb;
    //box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);

    .semSeguro{
        font-size: 10px;
        color: #b03232;
    }

    .comSeguro{
        font-size: 10px;
        color: var(--blue);
    }

    .meses{
        border: none;
    }

    .nome{
        max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
    }

    th, td{
        border: none;
    }

    td, th{
       white-space: nowrap;
    }

    .quebraLinha{
        white-space: nowrap;
    }

    .ac{
       text-align: center;
       align-items: center;
       vertical-align: middle;
    }

    .ac1{
       text-align: center;
       align-items: center;
       vertical-align: middle;
    }
   

   .tabela{
     vertical-align: center;
     
   }

   .celula{
      display: flex;
      align-items: center;

   }

   .del{
       cursor: pointer;
   }

   .linha{
      /* height: 60px; */
      vertical-align: center;
      border-bottom: none;
   }

   .esquerda{
       padding-left: 60px;
   }

   .esquerda1{
       padding-left: 20px;
   }
   
   .pesquisa{
      width: 100%;
      //height: 40px;
      writing-mode: horizontal-tb !important;
      //text-rendering: auto;
      color: -internal-light-dark(black, white);
      //letter-spacing: normal;
      //word-spacing: normal;
      //line-height: normal;
      //text-transform: none;
      text-indent: 0px;
      text-shadow: none;
      //display: inline-block;
      text-align: start;
      //appearance: auto;
      //-webkit-rtl-ordering: logical;
      //cursor: text;
      //background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
      //margin: 0em;(16)991113810 Zé Emax | (16)99460-1450 Max Pat | (16) 99242-1876
   }
`;