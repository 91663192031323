import React, { ChangeEvent, useEffect, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { EtapaTeste } from '../../../components/Etapa2';
import { Container, CardContainer } from './styles';
import { Button, Form, Row, Col } from 'react-bootstrap';
import api from '../../../services/api';
import { Tabela } from '../../../interfaces/tabela';
import { TiposBens } from '../../../interfaces/tiposBem';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import { Tabelas } from '../../../hooks/tabelas';
import { GruposTodos } from '../../../hooks/grupos';
import { FiArrowLeft, FiPlus } from 'react-icons/fi';
import { useToast } from '../../../hooks/toast';
import { Output } from '@mui/icons-material';


const Etapa1: React.FC = () => {
    
	const { addToast } = useToast();
	const history = useHistory();
	const { id } = useParams() as any
	const [tipoBem, setTipoBem] = useState<TiposBens[]>([]);
	const { getAllTiposBem, tiposBem } = Tabelas();
	const [listaTipoBem, setListaTipoBem] = useState<TiposBens[]>(tiposBem);
	const [grupo, setGrupo] = useState<number>();
	const { grupoLista, getAll } = GruposTodos();
	const [IdTabela, setIdTabela] =  useState<number>();
	const [idBemSelecionado, setIdBemSelecionado] = useState<number>();

	useEffect(() => {
		getAllTiposBem();
		getAll();
	}, [getAllTiposBem])

	useEffect(() => {
		if (id !== undefined) {
			findTabela(id)
			setIdTabela(id)
		}
	}, [])

	useEffect(() => {
		setListaTipoBem(tiposBem)
	}, [tiposBem])

	const [model, setModel] = useState<Omit<Tabela, "id">>({
		codigoGrupo: 0,
		codigoGrupoBem: 0,
		nome: ''
	})


	function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
		setModel({
			...model,
			[e.target.name]: e.target.value
		});
	}


	async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
		e.preventDefault()
		if (id !== undefined) {
			if(model.codigoGrupo > 0 && model.codigoGrupoBem > 0 && model.nome !== ''){
			  const response = await api.put(`/v1/tabelas-venda/${id}`, { nome: model.nome, codigoGrupoBem: model.codigoGrupoBem, codigoGrupo: model.codigoGrupo, id: id })
			  addToast({
				type: 'success',
				title: 'Sucesso ao salvar',
				description: 'Tabela alterada com sucesso!'
			  });
			}else{
				addToast({
					type: 'error',
					title: 'Erro ao salvar',
					description: 'Faltou preencher campos, tabela não foi alterada!'
				});
			}
		} else {
			if(model.codigoGrupo > 0 && model.codigoGrupoBem > 0 && model.nome !== ''){
				console.log(model)
            	const response = await api.post(`/v1/tabelas-venda`, { nome: model.nome, codigoGrupoBem: model.codigoGrupoBem, codigoGrupo: model.codigoGrupo })
				console.log(response)
				setIdTabela(response.data[0].id)

				addToast({
					type: 'success',
					title: 'Sucesso ao salvar',
					description: 'Tabela inserida com sucesso!'
				});
			}else{
				addToast({
					type: 'error',
					title: 'Erro ao salvar',
					description: 'Faltou preencher campos, tabela não foi salva!'
				});
			}
		}
		//back();
	}

	async function findTabela(id: string) {
		const response = await api.get(`/v1/tabelas-venda/${id}`);		
		setModel({
			nome: response.data[0].nome,
			codigoGrupoBem: response.data[0].codigoGrupoBem,
			codigoGrupo: response.data[0].codigoGrupo,
		})

	}


	function back() {
		history.goBack()
	}

	function handleChangeSelected(e: ChangeEvent<HTMLSelectElement>) {
		setModel({
			...model,
			codigoGrupoBem: parseInt(e.target.value)
		})
	}

	async function handleGrupo(e: ChangeEvent<HTMLSelectElement>) {

		setModel({
			...model,
			codigoGrupo: parseInt(e.target.value)
		});
	  }

	  function verificar(){
		if(!grupo){
		return(
		  <option></option>
		)
	  }
	  }

	  function voltar(){
		history.goBack()
	  }


	return (
		<>
			<Header />
			<Container>
				<CardContainer>
					<div className='title'><FiArrowLeft onClick={voltar} className='voltar1'/><h2>Nova Tabela</h2><div></div></div>
					<Form className="form-border" onSubmit={onSubmit}>
						<Row>
							<Col>
								<Form.Group className="inputCargo">
									<Form.Label>Nome Tabela</Form.Label>
									<Form.Control

										type="text"
										name="nome"
										placeholder="Nome Tabela"
										value={model.nome}
										onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group className="inputNovo">
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Tipo
									</InputLabel>
									<NativeSelect
									    className="inputNovo1"
										defaultValue={30}
										onChange={handleChangeSelected}
                                        value={model.codigoGrupoBem}
										inputProps={{
											name: 'age',
											id: 'uncontrolled-native',
										}}
									>
										<option id="0" value="0"></option>
										{listaTipoBem.map((item) =>
											<option id={item.id?.toString()} value={item.codigoGrupoBem}>{item.descricao}</option>
										)}
									</NativeSelect>
								
								
				</Form.Group>
							</Col>
							<Col>
							<Form.Group className="inputNovo">
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Selecione o Grupo
									</InputLabel>
								<NativeSelect
								    className="inputNovo1"
									value={model.codigoGrupo}
									onChange={handleGrupo}
									defaultValue=""
									inputProps={{
										name: 'cargo',
										id: 'uncontrolled-native',
									}}
								>
									{verificar()
									}
									{grupoLista.map(item => {
										if (item.codigoSituacao === 'A' || item.codigoSituacao === 'F') {
											return (
												<option
													id={item.codigoGrupo.toString()}
													value={item.codigoGrupo}>

													{item.codigoGrupo}
												</option>
											)
										}
									})

									}



								</NativeSelect>
								</Form.Group>
							</Col>
						</Row>

						
						<Form.Group className="buttonsCargo">
							<Button onClick={back}>Cancelar</Button>
							<Button type="submit">Salvar</Button>
						</Form.Group>
					</Form>

					{IdTabela?
                       <EtapaTeste Tabela={IdTabela}/>:<></>
					}
					
				</CardContainer>
			</Container>
		</>
	)
}

export default Etapa1