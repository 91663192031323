import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErros';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';

import { FiMail, FiArrowLeft, FiUser} from 'react-icons/fi';
import { MdSecurity, MdPassword } from 'react-icons/md';
import { Container, Content } from './styles';
import logo from '../../assets/logo.svg';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useToast } from '../../hooks/toast';
import { AxiosError } from 'axios';

const ChangePassword: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  let handleSubmit = async (data:object) => {
     try {
       
      formRef.current?.setErrors({});

       const schema = Yup.object().shape({
         usuario: Yup.string().required('Usuário obrigatório'),
         token: Yup.string().required('Pin obrigatório'),
         senhaNova: Yup.string().required('Senha obrigatória')
       });

       await schema.validate(data, {
         abortEarly: false,
       })

       const envio = await api.post('/v1/usuarios/alterar-senha', data)

       if(envio.status === 200){
        addToast({
          type: 'success',
          title: 'Senha Alterada!',
          description: 'Alteração realizada com sucesso!'
        })    
       }else{
        addToast({
          type: 'error',
          title: 'Senha não Alterada!',
          description: 'Favor enviar um email para ti@consorciogroscon.com.br!'
        })
       }

       history.push('/');
       
     } catch (err) {
      const error = err as AxiosError
      let erroMensagem = error.response?.data.developerMessage

      if(error.response?.status === 404)
      erroMensagem = 'Usuário não existe'

      addToast({
        type: 'error',
        title: 'Erro ao alterar a senha!',
        description: erroMensagem
      })
      
      if(err instanceof Yup.ValidationError){
       const errors = getValidationErrors(err);
      
       formRef.current?.setErrors(errors);

       console.log(err.message)
      }
     }
  };

  return (
    <>
    <Container>
        <Content>
          <img src={logo} alt='GScon' />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1> Altera sua senha </h1>

            <Input 
              name="usuario" 
              icon={FiUser} 
              placeholder="Usuário do sistema" />
 
            <Input 
              name="token" 
              icon={MdSecurity} 
              placeholder="Pin recebido por email" />

            <Input 
              name="senhaNova" 
              type="password"
              icon={MdPassword} 
              placeholder="Nova senha" />
              
            <Button type="submit">Enviar</Button>
          </Form>
        </Content>
    </Container>
    </>
  )
};

export default ChangePassword;