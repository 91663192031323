import React, { useRef, useCallback, useState, ChangeEvent, useEffect } from 'react';
import { Container, Tabelas, ButtonNew, PrimeiraLetra, Title } from './styles';
import { Header } from '../../components/Header';
import { FormHandles } from '@unform/core';
import { FiPlus } from 'react-icons/fi';
import { Table, Button } from 'react-bootstrap';
import { GruposUsuarios } from '../../hooks/grupoUsuarios';
import { useHistory } from 'react-router-dom';
import { GrupoUsuarios as GrupoUsuariosInterface } from '../../interfaces/grupoUsuarios';
import { TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationGrupoUsuarios';

const GrupoUsuarios: React.FC = () => {
  const history = useHistory();
  const idGrupoUsuarios = useRef(null);
  const { grupoUsuariosLista, getAll, alteraGrupoUsuarios, createGrupoUsuarios } = GruposUsuarios();
  const [modalShow, setModalShow] = useState(false);
  const [listaSearchGrupoUsuaios, setSearchListaGrupoUsuarios] = useState(grupoUsuariosLista);
  const formGURef = useRef<FormHandles>(null);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchGrupoUsuaios.length / PER_PAGE);
  const _DATA = usePagination(listaSearchGrupoUsuaios, PER_PAGE);
  console.log(_DATA)
  

  const handleChangePagination = (event: object, e:number) => {
    console.log(e)
    setPage(e);
    _DATA.jump(e);
  };



  function editGrupoUsuarios(id?: number) {
    history.push(`/grupoUsuariosCadastro/${id}`)
  }

  function novoGrupoUsuarios() {
    history.push(`/grupoUsuariosCadastro/`);
  }

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    const lista: GrupoUsuariosInterface[] = grupoUsuariosLista.filter(
      item =>
        event.target.value ?
          item.nome?.toLowerCase().includes(event.target.value.toLowerCase())
          : item
    );
    setSearchListaGrupoUsuarios(lista);

    setPage(1)
    _DATA.jump(1);
  }

  useEffect(() => {
    getAll();
  }, [getAll])

  useEffect(() => {
    setSearchListaGrupoUsuarios(grupoUsuariosLista);
  }, [grupoUsuariosLista])

  function randomDarkRGB() {
    var r = Math.floor(Math.random() * 156);
    var g = Math.floor(Math.random() * 156);
    var b = Math.floor(Math.random() * 156);
    var bgColor = "rgb(" + r + "," + g + "," + b + ")";
    return bgColor;
  }

  return (
    <>
      <Header />
      <Container>
        <Title><h2 className='title'>Grupo de Usuários</h2></Title>
        <Tabelas>
          <Table bordered hover className='tabela'>
            <thead>
              <tr>
                <th><TextField className='pesquisa' variant='standard' type="text" placeholder="Pesquisar" onChange={handleSearch} /></th>
              </tr>
            </thead>
            <tbody>
              {listaSearchGrupoUsuaios.map((item, index) =>
                <tr key={index} className="linha">
                  <td
                    className="celula"
                    key={index}
                    ref={idGrupoUsuarios}
                    id={item?.nome}
                    onClick={() => editGrupoUsuarios(item.id)}>
                    <PrimeiraLetra style={{ backgroundColor: `${randomDarkRGB()}` }}>
                      {item.nome?.substring(0, 1).toUpperCase()}
                    </PrimeiraLetra>
                    {item.nome?.toUpperCase()}
                  </td>
                </tr>
              )}
<tr>
<td className='pagination'>
                  <Pagination count={count} page={page} onChange={handleChangePagination} size="large" />
                </td></tr>
            </tbody>
          </Table>
          <div style={{ marginLeft: '30px' }}>
            <ButtonNew variant="primary" onClick={novoGrupoUsuarios}><FiPlus size={30} /></ButtonNew>
          </div>
        </Tabelas>
      </Container>
    </>
  )
}

export default GrupoUsuarios