import logoImg from '../../assets/logo.svg';
import notificacoesImg from '../../assets/notificacoes.svg';
import sairImg from '../../assets/sair.svg';
import { MenuSideBar } from '../MenuSideBar';
import Button from '@mui/material/Button';
import { Container, Content, LabelHeader, LeftGroup, RightGroup } from './styles';
import { MenuHeader } from '../MenuHeader';
import { useAuth } from '../../hooks/auth';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ConsultaUsuario } from '../../interfaces/usuarioConsulta';
import { FiBell } from 'react-icons/fi';
import { AiOutlinePoweroff } from 'react-icons/ai';
import Typography from '@mui/material/Typography';


import api from '../../services/api';
import { IconButton } from '@mui/material';

export function Header() {

  const nome = localStorage.getItem('@Gscon:user')
  const [usuarios, setUsuarios] = useState<ConsultaUsuario[]>([]);
  const [usuario, setUsuario] = useState<ConsultaUsuario>();

  useEffect(() => {
    buscaUsuarios();
  }, [])

  /*seEffect(() => {
    buscaDadosUsuario();
  }, [usuarios])*/

  async function buscaUsuarios() {
    const { status, data } = await api.get('/v1/usuarios')
    let resultado:ConsultaUsuario[] = data
    const dadosUsuario = resultado.find(item => item.nome === nome);
    setUsuario(dadosUsuario)
    setUsuarios(data)

    console.log(usuario)
  }

  /*async function buscaDadosUsuario() {
    if(usuarios){
    
    }
  }*/

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);



  const { signOut } = useAuth();

  return (
    <>
      <Container>
        <LeftGroup>
          <MenuHeader />
          <Link to="/"><img className='img-fluid shadow-4' style={{ resize: 'vertical' }} src={logoImg} alt="Gscon" /></Link>
          {
            windowSize.innerWidth < 600 ?
              <></> :
              <LabelHeader>
                <Typography> Bem vindo, {usuario?.dadoUsuario.nomePrincipal?.toUpperCase()} 
                {usuario?.dadoUsuario?.tipoPessoa==="PESSOA_FISICA"?usuario?.dadoUsuario.nomeSecundario?.toUpperCase():''}</Typography>
              </LabelHeader>
          }
        </LeftGroup>

        <RightGroup>
          <IconButton sx={{margin: 0}}>
            <FiBell color='#ffffff' size={20} />
          </IconButton>
          <IconButton onClick={signOut} style={{zIndex: 10998}}>
            <AiOutlinePoweroff color='#ffffff' size={20}  onClick={signOut} style={{zIndex: 10999}} />
          </IconButton>
        </RightGroup>
      </Container>
    </>
  )
}