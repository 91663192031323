import { useCallback, useState } from "react"
import { GetSeguradoras } from '../services/Seguradoras';
import { GetSeguroGrupos } from '../services/SeguroGrupos';
import { Seguradoras } from '../interfaces/seguradoras';
import { SeguroGrupos } from '../interfaces/seguroGrupos';


const SeguradoraTodos = () => {
    const [seguradoraLista, setSeguradoraLista] = useState<Seguradoras[]>([]);
    const [seguroGrupoLista, setSeguroGrupoLista] = useState<SeguroGrupos[]>([]);
    
    const getAll = useCallback(async () => {
        const { status, data } = await GetSeguradoras.getAll();

        if(status !== 200) throw new Error();

        setSeguradoraLista(data) 
    
    },[]);

    const getAllSegurosGrupo = useCallback(async () => {
        const { status, data } = await GetSeguroGrupos.getAll();

        if(status !== 200) throw new Error();

        setSeguroGrupoLista(data) 
    
    },[]);

    /*const createGrupoUsuarios = useCallback(async (grupoUsuarios: Pick<GrupoUsuarios, 'nome' | 'listMenuGrupoUsuario' >) => {
        const { status, data } = await GetGrupoUsuarios.createGrupoUsuarios(grupoUsuarios);
        
        if(status !== 201) throw new Error();

    }, [])

    const alteraGrupoUsuarios = useCallback(async (grupoUsuarios: GrupoUsuarios) => {
        const { status, data } = await GetGrupoUsuarios.alteraGrupoUsuarios(grupoUsuarios);
        
        if(status !== 201) throw new Error();

    }, [])*/


    return {
        seguradoraLista,
        getAll,
        getAllSegurosGrupo,
        seguroGrupoLista
        //createGrupoUsuarios,
        //alteraGrupoUsuarios,
    }
}

export { SeguradoraTodos }